import { useNotificationStore } from "@/store/notification"
import usePushNotificationHelper, { NotificationType } from "./pushNotifications"
import {useRouter} from "vue-router";
import {useIonRouter} from "@ionic/vue";
import {UseIonRouterResult} from "@ionic/vue/dist/types/hooks/router";

let initialized = false

export const init = ()=>{

    const notificationStore = useNotificationStore()
    const pushNotificationHelper = usePushNotificationHelper()

    // so kommen neue notifications rein...
    pushNotificationHelper.onNotificationReceived(({view, props, title, body}:NotificationType)=>{
        notificationStore.pushNotification({
            title:title,
            body:body,
            view:view,
            props:props
        })
    })

    if (initialized) {
        console.warn('duplicate init of notification helper: multiple event listeners attached')
    }
    initialized = true
}

export const navigateToNotificationTarget = (notification: NotificationType, ionRouter: UseIonRouterResult) => {

    switch (notification.view) {
        case 'CalendarDetail':
            ionRouter.push({
                name: 'CalendarDetail',
                params: {
                    dateId: notification.props.dateId
                }
            })
            break

        default:
            console.warn('implicit push notification routing target: ' + notification.view, notification)
            ionRouter.push({
                name: notification.view,
                params: notification.props
            })
            break
    }


}
