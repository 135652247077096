import {defineStore} from "pinia";
import {AssistantRunExpert} from "@/helper/chat/assistantRunExpert";
import {AssistantRunState} from "@/helper/chat/assistantRun";
import {ChatStatus} from "@/helper/chat/chatStatus";
import {consoleLogChat} from "@/helper/console";
import {getLastArrayElement} from "@/helper/array";
import {ResponseMessageRichResponse, RichContent} from "@/helper/chat/richResponses";
import {KapitelToolCall} from "@/helper/chat/toolCalls";
import {StreamingThreadMessage, ThreadMessage, ThreadMessageStatus} from "@/helper/chat/threadMessage";
import {TranscribeStatus} from "@/helper/chat/speechToTextRecorder";

export const useChatStore = defineStore("Chat", {
    state: (): {
        chatStatus: ChatStatus,

        currentExpertRun: AssistantRunExpert | undefined,
        currentExpertRunMessageText: string,
        currentExpertRunMessageRichContents: RichContent[] | undefined,
        currentExpertRunMessageStatus: ThreadMessageStatus,
        currentExpertRunMostRecentToolCall: KapitelToolCall | undefined,

        messageHistory: ThreadMessage[],

        transcribeStatus: TranscribeStatus,
    } => ({
        chatStatus: ChatStatus.READY,

        currentExpertRun: undefined,
        currentExpertRunMessageText: '',
        currentExpertRunMessageRichContents: undefined,
        currentExpertRunMessageStatus: 'ready',
        currentExpertRunMostRecentToolCall: undefined,

        messageHistory: [],

        transcribeStatus: 'ready',
    }),
    getters: {
        userMessageHistoryEmpty: (state) => state.messageHistory.filter(message => message.role === 'user').length === 0,
    },
    actions: {
        setChatStatus(state: ChatStatus, force: boolean = false) {
            const nextState = ChatStatus.transitionTo(state, this.chatStatus, force)

            if (nextState === this.chatStatus) {
                return;
            }

            consoleLogChat('ChatStatus -> %o', ChatStatus.stringify(state))
            this.chatStatus = state
        },
        setChatStatusFromRunState(state: AssistantRunState) {
            this.setChatStatus(ChatStatus.fromAssistantRunState(state))
        },
        resetCurrentExpertRun() {
            if (this.currentExpertRun) {
                this.currentExpertRun.mitt.all.forEach((v,k) => k === '*' ? console.error('wildcard event not handled') : this.currentExpertRun?.mitt.off(k))
            }

            this.currentExpertRun = undefined
            this.setChatStatus(ChatStatus.READY)

            /*
            reactivity workaround
             */

            // reset message copy
            this.currentExpertRunMessageText = ''
            this.currentExpertRunMessageRichContents = undefined
            this.currentExpertRunMessageStatus = 'ready'

            // reset tool call
            this.currentExpertRunMostRecentToolCall = undefined
        },
        setCurrentExpertRun(run: AssistantRunExpert) {
            this.currentExpertRun = run;

            // init & track run state
            this.setChatStatusFromRunState(run.getState())
            run.mitt.on('stateChanged', (state) => this.setChatStatusFromRunState(state))

            // collect response mesage (even if not yet finished)
            this.addToMessageHistory(run.message)


            /*
            reactivity workaround
             */

            // init message copy and track state
            const _setMessageContent = (message : StreamingThreadMessage) => {
                this.currentExpertRunMessageText = message.text
                this.currentExpertRunMessageRichContents = message.getRichContents()
                this.currentExpertRunMessageStatus = message.status
            }
            _setMessageContent(run.message)
            run.mitt.on('messageStreaming', _setMessageContent)

            // init tool call copy and track state
            this.currentExpertRunMostRecentToolCall = run.getMostRecentToolCall()
            run.mitt.on('runRequiresToolCall', (toolCall) => this.currentExpertRunMostRecentToolCall = toolCall)
        },
        addToMessageHistory(message: ThreadMessage) {
            this.messageHistory.push(message)
        },
        setTranscribeStatus(state: TranscribeStatus) {
            this.transcribeStatus = state
        },
    }
})
