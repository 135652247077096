import { mutation, query } from "@/graphql/client";
import { AvailabilityPlanningDates, AvailabilityPlanningDatesInput } from "@/graphql/generated/graphql";
import { gql } from "@urql/vue";
import useEmitter from "@/helper/emitter";
import {errorCatcher} from "@/helper/error";

const emitter = useEmitter()

const fragment = gql`fragment Fragment on AvailabilityPlanningDates {
    planningThreshold,
    daysBeforeAutoPlan,
    defaulted,
    firstUnplannedDay
}`

export async function fetchAvailabilityPlanningDates(): Promise<AvailabilityPlanningDates> {
    const result = await query(gql`
        ${fragment}
        query GetAvailabilityPlanningDates{
              availabilityPlanningDates {
                  ...Fragment
              }
        }`
    )

    return result?.data?.availabilityPlanningDates
}

export async function setAvailabilityPlanningDates(planningDates: AvailabilityPlanningDatesInput): Promise<any> {

    emitter.emit('availabilityPlanningDates:mutating', planningDates)

    const MUTATION = gql`
            mutation SetAvailabilityPlanningDates (
                $planningDatesDings: AvailabilityPlanningDatesInput!
            ) {
                setAvailabilityPlanningDates(
                    newAvailabilityPlanningDates: $planningDatesDings,
                )
                {
                    planningThreshold,
                    daysBeforeAutoPlan
                }
            }`

    const result = await mutation(
        MUTATION, {
            planningDatesDings: planningDates,
        }
    )

    const response = result?.data?.setAvailabilityPlanningDates
    if (!response) {
        errorCatcher('Fehler beim Speichern')
        return
    }

    emitter.emit('availabilityPlanningDates:mutated', planningDates)

    return response
}
