export const getArrayElementsExcluding = <Type>(arr: Type[], excluding: Type[]) : Type[] => {
    return arr.filter((elem: Type) => !excluding.includes(elem))
}

export const getRandomArrayElement = <Type>(arr: Type[], excluding?: Type[]) : Type=> {
    if (excluding) {
        arr = getArrayElementsExcluding(arr, excluding)
    }
    const pick = Math.floor(Math.random() * arr.length)
    return arr[pick]
}

export const getRandomArrayElements = <Type>(arr: Type[], n: number) : Type[] => {
    const result : Type[] = []
    for (let x = 0; x < n; x++) {
        result.push(getRandomArrayElement(arr, result))
    }
    return result
}

export const getLastArrayElement = <Type>(arr: Type[]) : Type | undefined => {
    return arr.length > 0 ? arr[arr.length - 1] : undefined
}
