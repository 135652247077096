<script setup>
import { computed } from 'vue'

const props = defineProps({
    min: Number,
    max: Number,
    value: Number
})

const pinCoordinates = computed(() => {
    const minDeg = -20
    const maxDeg = -180 - minDeg
    const rangeDeg = maxDeg - minDeg

    const valueMin = props.min !== undefined ? props.min : -minDeg
    const valueMax = props.max !== undefined ? props.max : -maxDeg
    const valueRange = valueMax - valueMin
    const value = props.value !== undefined ? props.value : valueRange / 2

    const quotient = rangeDeg / valueRange  
    const degree = -90 + ((value - valueMin) * quotient + minDeg + 90) * -1

    const arcMx = 200
    const arcMy = 192
    const arcRadius = 150
    const rad = Math.min(minDeg, Math.max(maxDeg, degree)) * Math.PI / 180

    return {
        cx: arcMx + arcRadius * Math.cos(rad),
        cy: arcMy + arcRadius * Math.sin(rad)
    }
})
</script>

<template>
<div
    class="speedometer"
>
    <svg
        viewBox="0 0 400 180"
    >
        <path
            d=" M 59 140 A 150 150 200 0 1 341 140"
            fill="none"
            stroke-width="60"
            stroke-linecap="round"
            class="track"
        />
        <circle
            id="circle"
            :cx="pinCoordinates.cx"
            :cy="pinCoordinates.cy"
            r="18"
            class="pin"
        />
</svg>
</div>
</template>

<style lang="scss">
.speedometer {
    width: 100%;
    position: relative;

    &:before {
        content: '';
        display: block;
        padding-top: 45%;
    }

    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .track {
        stroke: var(--ion-color-secondary);
        filter: drop-shadow( 0 0 0.5rem rgba(var(--ion-color-secondary-rgb), 0.1));
    }

    .pin {
        fill: var(--ion-color-primary);
        filter: drop-shadow( 0 0 0.7rem rgba(var(--ion-color-primary-rgb), 0.5));
    }
}
</style>