<template>
    <ion-app>
        <ion-page>

            <!-- version check indicator -->

            <OutdatedIndicator />


            <!-- offline state -->

            <!--<OfflineIndicator :show="offlineIndicatorVisible" />-->


            <!-- router outlet & bottom tabs  -->

            <ion-tabs>

                <ion-router-outlet></ion-router-outlet>

                <ion-tab-bar slot="bottom" v-if="bottomTabBarVisible" id="bottomTabBar">
                    <ion-tab-button
                        v-for="tab in availableTabs"
                        :class="isTabActive(tab) ? 'tab-selected' : ''"
                        :key="tab.key"
                        :disabled="!tab.enabled"
                        @click="onTabClick(tab)"
                    >
                        <ion-icon :src="tab.icon" />
                    </ion-tab-button>
                </ion-tab-bar>

            </ion-tabs>
        </ion-page>
    </ion-app>
</template>

<script setup lang="ts">
import {computed, watch, ref, Ref} from "vue";
import {
    IonApp,
    IonIcon,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    toastController,
    IonPage,
    IonTabs
} from '@ionic/vue';
import OutdatedIndicator from "@/views/components/OutdatedIndicator.vue";
import OfflineIndicator from "@/views/components/OfflineIndicator.vue";

import {useIonRouter}  from '@ionic/vue';
const ionRouter = useIonRouter()


/**
 * template visibility properties
 */

import {useRoute} from "vue-router";
import useOfflineHelper from "@/helper/offline";
const route = useRoute()
const offlineHelper = useOfflineHelper()

const offlineIndicatorVisible =computed(() => offlineHelper.isOffline.value && !route.meta.offlineSupport)
const bottomTabBarVisible = computed(() => !route.meta.hideToolbar)


/**
 * tab navigation
 */

import {availableTabs, isTabActive, navigateToTab, TabType} from "@/router/tabs";
const onTabClick = (tab: TabType) => navigateToTab(tab, ionRouter)


/**
 * global toast handling
 */

import {ToastOptions} from "@ionic/vue";
import {timerPromise, timerPromiseWithCancel, TimerPromiseWithCancelReturn} from "@/helper/timerPromise";
const toastStack:{
    top: Array<HTMLIonToastElement>,
    middle: Array<HTMLIonToastElement>,
    bottom: Array<HTMLIonToastElement>,
} = {
    top: [],
    middle: [],
    bottom: [],
}
const presentToast = async (position: 'bottom'|'middle'|'top', options: ToastOptions = {}) : Promise<HTMLIonToastElement> => {

    const internalOptions = {
        position,
        positionAnchor: position === 'bottom' && bottomTabBarVisible ? 'bottomTabBar' : undefined,
        ...options,
    }

    // toasts don't fire "dismiss" events if they hide automatically - we have to reproduce this options
    let autoDismissDuration;
    if (internalOptions.duration) {
        autoDismissDuration = internalOptions.duration
        internalOptions.duration = undefined
    }

    const toast = await toastController.create(internalOptions);

    if (autoDismissDuration) {
        timerPromise(autoDismissDuration).then(() => {dismissToast(toast)})
    }

    if (toastStack[position].length !== 0) {
        console.warn("overlapping " + position + " toast detected", toast)
    }
    toastStack[position].push(toast)

    // console.info(toastStack)

    toast.present().then(() => {});

    return toast
}
const dismissToast = async (toast: HTMLIonToastElement) : Promise<boolean> =>  {
    const i = toastStack[toast.position].findIndex((t) => t === toast)
    if (i !== -1) {
        toastStack[toast.position].splice(i, 1)
        // console.info(toastStack)
    } else {
        consoleErrorApp('dismissed toast missing from stack, this should not happen %o %d', toast, i)
    }
    return await toast.dismiss()
}


/**
 * error toast
 */
import {warning} from 'ionicons/icons';
const presentErrorToast = async (error: Error | string) : Promise<void> => {
    const message = error instanceof Error ? error.message : error
    await presentToast('bottom', {
        message,
        color:      "danger",
        icon:       warning,
        duration:   5000,
        buttons:    [{text: 'Ok', role: 'cancel'}]
    })
}
// window.presentErrorToast = presentErrorToast

// error toast event wiring
import {addErrorListener} from "@/helper/error";
addErrorListener(presentErrorToast)


/**
 * save toast
  */
let currentSaveToast : { toast : HTMLIonToastElement | undefined, timer : TimerPromiseWithCancelReturn } | undefined
const miniumSaveToastDisplayTime = 500
const presentSaveToast = async () => {
    if (currentSaveToast) {
        // cancel any pending dismiss runs and restart timer
        currentSaveToast.timer.cancel()
        currentSaveToast.timer = timerPromiseWithCancel(miniumSaveToastDisplayTime)
    } else {
        currentSaveToast = {
            toast: undefined,
            timer: timerPromiseWithCancel(miniumSaveToastDisplayTime)
        }

        currentSaveToast.toast = await presentToast('bottom', {
            message: 'Speichern ...',
            color: 'light',
        })
    }
}
const dismissSaveToast = async () => {
    if (!currentSaveToast) {
        return
    }

    await currentSaveToast.timer.promise

    if (!currentSaveToast?.toast) {
        return
    }

    dismissToast(currentSaveToast.toast)
    currentSaveToast = undefined
}
// window.presentSaveToast = presentSaveToast
// window.dismissSaveToast = dismissSaveToast

// save toast event wiring
import {useRequestQueueHelper} from "@/helper/requestQueue";
const requestQueue = useRequestQueueHelper();
const checkForSaveToastToPresent = async () => {
    if (requestQueue.pendingMutationsState.value) {
        presentSaveToast().then(() => {})
    } else
        dismissSaveToast()
}
watch(requestQueue.pendingMutationsState, checkForSaveToastToPresent)
checkForSaveToastToPresent()


/**
 * (push) notification toast
 */
const notificationToast : Ref<HTMLIonToastElement|undefined> = ref();
import { NotificationType } from "@/helper/pushNotifications"
import {navigateToNotificationTarget} from "@/helper/notification";
const dismissNotificationToast = () => {
    if (notificationToast.value) {
        dismissToast(notificationToast.value)
        notificationToast.value = undefined
    }
}
const presentNotificationToast = async (notification: NotificationType) => {
    notificationToast.value = await presentToast('top', {
        message: notification.title + ":" + notification.body,
        color: 'light',
        buttons: [
            {text: 'Mehr', role: 'info', handler: notificationClickHandler(notification, true)},
            {text: 'Ok', role: 'dismiss', handler: notificationClickHandler(notification, false)},
        ],
    })
}
const notificationClickHandler = (notification: NotificationType, navigate = false) => () => {
    notificationStore.popNotification()
    dismissNotificationToast()
    if (navigate) {
        navigateToNotificationTarget(notification, ionRouter)
    }
}

// (push) notification toast event wiring
import { useNotificationStore } from './store/notification';
import {consoleErrorApp} from "@/helper/console";
const notificationStore = useNotificationStore()
const checkForNotificationToastToPresent = async () => {
    dismissNotificationToast()
    const notification = notificationStore.getTop
    if (notification) {
        await presentNotificationToast(notification)
    }
}
// topmost notification changes -> update
watch(()=>notificationStore.getTop, checkForNotificationToastToPresent)
// initial check for notifications to present
checkForNotificationToastToPresent()
 // window.notificationStore = notificationStore


/**
 * offline handing
 */
 offlineHelper.onOffline(() => {
    /**
     * if we're going offline and the current route doesn't have offline support: navigate to the offline landing page
     *
     * PS: cannot navigate to '/' and let the router guard handle the redirect because it might be that '/' redirects
     * to the same page w're currently on, preventing any navigation from occurring
     */
    if (!route.meta.offlineSupport) {
        // route to root, let the router guards handle the rest        
        ionRouter.navigate('/offline', 'none', 'push')        
    }
})


</script>
