import {
    generateCalendarDates
} from "@/helper/calendar/calendarDate";
import { kapitelDateString } from '@/graphql/kapitelTypes';

import date from '@/helper/datetime/date';

export class CalendarMonth {
    constructor(
        public begin: kapitelDateString,
        public until: kapitelDateString
    ) { }

    getId() { return this.begin }

    format(format = "MMMM y") : string { return date.formatKapitelDate(this.begin, format)}

    generateCalendarDates() : Array<any> { return generateCalendarDates(this.begin, this.until) }

    getKapitelDates() : Array<kapitelDateString> { return this.generateCalendarDates().map(calendarDate => calendarDate.kapitelDate) }

    containsDate(date_: kapitelDateString ) : boolean {
        return (
            (date.isBefore(this.begin, date_) || date.isSameDay(this.begin, date_)) &&
            (date.isAfter(this.until, date_) || date.isSameDay(this.until, date_))
        )
    }
}


export const generateCalendarMonth = (dateString: kapitelDateString) : CalendarMonth => {
    return new CalendarMonth(
        date.startOfMonth(dateString),
        date.endOfMonth(dateString)
    )
}

export const generateCalendarMonths = (startDate: kapitelDateString, count?: number, endDate?: kapitelDateString): Array<CalendarMonth> => {
    const result = []

    let iteratorMonth = startDate;
    if (count && !endDate) {
        endDate = date.addMonths(startDate, count - 1)
    }

    if (!endDate) {
        throw new Error('missing parameter')
    }

    while (!date.isAfter(iteratorMonth, endDate)) {
        const calendarMonth = generateCalendarMonth(iteratorMonth)
        result.push(calendarMonth)
        iteratorMonth = date.addMonths(iteratorMonth, 1)
    }

    return result
};


/**
 * to be used as compareFn for CalendarMonth[].sort()
 */
export function sorter(a : CalendarMonth, b : CalendarMonth) {
  return date.cmpKapitelDate(a.begin, b.begin)
}
