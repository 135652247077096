import {query} from "@/graphql/client";
import {gql} from "@urql/vue";


export async function fetchGlobalShiftHullTimes(): Promise<any> {
    const result = await query(
        gql`query GetGlobalHullTimes {
                globalHullTimes{
                    begin
                    until
                    type
                }
            }
        }`
    )

    return result?.data?.globalHullTimes
}

export async function fetchGlobalShiftCoreTimes(): Promise<any> {
    const result = await query(
        gql`query GetGlobalCoreTimes {
                globalCoreTimes{
                    begin
                    until
                    type
                }
            }
        }`
    )

    return result?.data?.globalCoreTimes
}

export const AVERAGE_SHIFT_DURATION = 7.7
