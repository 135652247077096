<template>
    <div class="wrapp">
        <JobsiteSelectorModalInner
            ref="innerComponentRef"
            :categorize="false"
            :alreadySelectedJobsites="[]"
            :quickSelectJobsiteCategorySegments="[]"
            @select="onSelect"
            @close="onClose">

        </JobsiteSelectorModalInner>
    </div>
</template>

<script setup lang="ts">

import {IonContent} from "@ionic/vue";
import JobsiteSelectorModalInner from "@/views/components/JobsiteSelectorModalInner.vue";
import {Jobsite} from "@/graphql/generated/graphql";

const props = defineProps<{
    initData: string
}>()


const emit = defineEmits(['send-user-message', 'close'])
const onSelect = (jobsite: Jobsite) => {
    emit("close")
    emit("send-user-message", "Ich habe dir die jobsiteId und den Namen rausgesucht. jobsiteId: " + jobsite.id +" Name: "+ jobsite.name)
}
const onClose = () => {
    emit("close")
}


</script>

<style lang="scss">
.wrapp{
    height: 50vh;
}
</style>
