import {AInesAssistantType} from "@/graphql/generated/graphql";

import {fetchWithAuth} from "@/client";
import {Stream} from "openai/streaming";
import {AssistantStream} from "openai/lib/AssistantStream";

import {ToolCall} from "openai/resources/beta/threads/runs";

import {AssistantRunConfig} from "@/helper/chat/assistantRun";

import datetime from "@/helper/datetime/datetime";


/**
 *
 */
export const createAssistant = async (assistantType: AInesAssistantType) : Promise<string> => {
    const response = await fetchWithAuth(
        import.meta.env.VITE_REST_ENDPOINT + '/aines/create-assistant',
        JSON.stringify({
            assistant_type: assistantType,
        }),
        'POST',
        {
            "Content-Type": 'application/json',
        }
    )
    const assistant = await response.json();
    return assistant.id
};

/**
 *
 */
export const createThread = async () : Promise<string> => {
    const response = await fetchWithAuth(
        import.meta.env.VITE_REST_ENDPOINT + '/aines/create-thread',
        null,
        'POST',
        {
            "Content-Type": 'application/json',
        }
    )
    const thread = await response.json();
    return thread.id
}

export const performToolCall = async (toolCallArguments: any): Promise<any> => {
    const response = await fetchWithAuth(
        import.meta.env.VITE_REST_ENDPOINT + '/aines/perform-tool-call',
        JSON.stringify(toolCallArguments),
        "POST"
    );
    if (!response?.body) {
        throw Error("No valid response given.")
    }

    return await response.text()
}

export const submitToolCallResponse = async (toolCallOutputs: Array<string>, runConfig: AssistantRunConfig, runId: string) => {
    const response = await fetchWithAuth(
        import.meta.env.VITE_AI_ENDPOINT + `/aines/assistants/${runConfig.assistantId}/threads/${runConfig.threadId}/actions`,
        JSON.stringify({
            runId: runId,
            threadId: runConfig.threadId,
            assistantId: runConfig.assistantId,
            toolCallOutputs: toolCallOutputs,
        }),
        "POST",
    );

    if (!response?.body) {
        throw Error("No valid response given.")
    }


    // const stream = Stream.fromSSEResponse(response, new AbortController())
    // return AssistantStream.fromReadableStream(stream.toReadableStream())
    return AssistantStream.fromReadableStream(response.body);
}

export const sendMessageAndRun = async (
    assistantId: string,
    threadId: string,
    content: string
) : Promise<AssistantStream> => {

    const response = await fetchWithAuth(
        import.meta.env.VITE_AI_ENDPOINT + `/aines/assistants/${assistantId}/threads/${threadId}/messages`,
        JSON.stringify({
            content: content,
        }),
        "POST",
    );

    if (!response?.body) {
        throw Error("No valid response given.")
    }

    return AssistantStream.fromReadableStream(response.body);
    // const stream = Stream.fromSSEResponse(response, new AbortController())
    // return AssistantStream.fromReadableStream(stream.toReadableStream())

}

export const sendMessage = async (
    content: string,
    role: string = 'user',
    threadId: string,
) => {
    try {
        await fetchWithAuth(
            import.meta.env.VITE_REST_ENDPOINT + `/aines/create-message`,
            JSON.stringify({
                thread_id: threadId,
                content: content,
                role: 'user'
            }),
            'POST'
        );
        return true
    } catch (e) {
        console.error(e)
        return false
    }
};


/**
 * wraps base64 in file and calls openai API via transcribeAudioFile()
 */
export const transcribeAudioBase64 = async (b64Audio: string, mimeType: string): Promise<string | undefined> => {

    const base64ToBlob = (base64: string, mime: string): Blob => {
        const byteChars = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteChars.length; offset += 512) {
            const slice = byteChars.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: mime});
    }

    let filename = "audio.webm"

    if (mimeType === 'audio/mp4') {
        filename = "audio.mp4"
        alert(mimeType + ' unsupported by OpenAI API - use Chrome browser or native app')
        return;
    } else if (mimeType === 'audio/aac') {
        filename = "audio.m4a"
        alert(mimeType + ' unsupported by OpenAI API - use Chrome browser or native app')
        return;
    }

    const audioFile = new File([base64ToBlob(b64Audio, mimeType)], filename, {type: mimeType});

    return await transcribeAudioFile(audioFile)
}

/**
 * helper list
 */
const transcriptionIgnoreList = [
    "Untertitelung aufgrund der Amara.org-Community",
    "Untertitel im Auftrag des ZDF für funk, 2017",
    "Untertitel von Stephanie Geiges",
    "Untertitel der Amara.org-Community",
    "Untertitel im Auftrag des ZDF, 2017",
    "Untertitel im Auftrag des ZDF, 2020",
    "Untertitel im Auftrag des ZDF, 2018",
    "Untertitel im Auftrag des ZDF, 2021",
    "Untertitelung im Auftrag des ZDF, 2021",
    "Copyright WDR 2021",
    "Copyright WDR 2020",
    "Copyright WDR 2019",
    "SWR 2021",
    "SWR 2020",
    "Vielen Dank für's Zuschauen!",
    "Bis zum nächsten Mal."
]

/**
 * helper function
 */

/**
 *
 */
const transcribeAudioFile = async (file: File): Promise<string | undefined> => {
    const formData = new FormData();
    formData.append("file", file);

    let transcriptionText
    try {
        const transcription = await fetchWithAuth(
            import.meta.env.VITE_REST_ENDPOINT + '/aines/transcription',
            formData,
            "POST"
        )

        const transcriptionTextObject = await transcription.text();
        transcriptionText = (await JSON.parse(transcriptionTextObject))?.text || '';
    } catch (e) {
        return undefined
    }

    // mostly on empty audio translations we get strange responses - this fixes those cases
    const removeIgnoredTranscriptions = (transcription: string) => {
        transcriptionIgnoreList.forEach(ignoreString => {
            transcription = transcription.replace(new RegExp(ignoreString, 'g'), '');
        });
        return transcription;
    };

    // Example usage:
    transcriptionText = removeIgnoredTranscriptions(transcriptionText).trim();

    return transcriptionText
};

