<script setup>
import { IonButton } from '@ionic/vue'
import { ref, watch } from 'vue'
import Modal from '@/components/Modal.vue'
import { useAppState } from '@/helper/appState';

const props = defineProps({
    modelValue: Boolean
})

const emit = defineEmits(['update:modelValue'])
const isOpen = ref(false)

watch(() => props.modelValue, value => {
    isOpen.value = value
})
watch(() => isOpen.value, value => {
    emit('update:modelValue', value)
})

const logout = () => {
    useAppState().logout()    
    isOpen.value = false
}

const cancel = () => {
    isOpen.value = false
}
</script>

<template>
    <Modal
        v-model="isOpen"
        title="Ausloggen"
    >
        Bist Du sicher, dass Du dich ausloggen möchtest?
        <br><br>
        <ion-button
            @click="logout"
            expand="block"
            color="primary"
            fill="clear"
        >
            Ja ich möchte mich ausloggen
        </ion-button>
        <ion-button
            @click="cancel"
            expand="block"
            color="secondary"
        >
            Abbrechen
        </ion-button>
    </Modal>
</template>
