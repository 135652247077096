<script setup lang="ts">
import { SimplifiedJobsiteCategory } from '@/helper/jobsiteCategory';
import { IonChip } from '@ionic/vue';
import { computed } from 'vue';
import InlineSVG from './InlineSVG.vue';


const props = defineProps<{
    amount: number,
    mode: SimplifiedJobsiteCategory    
}>()

const icon = computed(() => {
    switch (props.mode) {
        case SimplifiedJobsiteCategory.Favorite: return '/icons/heart.svg'
        case SimplifiedJobsiteCategory.Additional: return '/icons/plus.svg'
    }
    return '';
})

</script>

<template>
    <IonChip class="small">
        <InlineSVG :src="icon" />{{ props.amount }}
    </IonChip>
</template>

<style lang="scss" scoped>
    ion-chip {
        .inline-svg {
            width: 1em;
            margin-bottom: -0.3em;
            margin-right: 0.3em;
        }
    }
</style>
