import {Ref, ref} from 'vue';

import {useEmployeeStore} from "@/store/employee";
import {useTimesheetStore} from "@/store/timesheet";

import useOfflineHelper from "@/helper/offline";

const intervalId : Ref<NodeJS.Timeout | undefined> = ref(undefined);

const disablePolling = import.meta.env.VITE_DEBUG_DISABLE_POLLING === 'true';

const start = async () => {
  const cycle = async () => {
    await Promise.all([
        useTimesheetStore().sync(),
        useEmployeeStore().reloadEmployee()
    ])
  }
  // initial run
  await cycle()

  if (disablePolling) {
    return
  }

  // cyclic run
  intervalId.value = setInterval(() => {
    if (useOfflineHelper().isOnline.value) {
      cycle()
    }
  }, 30000);
};


const stop = () => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
}


export function useCyclicRefresh() {
  return {
      start,
      stop
  };
}
