<script setup lang="ts">
import {IonButton, IonCard, IonCardContent, IonContent, IonItem, IonLabel, IonList,} from "@ionic/vue";

import {computed, onMounted, ref} from "vue";
import {getSummary,} from "@/helper/timesheet";
import TimesheetSignatureSummaryModal from "../components/TimesheetSignatureSummaryModal.vue";
import TimesheetSignatureConfirmation from "../components/TimesheetSignatureConfirmation.vue";
import {pluralize} from "@/helper/amountFormatter";
import TimesheetHeader from "@/views/Timesheet/components/TimesheetHeader.vue";
import {useTimesheetStore} from "@/store/timesheet";
import {VueSignaturePad} from "vue-signature-pad";

const store = useTimesheetStore();

const props = defineProps({
    timesheetNavigator: {
        type: Object,
        required: true
    }
})

const timesheetToDosInSignatureProcess = computed(() =>
    store.timesheetToDosInSignatureProcess.map((tstd) =>
        getSummary(tstd, store.timesheetToDos),
    ),
);

const signateurName = computed(() => {
  if(store.timesheetToDosInSignatureProcess.length>0){
    const firstTstd = store.timesheetToDosInSignatureProcess[0]
    if(firstTstd.timesheet){
      return firstTstd.timesheet.signateur;
    }
  }
  return ""

})

const confirmationOpen = ref(false);
const confirmationNumerator = ref(0);
const confirmationDenominator = ref(0);

const signaturePad = ref()
const hasSignature = ref(false)
const isSummaryModelOpen = ref(false);
const signatureData = ref("");

onMounted(() => {
    // Bug workaround
    // https://github.com/neighborhood999/vue-signature-pad/issues/389
    setTimeout(() => {
        signaturePad.value.resizeCanvas()
    }, 250)
})

const onTouchEnd = () => {
    hasSignature.value = !signaturePad.value.isEmpty()
}

const valid = computed(() =>
    store.timesheetToDosInSignatureProcess.length >= 0 &&
    hasSignature.value === true
)

const submitTimesheets = async () => {
    // Get image from signature pad
    signatureData.value = signaturePad.value.saveSignature().data

    if (valid.value) {
        store.setSignature(signatureData.value);
        await store.finishSignatureProcess();
        props.timesheetNavigator.next()
    }
};

// const onConfirmSignatureSaveDialog = ()=> {
//   confirmationOpen.value = false;
//   props.timesheetNavigator.done()
// }

const clear = () => {
    signaturePad.value.clearSignature();
    signatureData.value = "";
    hasSignature.value = false
};
</script>

<template>
        <TimesheetHeader :timesheet-navigator="timesheetNavigator"></TimesheetHeader>
        <ion-content class="no-padding-top">
            <ion-card class="custom-ion-card-dark">
                <ion-card-content
                    class="no-padding-top"
                    v-if="store.timesheetToDosInSignatureProcess.length > 0"
                >
                    <ion-list>
                        <ion-item lines="none">
                            <ion-label>
                                <h3 class="text-white">Einsätze</h3>
                                <p class="text-white">
                                    {{
                                        store.timesheetToDosInSignatureProcess
                                            ? getSummary(
                                                  store
                                                      .timesheetToDosInSignatureProcess[0],
                                                  store.timesheetToDos,
                                              ).jobsiteDescription
                                            : ""
                                    }}
                                </p>
                                <p class="text-white">
                                    {{
                                        pluralize(
                                            store.timesheetToDosInSignatureProcess.length,
                                            "Dienst",
                                            "Dienste"
                                        )
                                    }}
                                </p>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                    <ion-button color="light" expand="block" v-on:click="isSummaryModelOpen = true">Dienstzeiten anzeigen</ion-button>
                </ion-card-content>
            </ion-card>


            <ion-button
                :disabled="!valid"
                v-on:click="clear()"
                color="light"
                expand="block"
                >Feld leeren</ion-button
            >

            <ion-card class="signature-container">
                <div class="signer-name">Unterschrift von {{ signateurName }}</div>
                <VueSignaturePad
                    id="signaturePad"
                    ref="signaturePad"
                    @touchend.native="onTouchEnd"
                />
            </ion-card>

            <ion-button :disabled="!valid" v-on:click="submitTimesheets()" expand="block"
                >Dienst bestätigen</ion-button
            >
            <TimesheetSignatureSummaryModal
                v-model="isSummaryModelOpen"
                :timesheet-to-do-summaries="timesheetToDosInSignatureProcess"
            />
<!--
            <TimesheetSignatureConfirmation
                v-if="confirmationOpen"
                :numerator="confirmationNumerator"
                :denominator="confirmationDenominator"
                v-on:confirm="onConfirmSignatureSaveDialog"
            /> -->
        </ion-content>
</template>


<style scoped>

.signature-container {
  margin-top: 1%;
  position: relative;
  width: 100%;
  height: 30vh;

  > #signaturePad {
    position: relative;
    z-index: 1;
  }
}

.signer-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #cccccc;
  font-size: 25px;
  text-align: center;
}

/* canvas {
  position: relative;
  background-color: transparent;
  z-index: 2;
} */
</style>
