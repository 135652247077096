<template>
    <CalendarListItem
        :date="props.initData"
        :day-data="day"
        v-model="model"
        :selectable="selectable"
    />
</template>

<script setup lang="ts">
import CalendarListItem from "./components/CalendarListItem.vue";
import { ref } from "vue";
import { Day } from "@/graphql/generated/graphql";
import { kapitelDateString } from "@/graphql/kapitelTypes";
import { fetchDayForDate } from "@/helper/day";
import { DataLoader } from "@/helper/dataLoader";

const model = defineModel()

const props = defineProps<{
    initData: kapitelDateString // day,
    selectable?: boolean
}>()

const day = ref<Day>()
const loader = new DataLoader(
    async () => day.value = await fetchDayForDate(props.initData)
)
loader.load()
</script>