<template>
    <div class="voice-input-container floating-big">
        <IonInput
            :disabled="recording.status.value !== 'off'"
            v-model="text"
            @input="onInput"
            @keypress.enter="onSubmit"
            placeholder="Frag' AInes..."
            class="fragAInesInput"
        />
        <div v-if="recording.status.value === 'on'" class="recording">
            <span>{{ recording.time.value }}</span>
        </div>
        <div v-if="recording.status.value === 'postprocessing'" class="processing">
            <span>Verarbeitung...</span>
        </div>
        <InlineSVG
            v-if="!text"
            @touchstart="recordButton"
            @touchend="recordButton"
            @mousedown="recordButton"
            @mouseovup="recordButton"
            :class="{'icon-gear': !['on','off'].includes(recording.status.value), 'icon-microphone': ['on','off'].includes(recording.status.value), 'recording-active': recording.status.value === 'on'}"
        />
        <InlineSVG
            v-if="recording.status.value === 'off' && text"
            @click="onSubmit"
            class="submit-button"
            :class="{'active': text.length > 0}"
            src="/icons/arrow-circle-right.svg"
        />
    </div>
</template>

<script setup lang="ts">
import { Ref, ref, watch } from "vue";
import InlineSVG from "@/components/InlineSVG.vue";
import { IonInput } from "@ionic/vue";
import { SpeechToTextRecorder } from "@/helper/chat/speechToTextRecorder";
import {consoleLogChat} from "@/helper/console";
import {useChatStore} from "@/helper/chat/chatStore";

const emit = defineEmits(["text:submit", "text:input"]);

const text = ref("");

const recording : {
    recorder: {type: string, start : () => Promise<void>, stop: () => Promise<void>, transcription: Promise<string|undefined>} | undefined,
    status: Ref<'on'|'off'|'preprocessing'|'postprocessing'> ,
    time: Ref<string>,
    timeUpdateInterval: NodeJS.Timeout | undefined
} = {
    recorder: undefined,
    status: ref('off'),
    time: ref("00:00"),
    timeUpdateInterval: undefined
}

const onInput = () => {
    if (text.value.length === 0) {
        stopRecording();
    }
    emit("text:input", text.value.trim());
};

const onSubmit = () => {
    if (text.value.trim() !== "") {
        emit("text:submit", text.value.trim());
        text.value = "";
    }
};

const _recordingTimerStart = () => {
    recording.time.value = "00:00";

    recording.timeUpdateInterval = setInterval(() => {
        let [minutes, seconds] = recording.time.value.split(":").map(Number);
        seconds++;
        if (seconds >= 60) {
            minutes++;
            seconds = 0;
        }
        recording.time.value = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }, 1000); 
}
const _recordingTimerStop = () => {
    if (recording.timeUpdateInterval) {
        clearInterval(recording.timeUpdateInterval)
        recording.timeUpdateInterval = undefined
    }
}

let holdToggleTimer:any = undefined
const recordButton = (e: Event | undefined) => {
    if (e) {
        e.preventDefault();
    }
    if (holdToggleTimer) {
        return
    }
    if (recording.status.value !== 'on' && recording.status.value !== 'off') {
        return
    }
    recording.status.value === 'on' ? stopRecording() : startRecording()
    holdToggleTimer = setTimeout(() => {holdToggleTimer = undefined}, 500)
}

const startRecording = async () => {
    if (recording.status.value !== 'off') {
        return
    }

    recording.status.value = 'preprocessing'
    useChatStore().setTranscribeStatus('preparing')

    recording.recorder = await SpeechToTextRecorder();

    if (!recording.recorder) {
        recording.status.value = 'off'
        useChatStore().setTranscribeStatus('ready')
        return
    }

    _recordingTimerStart()

    try {
        await recording.recorder.start()
    } catch {
        recording.status.value = 'off'
        useChatStore().setTranscribeStatus('ready')
    }

    recording.status.value = 'on'
    useChatStore().setTranscribeStatus('listening')
};

const stopRecording = async () => {
    if (recording.status.value !== 'on' || !recording.recorder) {
        return
    }
    
    recording.status.value = 'postprocessing'
    useChatStore().setTranscribeStatus('transcribing')

    _recordingTimerStop()

    let text
    try {
        await recording.recorder.stop()

        text = await recording.recorder.transcription

        consoleLogChat('transcription result: %s', text)
    } catch (e) {
        console.error(e)
    } finally {
        recording.status.value = 'off'
        useChatStore().setTranscribeStatus('ready')
        recording.recorder = undefined
    }

    if (!text) {
        consoleLogChat("skip empty")
        return
    }
    
    emit("text:submit", text)
};

watch(recording.status, () => { consoleLogChat('Recorder %o %o',recording.recorder?.type, recording.status.value) })
</script>

<style scoped lang="scss">
.fragAInesInput {
    color: var(--ion-color-secondary);
}
.voice-input-container {
    display: flex;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.15em;
    padding-bottom: 0.15em;
    border-radius: 1rem;
    z-index: 9;
    background-color: var(--ion-color-white);

    ion-input {
        flex: 1;
        border: none;
        outline: none;
        padding: 8px 12px;
        border-radius: 24px;
        font-size: 16px;
    }
    .recording-active {
        color: white;
        background-color: var(--ion-color-primary);
        border-radius: 24px;
    }

    .recording-active.icon-microphone::before{
        background-color: var(--ion-color-white);
    }

    .microphone,
    .submit-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--ion-color-secondary);
    }
    .inline-svg {
        width: 2rem;
        height: 2rem;
        padding: .3rem;
    }
    .microphone {
        width: 2rem;
        height: 2rem;
        padding: .3rem;
    }

    .submit-button {
        width: 2rem;
        height: 2rem;

        color: var(--ion-color-grey);

        &.active {
            color: var(--ion-color-primary);
        }
        
    }

    .recording,
    .processing {
        background-color: var(--ion-color-primary);
        color: var(--ion-color-light);
        padding: 0 16px;
        border-radius: 24px;
        margin-right: 6px;
        .recording-icon {
            margin-right: 8px;
        }
    }



    .icon-microphone::before,.icon-gear::before {
        background-color: var(--ion-color-black);
        content: '';
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        mask-size: cover;
        mask-position: center;
        mask-repeat: no-repeat;
    }




    .icon-microphone::before {
        mask-image: url(/icons/microphone.svg);
        //background-image: url('/icons/microphone.svg');
        -webkit-mask-image: url(/icons/microphone.svg);
    }



    .icon-gear::before {

        mask-image: url(/icons/gear.svg);
        //background-image: url('/icons/gear.svg');
        -webkit-mask-image: url(/icons/gear.svg);
    }

    .icon-gear{
        animation-name: spinning;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: 55% 55%; // gear is not centered, todo fix by professional
    }
    @keyframes spinning {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>
