<script setup lang="ts">
import {IonCard} from '@ionic/vue'
import {Availability, AvailabilityShift, ShiftType} from "@/graphql/generated/graphql";
import AvailabilityShiftTimeEditor from "@/components/AvailabilityShiftTimeEditor.vue";
import type {PropType} from 'vue'

const props = defineProps({
    availabilityShifts: {
        type: Array as PropType<AvailabilityShift[]>,
        required: true
    }
})

const emit = defineEmits(['update:availableShifts']);

const onUpdateAvailabilityShift = (clonedAvailabilityShift: AvailabiltyShift)=>{

    const changingAvailabilityShift = props.availabilityShifts.find(
        availabilityShift => availabilityShift.type === clonedAvailabilityShift.type
    )

    if (!changingAvailabilityShift) {
        throw new Error()
    }

    const clonedAvailabilityShifts = props.availabilityShifts.map(
        availabilityShift => availabilityShift.type === changingAvailabilityShift.type ? clonedAvailabilityShift : availabilityShift
    )

    emit('update:availableShifts', clonedAvailabilityShifts);

}

</script>

<template>
    <ion-card
        v-for="shift in availabilityShifts"
        :key="shift.type"
    >
        <AvailabilityShiftTimeEditor
            :shift="shift"
            v-on:update:available-shift="onUpdateAvailabilityShift"
        />

    </ion-card>
</template>
