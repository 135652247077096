import { useAuthStore } from "@/store/auth";
import Auth from "@/views/Auth/Auth.vue";
import Calendar from '@/views/Calendar/CalendarPage.vue';
import CalendarDetail from '@/views/CalendarDetail/CalendarDetailPage.vue';
import EmployeeSelector from '@/views/Employees/EmployeeSelector.vue';
import JobsiteDetails from '@/views/JobsiteDetails.vue';
import MockDetailAvailability from '@/views/Mocks/CalendarDetailAvailabilityEditor.vue';
import MockDetailAvailabilityTimeConstraintEditor from '@/views/Mocks/CalendarDetailAvailabilityTimeConstraintEditor.vue';
import MockHome from '@/views/Mocks/MockHome.vue';
import Planned from '@/views/Planned.vue';
import Planning from '@/views/Planning/PlanningDashboard.vue';
import PlanningMonth from "@/views/Planning/PlanningMonth.vue";
import AutopilotJobsitePreference from "@/views/Planning/components/AutopilotJobsitePreference.vue";
import PlanningSettings from "@/views/Settings/PlanningSettings.vue";
import AvailabilityTemplates from "@/views/Settings/AvailabilityTemplates.vue";
import JobsiteManager from '@/views/Settings/JobsiteManager.vue';
import Settings from "@/views/Settings/Settings.vue";
import {createRouter, createWebHashHistory} from '@ionic/vue-router';
import {RouteParamsRaw, RouteRecordRaw} from 'vue-router';
import {isLoggedIn} from "@/helper/auth";
import TimesheetProcess from "@/views/Timesheet/TimesheetProcess.vue";
import Offline from "@/views/Offline.vue";
import RegisterBooking from "@/views/RegisterBooking.vue";
import Chat from "@/views/Chat/Chat.vue";
import useOfflineHelper from "@/helper/offline";
import usePushNotificationHelper, { NotificationType } from "@/helper/pushNotifications"
import Debug from "@/views/Debug.vue";
import PlanningMonthPage from "@/views/Planning/PlanningMonthPage.vue";

const loginStateEntrypoint = () => isLoggedIn() ? '/chat' : '/auth'

const nestedRouteRawData: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: () => loginStateEntrypoint(),
        meta: { allowAccessWhenLoggedOut: true, hideToolbar: true }
    },
    {
        path: '/auth',
        component: Auth,
        meta: { allowAccessWhenLoggedOut: true, prohibitAccessWhenLoggedIn: true, hideToolbar: true, exitOnHardwareBack: true }
    },
    {
        path: '/timesheet/:jobsiteId?',
        name: 'TimesheetProcess',
        component: TimesheetProcess,
        props: true,
        meta: { hideToolbar: true, offlineSupport: true },
    },
    {
        path: '/planned',
        name: 'Planned',
        component: Planned,
        meta: { exitOnHardwareBack: true }
    },
    {
        path: '/chat',
        name: 'Chat',
        component: Chat,
        meta: { hideToolbar: true }
    },
    {
        path: '/register-booking',
        name: 'RegisterBooking',
        component: RegisterBooking,
        meta: { exitOnHardwareBack: true }
    },
    {
        path: '/offline',
        name: 'Offline',
        component: Offline,
        meta: { exitOnHardwareBack: true, offlineSupport: true, hideToolbar: true }
    },
    {
        path: '/planning',
        name: 'Planning',
        component: Planning,
        children: [
            {
                path: '/planning/:monthId',
                name: 'PlanningMonth',
                component: PlanningMonthPage,
                props: true
            },
            /*
            {
                path: '/planning/jobsiteAfterburner/:monthId',
                name: 'JobsiteAfterburner',
                component: JobsiteAfterburner,
                props: true
            },
            {
                path: '/planning/jobsiteExplorer/:monthId',
                name: 'JobsiteExplorer',
                component: JobsiteExplorer,
                props: true
            },
            */
            {
                path: '/planning/autopilot/:monthId',
                name: 'AutopilotJobsitePreference',
                component: AutopilotJobsitePreference,
                props: true
            },
        ]
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar,
        meta: { hideToolbar: true },
        children: [
            {
                path: '/calendar/:dateId/:expectedContent?',
                name: 'CalendarDetail',
                component: CalendarDetail,
                props: true
            }
        ]
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        children: [
            {
                path: '/settings/availability-templates',
                name: 'AvailabilityTemplates',
                component: AvailabilityTemplates
            },
            {
                path: '/settings/planning-dates',
                name: 'PlanningSettings',
                component: PlanningSettings
            },
            {
                path: '/settings/favorite-jobsites',
                name: 'JobsiteManager',
                component: JobsiteManager
            },
        ]
    },
    {
        path: '/employees',
        name: 'EmployeeSelector',
        component: EmployeeSelector,
        meta: {
            allowAccessWhenNotImpersonating:true
        }
    },
    {
        path: '/jobsite-details/:jobsiteId',
        name: 'JobsiteDetails',
        component: JobsiteDetails,
        props: true
    },
    {
        path: '/mock',
        name: 'MockHome',
        component: MockHome,
        children: [
            {
                path: '/mock/calender-detail-availability-editor',
                name: 'MockDetailAvailability',
                component: MockDetailAvailability
            },
            {
                path: '/mock/calender-detail-availability-time-constraint-editor',
                name: 'MockDetailAvailabilityTimeConstraintEditor',
                component: MockDetailAvailabilityTimeConstraintEditor
            }
        ]
    },
    {
        path: '/debug',
        name: 'Debug',
        component: Debug,        
    },
]

const parseAndFlattenRoutes = (routes:Array<RouteRecordRaw>, parentRoute: RouteRecordRaw | undefined = undefined, nestingLevel = 0) => {
    let result : Array<RouteRecordRaw> = [];
    routes.forEach(route => {
        if (route.children) {
            result = result.concat(parseAndFlattenRoutes(route.children, route, nestingLevel + 1))
        }

        const clone = Object.assign({}, route)
        clone.children = undefined
        clone.meta = clone.meta ?? {}
        clone.meta.nestedRouteParent = parentRoute
        clone.meta.nestedRouteChildren = route.children
        clone.meta.nestedRouteLevel = nestingLevel
        result.push(clone)
    })
    return result;
}

const routes = parseAndFlattenRoutes(nestedRouteRawData)

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes
})

const offlineHelper = useOfflineHelper()
const pushNotificationHelper = usePushNotificationHelper()

router.beforeEach((to, from) => {
    /**
     * routes accessible while logged out explicitly need to allow this {allowAccessWhenLoggedOut:true}
     * routes un-accessible if logged in can prohibit access {prohibitAccessWhenLoggedIn:true}
     *
     * in both cases we redirect to the corresponding login-state-entrypoint
     */
    const loggedInState = isLoggedIn()
    if (
        (!loggedInState && !to.meta.allowAccessWhenLoggedOut) ||
        (loggedInState && to.meta.prohibitAccessWhenLoggedIn)
    ) {
        return loginStateEntrypoint()
    }

    /**
     * if we are offline and the target route doesn't have offline support: redirect to the offline landing page
     */
    if (offlineHelper.isOffline.value && !to.meta.offlineSupport) {
        return '/offline'
    }

    const authStore = useAuthStore()
    if(authStore.canImpersonate() && !authStore.isImpersonating() && !to.meta.allowAccessWhenNotImpersonating){
        return '/employees'
    }
});

pushNotificationHelper.onPushNotificationActionPerformed(({view, props}:NotificationType)=>{
    router.push({
        name: view,
        params: props
    })
})

export default router
