import type { kapitelTimeString } from "@/graphql/kapitelTypes";
import time from "../datetime/time";

export class ShiftTime {
  private _begin!: kapitelTimeString;
  private _until!: kapitelTimeString;

  constructor(begin: kapitelTimeString, until: kapitelTimeString) {
    this._begin = begin;
    this._until = until;
  }

  get begin(): kapitelTimeString {
    return this._begin;
  }

  set begin(begin: kapitelTimeString) {
    this._begin = begin;
  }

  get until(): kapitelTimeString {
    return this._until;
  }

  set until(until: kapitelTimeString) {
    this._until = until;
  }

  isEndingOnNextDay(): boolean {
    // return this._begin.isGreaterThan(this._until);
    return time.cmpKapitelTime(this._begin, this._until) == 1
  }
}
