import { AvailabilityShift, AvailabilityShiftDefault, Scalars } from "@/graphql/generated/graphql";
import {kapitelDateString, kapitelDateTimeString, kapitelTimeString} from "@/graphql/kapitelTypes";
import { ShiftTime } from "@/helper/calendar/shiftTime";

import datetime from '@/helper/datetime/datetime';
import time from "@/helper/datetime/time";



const PIN_GRANULARITY = 30; //one pin in the ion range picker for every PIN_GRANULARITY minutes

const parseShiftTimeMinutes = (shiftTime: ShiftTime) => {

    const dateBegin: kapitelDateTimeString = shiftTime.begin
    const dateUntil: kapitelDateTimeString = shiftTime.until

    // if beginDateTime is AFTER untilDateTime
    const add24Hours = (datetime.cmpKapitelDateTime(dateBegin, dateUntil)==1);

    const timeBegin = datetime.convertDateTime2Time(dateBegin)
    const timeUntil = datetime.convertDateTime2Time(dateUntil)

    const totalMinutesBegin = time.kapitelTimeToMinutes(timeBegin)
    const totalMinutesUntil = time.kapitelTimeToMinutes(timeUntil) + (add24Hours ? 24 * 60 : 0)

    return {
        totalMinutesBegin: totalMinutesBegin,
        totalMinutesUntil: totalMinutesUntil
    }
}


export const getIonRangeMaxValue = () => {
    return 24 * (60 / PIN_GRANULARITY) * 1.5
}


export const shiftTimeToRangeValue = (shiftTime: ShiftTime) => {

    const {totalMinutesBegin, totalMinutesUntil} = parseShiftTimeMinutes(shiftTime)

    const rangeValueBegin = totalMinutesBegin / PIN_GRANULARITY
    const rangeValueUntil = totalMinutesUntil / PIN_GRANULARITY

    return {
        lower: rangeValueBegin,
        upper: rangeValueUntil
    }
}

export const rangeValueToMinutes = (value: number) => {
    return value * PIN_GRANULARITY
}


export const shiftTimesExceedHullTimes = (shiftTime: ShiftTime, hullTime: ShiftTime): boolean => {

    if (time.cmpKapitelTime(hullTime.begin, shiftTime.begin)== 1) {
        return true
    }
    if (shiftTime.isEndingOnNextDay() === hullTime.isEndingOnNextDay()) {
        return time.cmpKapitelTime(shiftTime.until, hullTime.until)== 1
    } else if (shiftTime.isEndingOnNextDay()) {
        return false
    }
    return true

}

export const isShiftTimeEndTheNextDay = (shiftTime: ShiftTime) => {

  const minutesBegin = time.kapitelTimeToMinutes(shiftTime.begin)
  const minutesUntil = time.kapitelTimeToMinutes(shiftTime.until)

  return minutesBegin > minutesUntil

}

export const convertShiftTimeToMinutes = (shiftTime: ShiftTime) => {
    const minutesBegin = time.kapitelTimeToMinutes(shiftTime.begin)
    const minutesUntil = time.kapitelTimeToMinutes(shiftTime.until)

    return {
        begin: minutesBegin,
        until: minutesUntil + (isShiftTimeEndTheNextDay(shiftTime) ? 24 * 60 : 0)
    }
}

export const convertMinutesToShiftTime = (begin: number, until: number) => {
    return {
        begin: time.parseMinutes(begin),
        until: time.parseMinutes(until)
    }
}

export const convertMinutesToTime = (minutes: number) : kapitelTimeString => {
    return minutes > (24 * 60)
        ? time.parseMinutes(minutes - (24 * 60))
        : time.parseMinutes(minutes)
}
