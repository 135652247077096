<template>
    <Modal
        v-model="isOpen"
        title="Stationsauswahl"
        class="no-padding-left no-padding-right"
    >
        <InnerComponent
            ref="innerComponentRef"
            :categorize="categorize"
            :alreadySelectedJobsites="alreadySelectedJobsites"
            :quickSelectJobsiteCategorySegments="quickSelectJobsiteCategorySegments"
            @select="onSelect"
            @close="isOpen = false"
        />
    </Modal>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { defineProps, defineEmits } from 'vue';
import Modal from '@/components/Modal.vue';
import InnerComponent from './JobsiteSelectorModalInner.vue'; // Adjust the path as needed
import { PropType } from 'vue';
import { Jobsite } from "@/graphql/generated/graphql";
import { JobsiteCategorySegment } from "@/helper/jobsite";

const props = defineProps({
    modelValue: Boolean,
    categorize: {
        type: Boolean,
        required: false,
        default: true
    },
    alreadySelectedJobsites: {
        type: Array as PropType<Jobsite[]>,
        required: false,
        default: () => []
    },
    quickSelectJobsiteCategorySegments: {
        type: Array as PropType<JobsiteCategorySegment[]>,
        required: false,
        default: () => []
    }
});

const emit = defineEmits(['update:modelValue', 'select']);

const isOpen = ref(false);
const innerComponentRef = ref(null);

watch(() => props.modelValue, value => isOpen.value = value);

watch(() => isOpen.value, value => {
    emit('update:modelValue', value);
    if (value && innerComponentRef.value && innerComponentRef.value.reload) {
        debugger
        innerComponentRef.value.reload();
    }
});

const onSelect = (item: Jobsite) => {
    emit('select', item);
    isOpen.value = false;
};



</script>

<style lang="scss">
/* Add any styles specific to the outer component if necessary */
</style>
