import {convertRichResponseToRichContent, ResponseMessageRichResponse, RichContent} from "@/helper/chat/richResponses";

export type ThreadMessageStatus = 'ready' | 'streaming' | 'textDone' | 'messageDone'

export class ThreadMessage {
    constructor(
        public text: string,
        public role: 'user' | 'assistant',
        public richResponses: undefined | ResponseMessageRichResponse[] = undefined,
    ) {
    }

    public getRichContents(): RichContent[] {
        return (this.richResponses || [])
            .filter((richResponse: ResponseMessageRichResponse) => richResponse.type)//&& richResponse.reference
            .map(convertRichResponseToRichContent)
    }
}

export class StreamingThreadMessage extends ThreadMessage {

    public status: ThreadMessageStatus = 'ready'

    public setStreamingStatus(status: ThreadMessageStatus) {
        this.status = status
        return this
    }

    public update(
        text: string,
        richResponses: undefined | ResponseMessageRichResponse[] = undefined,
    ) {
        this.text = text
        this.richResponses = richResponses

        return this
    }

}
