import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {AvailabilityTemplate, AvailabilityTemplateShift, ShiftType} from "@/graphql/generated/graphql";


export async function fetchAvailabilityTemplates(): Promise<any> {
    const result = await query(
        gql`query GetAvailabilityTemplates {
                availabilityTemplates{
                    dayOfWeekISO
                    shifts {
                        type
                    }
                    constraints {
                        begin
                        until
                        comment
                    }
                }
            }
        }`
    )

    return result?.data?.availabilityTemplates
}

export const generateAvailabilityTemplate = (dayOfWeekISO: number): AvailabilityTemplate => {
    return <AvailabilityTemplate>{
        dayOfWeekISO: dayOfWeekISO,
        shifts: [],
        constraints: []
    }
}


// mutation {
//     setAvailabilityTemplate(
//         dayOfWeekISO: $dow
//     shifts: [
//         {
//             type: SD
//         }
//     ]
//     constraints: [
//         {
//             begin: "{$constraintBegin->format("H:i")}"
//             until: "{$constraintUntil->format("H:i")}"
//             comment: "Kommentar"
//         }
//     ]
// )
//     $this->entityProperties
// }
export async function setAvailabilityTemplate(template: AvailabilityTemplate): Promise<any> {
    const MUTATION = gql`
            mutation SetAvailabilityTemplate (
                $dayOfWeekISODings: Int!,
                $shiftsDings: [AvailabilityTemplateShiftInput!]!,
                $constraintsDings: [AvailabilityTemplateTimeConstraintInput!]!
            ) {
                setAvailabilityTemplate(
                    dayOfWeekISO: $dayOfWeekISODings,
                    shifts: $shiftsDings,
                    constraints: $constraintsDings
                )
                {
                    dayOfWeekISO
                }
            }`

    const result = await mutation(
        MUTATION, {
            dayOfWeekISODings: template.dayOfWeekISO,
            shiftsDings: template.shifts,
            constraintsDings: template.constraints
        }
    )

    const success = result?.data

    if (!success) {
        throw new Error("error while storing templates")
    }

    return success
}
