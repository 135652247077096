import {defineStore} from "pinia";

import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {
    AInesRequest,
    AInesRequestAction, AInesRequestReaction,
    AInesRequestReactionInput, AInesRequestReactionType,
    AInesRequestSubject,
    Scalars
} from "@/graphql/generated/graphql";
import {actionSheetController, alertController} from "@ionic/vue";


export enum AInesRequestAssistantUIState {
    MINIMIZED = 'MINIMIZED', // nur avatar & badge
    COMPACT = 'COMPACT', // kurzbeschreibung der requests
}

export const useAInesRequestStore = defineStore("AInesRequests", {
    state: (): {
        requests: AInesRequest[],
        selection: AInesRequest | undefined,
        uiState: AInesRequestAssistantUIState | undefined,
    } => ({
        requests: [] as AInesRequest[],
        selection: undefined,
        uiState: undefined
    }),
    getters: {
        unhandledRequests: state => state.requests.filter(r => r !== state.selection)
    },
    actions: {
        async refetch() {
            this.requests = await fetchAInesRequests()
        },
        toggleUIState() {
            this.setUIState(this.uiState !== AInesRequestAssistantUIState.MINIMIZED ? AInesRequestAssistantUIState.MINIMIZED : AInesRequestAssistantUIState.COMPACT)
        },
        setUIState(uiState:AInesRequestAssistantUIState) {
            if (this.selection && uiState !== AInesRequestAssistantUIState.MINIMIZED) {
                //console.info('reset selection')
                this.selection = undefined
            }
            this.uiState = uiState
        },
        minimizeIfOpen() {
            if (this.uiState !== AInesRequestAssistantUIState.MINIMIZED) {
                this.setUIState(AInesRequestAssistantUIState.MINIMIZED)
            }
        },

        /**
         * request selection
         */
        selectRequest(request: AInesRequest | undefined) {
            if (request) {
                this.selection = request
                this.setUIState(AInesRequestAssistantUIState.MINIMIZED)
            } else {
                this.selection = undefined
                this.setUIState(AInesRequestAssistantUIState.COMPACT)
            }
        },
        respondToSelectedRequest(reactionType: AInesRequestReactionType) {
            if (!this.selection) {
                throw new Error('no selection')
            }

            // track response in backend
            const response : AInesRequestReactionInput = {
                requestSubject: this.selection.subject,
                requestScope: this.selection.scope,
                reactionType
            }
            respondToAInesRequest(response)

            // remove specific request from list of open requests
            this.requests = this.requests.filter(r => r !== this.selection)

            // reset request selection
            this.selectRequest(undefined)
        }
    },
});

export const snoozeAInesRequest = async () => {
    const aInesRequestStore = useAInesRequestStore()

    const actionSheet = await actionSheetController.create({
        buttons: [
            {
                text: 'Später nochmal',
                handler: () => aInesRequestStore.respondToSelectedRequest(AInesRequestReactionType.Later)
            },
            {
                text: 'Nicht nochmal fragen',
                handler: () => aInesRequestStore.respondToSelectedRequest(AInesRequestReactionType.Dismissrequest)
            },
            {
                text: 'Nie wieder nachfragen',
                role: 'destructive',
                handler: async () => {
                    const alert = await alertController.create({
                        header: 'Nie wieder nachfragen',
                        message: `Wirklich keinerlei Nachfragen mehr zu ${aInesRequestStore.selection?.subjectLabel} stellen?`,
                        buttons: [
                            {
                                text: 'Abbrechen',
                                role: 'cancel'
                            },
                            {
                                text: 'Nie wieder',
                                role: 'confirm',
                                handler: () => aInesRequestStore.respondToSelectedRequest(AInesRequestReactionType.Ignorerequestsubject)
                            }
                        ],
                    });
                    await alert.present();

                    // defer action sheet dismiss (return false) after alert dismiss (only on confirm)
                    alert.onWillDismiss().then((e) => { if (e.role !== 'cancel') { actionSheet.dismiss() }})
                    return false
                }
            }
        ]
    })
    await actionSheet.present();
}

// private - only via store
async function fetchAInesRequests(): Promise<AInesRequest[]> {
    const result = await query(
        gql`query getAInesRequests {
            currentAInesRequests {
                scope
                subject
                subjectLabel
                shortLabel
                featureSet
                message {
                    text
                    richResponses {
                        responses {
                            type
                            reference
                        }
                    }
                }
                suggestions {
                    shortLabel
                    prompt
                    reactionType
                }
                urgent
            }
        }`
    )

    return result.data.currentAInesRequests
}

// private - only via store
async function respondToAInesRequest(response: AInesRequestReactionInput): Promise<AInesRequestReaction[]> {
    const MUTATION = gql`
        mutation respondToAInesRequest(
            $reactionType: AInesRequestReactionType!,
            $requestScope: String!,
            $requestSubject: AInesRequestSubject!
        ) {
            respondToAInesRequest(
                reactionType: $reactionType,
                requestScope: $requestScope,
                requestSubject: $requestSubject,
            ) {
                reactionType
                requestScope
                requestSubject
            }
        }`

    const result = await mutation(MUTATION, response)

    if (!result?.data?.respondToAInesRequest) {
        throw new Error("error while storing response")
    }

    return result?.data?.respondToAInesRequest
}
