<template>
    <ion-page class="page-planned">
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-button
                    color="secondary"
                    fill="clear"
                    v-on:click="router.go(-1)"
                    size="small"
                >
                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />
                </ion-button>
                <ion-title>
                    Dienst eintragen
                </ion-title>
            </ion-toolbar>
        </ion-header>
        
        <ion-content class="no-padding-top">
            <RegisterBookingComponent :key="forceReloadKey"></RegisterBookingComponent>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonHeader,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonContent,
    IonPage,
    IonButton, onIonViewWillEnter
} from "@ionic/vue";
import { useRouter } from "vue-router";
import RegisterBookingComponent from "./components/RegisterBookingTopSheet.vue";
import {ref} from "vue";

const router = useRouter();

const forceReloadKey = ref(0);
onIonViewWillEnter(() => forceReloadKey.value++);
</script>

<style lang="scss"></style>
