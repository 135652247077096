<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-button
                    color="secondary"
                    fill="clear"
                    v-on:click="router.go(-1)"
                    size="small"
                >
                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />
                </ion-button>
                <ion-title>Planung</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="no-padding-top">
            <AvailabilityPlanner ref="plannerRef" />
            <AZK />
        </ion-content>
    </ion-page>
</template>

<script setup>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonIcon, onIonViewWillEnter} from '@ionic/vue'
import { ref } from 'vue'
import AvailabilityPlanner from '@/components/AvailabilityPlanner.vue'
import AZK from './components/AZK.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const plannerRef = ref()

onIonViewWillEnter(() => {
    plannerRef.value.refresh()
})
</script>
