<template>
<div>
    <RichResponseListItem
        v-for="item in renderSet"
        iconType="image"
        :icon="item.icon"
        :title="item.title"
        :text="item.text"
        selectable
        :modelValue="selectedJobsiteId === item.jobsiteId"
        @update:modelValue="selectedJobsiteId = selectedJobsiteId === item.jobsiteId ? undefined : item.jobsiteId"
    />
    <ion-button
        expand="block"
        color="primary"
        class="floating"
        :disabled="selectedJobsiteId === undefined"
        @click="onClickSubmit"
    >
        Zeiterfassung starten
    </ion-button>
</div>
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import {IonButton,} from '@ionic/vue';
import { getImageForJobsite } from '@/helper/jobsite'
import { ref, computed } from "vue";
import { useTimesheetStore } from "@/store/timesheet"
import { pluralize } from "@/helper/amountFormatter";
import {useRouter} from "vue-router";

const props = defineProps<{
    initData: number|undefined
}>()

const router = useRouter()

const timesheetStore = useTimesheetStore()
const timesheetToDoJobsites = computed(()=> timesheetStore.getToDoJobsites)
const selectedJobsiteId = ref<number | undefined>(props.initData)

const onClickSubmit = () => {
    if(selectedJobsiteId.value){
        router.push({name: 'TimesheetProcess', params: { jobsiteId: selectedJobsiteId.value } })
    }
}

const renderSet = computed(() => {
    return timesheetToDoJobsites.value.map(jobsite => {
        const todos = timesheetStore.getToDosByJobsite(jobsite.jobsite)

        return {
            jobsiteId: jobsite.jobsite.id,
            icon: getImageForJobsite(jobsite.jobsite),
            title: jobsite.jobsite?.name,
            text: pluralize(todos.length, 'Dienst', 'Dienste')
        }
    })
})
</script>
