<script setup lang="ts">
import { PlanningMode } from '@/graphql/generated/graphql';
import { computed } from 'vue';
import ProgressCircle from './ProgressCircle.vue';

const props = defineProps<{
    amount: number,
    targetAmount: number|undefined,
    mode: PlanningMode
}>()

if (props.mode === PlanningMode.BookingPlanning && props.targetAmount == undefined) {
    throw new Error("BookingProgressCircle requires target amount for mode:BookingPlanning")
}

const label = computed(() => {
    switch (props.mode) {
        case PlanningMode.BookingPlanning: return props.amount == props.targetAmount ? props.amount : `${props.amount}/${props.targetAmount}`
        case PlanningMode.AvailabilityPlanning: return props.amount
    }
    return '';
})

const colorCode = computed(() => {
    switch (props.mode) {
        case PlanningMode.BookingPlanning: return props.amount == props.targetAmount ? 'success' : 'primary'
        case PlanningMode.AvailabilityPlanning: return 'secondary'
    }
    return 'warning';
})

const progress = computed(() => {
    switch (props.mode) {
        case PlanningMode.BookingPlanning: return props.amount / props.targetAmount
        case PlanningMode.AvailabilityPlanning: return 0
    }
    return 0;
})

</script>

<template>
    <ProgressCircle       
        :stroke-width="4"
        track-color="transparent"
        :stroke-color="colorCode"
        :style="`--month-progress-color: var(--ion-color-${colorCode})`"
        :value="progress"
        :glow="false"
    >
        {{ label }}
    </ProgressCircle>
</template>

<style lang="scss" scoped>
    .progress-circle {
        width: 4.5rem;
        height: 4.5rem;
        margin-left: -0.5rem;
        margin-right: 0.5rem;

        :deep(.content) {
            background-color: var(--ion-color-light);
            font-weight: var(--custom-font-weight-bold);
            color: var(--month-progress-color);
        }
    }        
</style>
