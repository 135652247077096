<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-button
                    color="secondary"
                    fill="clear"
                    v-on:click="router.go(-1)"
                    size="small"
                >
                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />
                </ion-button>
                <ion-title>Einstellungen</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="no-padding-top">
            <ion-list>
                <ion-item
                    v-for="link in links"
                    detail="true"
                    detailIcon="/icons/caret-right.svg"
                    v-on:click="link.action()"
                >
                    <ion-label>
                        <h3>{{ link.title }}</h3>
                        <p v-if="link.description">{{ link.description }}</p>
                    </ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
        <LogoutModal
            v-model="isLogoutModalActive"
        />
    </ion-page>
</template>

<script setup>
import { IonContent, IonHeader, IonItem, IonList, IonLabel, IonIcon, IonPage, IonTitle, IonToolbar, IonButton } from '@ionic/vue';
import { useRouter } from 'vue-router'
import LogoutModal from '@/components/LogoutModal.vue'
import { ref } from 'vue';

const router = useRouter()

const isLogoutModalActive = ref(false)

const links = [
    {
        title: 'Einsatzorte',
        description: 'Verwaltung deiner Lieblings- und Alternativstationen',
        action: () => router.push('/settings/favorite-jobsites')
    },
    {
        title: 'Verfügbarkeitsvorlage',
        description: 'Wochenvorlage für Verfügbarkeiten, Einschränkungen und Abwesenheiten',
        action: () => router.push('/settings/availability-templates')
    },
    {
        title: 'Dienst- und Verfügbarkeitsplanung',
        description: 'AZK Zielwert und automatisierte Verfügbarkeitsplanung',
        action: () => router.push('/settings/planning-dates')
    },
    /* {
        title: 'Vertragsstunden',
        description: 'Details zu deinem Vertrag',
        action: () => router.push('')
    }, */
    {
        title: 'Ausloggen',
        action: () => isLogoutModalActive.value = true
    }
]
</script>
