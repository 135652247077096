<script setup lang="ts">
import {
    IonButton,
   IonHeader, IonToolbar, IonIcon, IonTitle, IonButtons
} from "@ionic/vue";

const props = defineProps({
    timesheetNavigator: {
        type: Object,
        required: true
    }
})

</script>

<template>
    <ion-header :translucent="true">
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button
                    color="secondary"
                    fill="clear"
                    v-on:click="props.timesheetNavigator.cancel()"
                    size="small"
                >
                    <ion-icon slot="icon-only" src="/icons/x.svg" />
                </ion-button>
            </ion-buttons>

            <ion-title>Zeiterfassung {{props.timesheetNavigator.getCurrentPageIndex()+1}}/{{props.timesheetNavigator.getPageLength()}}</ion-title>

        </ion-toolbar>
    </ion-header>
</template>
