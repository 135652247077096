const listener : { (error: Error|string): void; } [] = [];


export function addErrorListener(callback:{(error: Error|string):void;}) {
    listener.push(callback)
}

export function errorCatcher (error : Error | string) {
    console.error(error)
    listener.forEach((callback) => callback(error))
}





