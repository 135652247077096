import {query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {Employee, EmployeeContract} from "@/graphql/generated/graphql";
import {CalendarMonth} from "@/helper/calendar/calendarMonth";

export async function fetchEmployee(): Promise<Employee> {
    const result = await query(
        gql`query getEmployee {
            employee {
                id,
                name,
                contracts {
                    validFrom,
                    validTo,
                    monthlyHours
                },
                firstContract {
                    validFrom,
                    validTo,
                    monthlyHours
                },
                aZK {
                    validMonth,
                    value
                },
                targetAZK{
                    value
                }
                user {
                    id,
                    userName
                },
                offerableQualifications {
                    id,
                    label
                }
            }
        }`
    )

    return result.data.employee
}

export async function fetchEmployees(): Promise<Employee[]> {
    const result = await query(
        gql`query getEmployees {
            employees {
                id,
                name,
                user {
                    id,
                    userName
                }
            }
        }`
    )

    return result.data.employees
}

export async function fetchEmployeeContractFor(month: CalendarMonth): Promise<EmployeeContract> {
    const result = await query(
        gql`query getEmployee($month:KapitelDateImmutable!) {
            employee {
                contract(month: $month) {
                    validFrom,
                    validTo,
                    monthlyHours
                },
            }
        }`,
        {
            month: month.begin
        }
    )

    return result.data.employee.contract
}
