<template>
    <RichResponseListItem
        iconType="text"
        title="Station verwalten"
        :text="hasSearchString ? 'Suche nach ' + initData :'Tippe hier, um deine Stationen zu verwalten.'"
        :selectable="false"
    />
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import {computed, Ref} from "vue";

const props = defineProps<{
    initData: string
}>()

const hasSearchString: Ref<boolean> = computed(() => props.initData.length > 0)

</script>
