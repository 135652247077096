import {AInesAssistantType} from "@/graphql/generated/graphql";
import {AssistantStreamEvent} from "openai/resources/beta/assistants";
import {KapitelToolCall} from "@/helper/chat/toolCalls";

export type AssistantRunConfig = {
    assistantType: AInesAssistantType,
    assistantId: string,
    threadId: string
}
export type AssistantRunEvents = {
    stateChanged: AssistantRunState,
    runRequiresToolCall: KapitelToolCall
    runResponseStarted: void,
    // runStreamEvent: AssistantStreamEvent
    runComplete: void
};

export enum AssistantRunState {
    INIT,

    REQUESTED,

    RUNNING,
    PENDING_TOOL_CALL,

    COMPLETED,
    CANCELLED,
    ABORTED,
}
export namespace AssistantRunState {
    export function isTerminal(state: AssistantRunState) {
        return state === AssistantRunState.COMPLETED || state === AssistantRunState.CANCELLED || state === AssistantRunState.ABORTED;
    }

    export function transitionTo(nextState: AssistantRunState, previousState: AssistantRunState) : AssistantRunState {
        if (
            previousState < nextState ||
            (previousState === AssistantRunState.PENDING_TOOL_CALL && nextState === AssistantRunState.RUNNING)
        ) {
            return nextState;
        }

        console.error("dropping assistant run state transition from " + AssistantRunState[previousState] + " to " + AssistantRunState[nextState])
        return previousState
    }
}
