<template>
    <ion-content class="no-padding-top">
        <!-- Autopilot -->
        <ion-card>
            <ion-card-header>
                <ion-card-title>Dienstplanung</ion-card-title>
            </ion-card-header>

            <Skeleton v-if="initialDataLoader.pending.value" :items="['block--height-16rem']"></Skeleton>

            <ion-card-content v-else>
                <div class="icon-with-text">
                    <div class="icon">
                        <InlineSVG :src="autopilotSummary.playStateIcon" />
                        <InlineSVG :src="autopilotSummary.worktimeIcon" />
                    </div>
                    <div class="text">
                        <h3>
                            <span v-if="autopilotSummary.statusIsRunning">Die Dienstplanung läuft</span>
                            <span v-if="autopilotSummary.statusIsPaused">Die Dienstplanung ist pausiert</span>
                            <span v-if="autopilotSummary.statusIsScrapped">Die Dienstplanung ist gestoppt</span>
                        </h3>
                        <p>
                            {{ pluralize(bookingSummary.shifts, "Dienst", "Dienste") }} bereits geplant ({{ pluralize(bookingSummary.hoursLabel, "Stunde", "Stunden") }}).<br>
                            {{ autopilotSummary.worktimeLabel }}.
                        </p>
                    </div>
                </div>
                <ion-button
                    @click="onClickOpenWorktimeModeModal"
                    color="light"
                    expand="block"
                >
                    Überstunden-Modus ändern
                </ion-button>
                <template v-if="authStore.isImpersonating()">
                    <ion-button
                        v-if="autopilotSummary.statusIsRunning"
                        @click="onClickChangeAutopilotPlayState"
                        color="light"
                        expand="block"
                    >
                        Dienstplanung pausieren
                    </ion-button>
                    <ion-button
                        v-if="autopilotSummary.statusIsPaused || autopilotSummary.statusIsScrapped"
                        @click="onClickChangeAutopilotPlayState"
                        color="light"
                        expand="block"
                    >
                        Dienstplanung fortsetzen
                    </ion-button>
                </template>
            </ion-card-content>
        </ion-card>

        <!-- Jobsites -->
        <ion-card class="card-bedarf">
            <ion-card-header>
                <ion-card-title>Einsatzorte</ion-card-title>
            </ion-card-header>

            <Skeleton v-if="initialDataLoader.pending.value" :items="['block--height-16rem']"></Skeleton>

            <ion-card-content v-else>
                <div class="icon-with-text">
                    <div class="icon">
                        <InlineSVG src="/icons/heart.svg" />
                        <InlineSVG
                            v-if="!autopilotSummary.jobsiteSummary.favoriteOnly"
                            src="/icons/plus.svg"
                        />
                    </div>
                    <div class="text">
                        <h3>
                            <span>
                                {{ autopilotSummary.jobsiteSummary.favoriteOnly ? 'Nur Lieblingsstationen' : 'Angepasste Stationsauswahl' }}
                            </span>
                        </h3>
                        <p>
                            <span>
                                {{ pluralize(autopilotSummary.jobsiteSummary.count, "Station", "Stationen") }} ausgewählt.<br>
                            </span>
                            <span v-if="!autopilotSummary.jobsiteSummary.favoriteOnly">
                                {{ detailedJobsiteListLabel }} ({{ detailedClientListLabel }})
                            </span>
                        </p>
                    </div>
                </div>
                <ion-button
                    color="light"
                    expand="block"
                    @click="router.push({ name: 'AutopilotJobsitePreference', params: { monthId: calendarMonth.getId() } })"
                >
                    Stationsauswahl
                </ion-button>
            </ion-card-content>
        </ion-card>

        <!-- Verfügbarkeiten -->
        <ion-card>
            <ion-card-header>
                <ion-card-title>Verfügbarkeiten</ion-card-title>
            </ion-card-header>

            <Skeleton v-if="initialDataLoader.pending.value" :items="['block--height-16rem']"></Skeleton>

            <ion-card-content v-else>
                <div class="icon-with-text">
                    <div class="icon">
                        <InlineSVG src="/icons/check.svg" />
                    </div>
                    <div class="text">
                        <h3>
                            Verfügbarkeitsplanung abgeschlossen
                        </h3>
                        <p>
                            {{ pluralize(availabilitySummary.count, "Verfügbarkeit", "Verfügbarkeiten") }} ({{ availabilitySummary.shiftTypesList.join(',') }})
                            <span v-if="absenceSummary.count">
                                <br>
                                {{ pluralize(absenceSummary.count, "Tag", "Tage") }} abwesend ({{ absenceSummary.typeLabelList.join(', ') }})
                            </span>
                        </p>
                    </div>
                </div>
                <ion-button
                    @click="router.push('/calendar?date=' + calendarMonth.begin)"
                    color="light"
                    expand="block"
                >
                    Im Kalender bearbeiten
                </ion-button>
            </ion-card-content>
        </ion-card>

        <Modal
            v-model="isChangeWorktimeModalActive"
            title="Dienstplanung"
        >
            <RadioGroup
                v-model="worktimeModeForRadioGroup"
                :items="getWorktimeModes()"
                v-slot="{ item }"
                class="worktime-mode-radio-group"
            >
                <InlineSVG :src="getIconForWorktimeMode(item)" />
                <span>
                    {{ getWorktimeModeLabel(item) }}
                </span>
            </RadioGroup>
            <ion-button
                color="light"
                expand="block"
                @click="onClickUpdateWorktimeMode"
            >
                Ok
            </ion-button>
        </Modal>
    </ion-content>
</template>

<script setup lang="ts">
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonIcon
} from '@ionic/vue';
import { computed, Ref, ref, defineProps, defineExpose } from 'vue';
import { useRouter } from 'vue-router';
import { generateCalendarMonth } from "@/helper/calendar/calendarMonth";
import {
    DayObject,
    fetchDayObjectsForDateRange,
    yieldEffectiveAbsences,
    yieldEffectiveAvailabilities,
    yieldEffectiveBookings
} from "@/helper/day";
import { updateAutopilotPreference } from "@/helper/autopilotPreference";
import { DataLoader } from "@/helper/dataLoader";
import { useEmployeeStore } from "@/store/employee";
import { getAvailabilitySummary } from "@/helper/availability";
import { getBookingSummary } from "@/helper/booking";
import { getAbsenceSummary } from "@/helper/absence";
import {
    getWorktimeModes,
    getWorktimeModeLabel,
    getIconForWorktimeMode
} from "@/helper/autopilotPreference";

import RadioGroup from '@/components/RadioGroup.vue';
import InlineSVG from '@/components/InlineSVG.vue';
import Modal from '@/components/Modal.vue';
import Skeleton from "@/components/Skeleton.vue";
import { pluralize } from '@/helper/amountFormatter';
import {
    AutopilotPlayState,
    AutopilotPreference,
    AutopilotStatus,
    House
} from "@/graphql/generated/graphql";
import { fetchAutopilotStatus, getAutopilotStatusSummary } from "@/helper/autopilotStatus";
import { useAuthStore } from '@/store/auth';
import { jobsiteSummaryLabel } from '@/helper/jobsite';

import { kapitelDateString } from '@/graphql/kapitelTypes';

const props = defineProps<{
    monthId: kapitelDateString
}>();

const router = useRouter();
const authStore = useAuthStore();
const calendarMonth = generateCalendarMonth(props.monthId);
const days: Ref<DayObject[]> = ref([]);
const employee = ref();
const autopilotStatus: Ref<AutopilotStatus | undefined> = ref();
const autopilotPreference: Ref<AutopilotPreference | undefined> = ref();

const initialDataLoader = new DataLoader([
    async () => {
        days.value = await fetchDayObjectsForDateRange(calendarMonth.begin, calendarMonth.until);
    },
    async () => {
        employee.value = await useEmployeeStore().getEmployee();
    },
    async () => {
        const result = await fetchAutopilotStatus(calendarMonth);
        autopilotStatus.value = result;
        autopilotPreference.value = result.autopilotPreferences;
    },
]);

const availabilitySummary = computed(() =>
    getAvailabilitySummary(
        days.value ? yieldEffectiveAvailabilities(days.value) : []
    )
);
const absenceSummary = computed(() =>
    getAbsenceSummary(
        days.value ? yieldEffectiveAbsences(days.value) : []
    )
);
const bookingSummary = computed(() =>
    getBookingSummary(
        days.value ? yieldEffectiveBookings(days.value) : []
    )
);
const autopilotSummary = computed(() =>
    getAutopilotStatusSummary(autopilotStatus.value)
);

const detailedJobsiteListLabel = computed(() =>
    jobsiteSummaryLabel(autopilotSummary.value.jobsiteSummary.jobsites)
);

const detailedClientListLabel = computed(() => {
    const jobsiteSummary = autopilotSummary.value.jobsiteSummary;

    // Take houses where possible and clients from the rest
    let houses = jobsiteSummary.jobsites
        .map((js) => js.house)
        .filter((h): h is House => !!h);
    let clients = jobsiteSummary.jobsites
        .filter((j) => !j.house)
        .map((js) => js.client);

    // Unique houses and clients
    houses = houses.filter(
        (h, i) => houses.findIndex((h2) => h2.name === h.name) === i
    );
    clients = clients.filter(
        (c, i) => clients.findIndex((c2) => c2.name === c.name) === i
    );

    const components = houses
        .map((h) => h.abbreviation || '')
        .concat(clients.map((c) => c.abbreviation || ''));

    return components.join(', ');
});

/**
 * Autopilot Preference
 */
const onClickChangeAutopilotPlayState = () => {
    if (!autopilotPreference.value) {
        return;
    }

    switch (autopilotPreference.value.playState) {
        case AutopilotPlayState.Running:
            autopilotPreference.value.playState = AutopilotPlayState.Paused;
            break;
        case AutopilotPlayState.Paused:
            autopilotPreference.value.playState = AutopilotPlayState.Running;
            break;
        case AutopilotPlayState.Scrapped:
            autopilotPreference.value.playState = AutopilotPlayState.Running;
            break;
    }

    updateAutopilotPreference(calendarMonth, {
        playState: autopilotPreference.value.playState
    });
};

/**
 * Worktime Mode
 */
const isChangeWorktimeModalActive = ref(false);
const worktimeModeForRadioGroup = ref();

const onClickOpenWorktimeModeModal = () => {
    worktimeModeForRadioGroup.value = autopilotPreference.value?.worktimeMode;
    isChangeWorktimeModalActive.value = true;
};

const onClickUpdateWorktimeMode = () => {
    const worktimeMode = worktimeModeForRadioGroup.value;

    if (!autopilotPreference.value) {
        return;
    }

    autopilotPreference.value.worktimeMode = worktimeMode;
    updateAutopilotPreference(calendarMonth, { worktimeMode: worktimeMode });
    isChangeWorktimeModalActive.value = false;
};

/**
 * Lifecycle methods
 */
const reload = () => {
    initialDataLoader.load();
};

reload(); // Load data initially

defineExpose({
    reload
});
</script>

<style lang="scss" scoped>
.icon-with-text {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;

    > .icon {
        flex: 0 0 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.75rem;
        margin-top: 0.75rem;

        .inline-svg {
            width: 40%;

            svg {
                color: var(--ion-color-primary);
                filter: drop-shadow(0 0 0.4rem rgba(var(--ion-color-primary-rgb), 0.4));
            }
        }
    }

    > .text {
        h3 {
            color: var(--custom-h3-color);
            font-size: var(--custom-h3-font-size);
            line-height: var(--custom-h3-line-height);
            font-weight: var(--custom-h3-font-weight);
        }

        p {
            font-size: var(--custom-font-size-regular);
            color: var(--ion-color-medium);
        }
    }
}

.worktime-mode-radio-group {
    ion-radio {
        .inline-svg {
            display: inline-block;
            margin-right: 0.5rem;
            width: 1rem;
            height: 1rem;
            color: var(--ion-color-primary);
        }
    }
}
</style>
