<template>    
    <IonPage>
        <IonContent fullscreen padding="true">
            <TimesheetList
                v-if="false"
                init-data="1"
            />
            <JobsiteList
                v-if="false"
                :init-data="AInesJobsiteListType.Favorite"
            />
                <!--{listType:, selectable: true}-->
            <PlanningStatus
                v-if="false"
                init-data="2024-09-01"
            />
            <MonthCalendar
                v-if="false"
                init-data="2024-09-01"
            />
            <WeekCalendar
                v-if="false"
                init-data="2024-10-01"
            />
            <DayCalendar
                v-if="false"
                init-data="2024-10-01"
            />
            <RegisterBookingSummary
                v-if="false"
                :init-data="{jobsite:'Station 3', shift: ShiftType.Fd, dates: ['2024-10-01']}"
                @registerBooking="onRegisterBooking"
            />
            <JobsiteSearch
                v-if="false"
                init-data="WMK-S4"
            />

            <Modal v-model="showRegisterBooking" title="Dienst eintragen">
                <RegisterBooking :jobsite="registerBookingParams.jobsite" :shiftDates="registerBookingParams.shiftDates"></RegisterBooking>
            </Modal>

            <Modal v-model="showDetailTopsheet" title="Detail" class="no-padding-left no-padding-right">
                <JobsiteDetails
                    v-if="false"
                    :jobsite-id="'123'"
                ></JobsiteDetails>
                <AvailabilityAbsenceEditor
                    v-if="false"
                    :availability="undefined"
                    :absence="undefined"
                    :date="undefined"
                ></AvailabilityAbsenceEditor>
                <CalendarDetail
                    v-if="false"
                    :date-id="undefined"
                ></CalendarDetail>
                <Calendar
                    v-if="true"
                ></Calendar>
            </Modal>
            <Modal v-model="showProcessTopsheet" title="Bestätigung">
                <RegisterBookingTopSheet
                    v-if="false"
                    :init-data="undefined"
                ></RegisterBookingTopSheet>
                <JobsiteSearchTopSheet
                    v-if="false"
                    :init-data="''"
                ></JobsiteSearchTopSheet>
            </Modal>

        </IonContent>
    </IonPage>
</template>

<script setup lang="ts">
import {IonContent, IonPage} from '@ionic/vue';
import RegisterBookingSummary, {registerBookingParameter} from "@/views/Chat/RichResponses/RegisterBookingSummary.vue";
import {AInesJobsiteListType, AInesRichResponseType, ShiftType} from "@/graphql/generated/graphql";
import JobsiteList from "@/views/Chat/RichResponses/JobsiteList.vue";
import PlanningStatus from "@/views/Chat/RichResponses/PlanningStatus.vue";
import WeekCalendar from "@/views/Chat/RichResponses/WeekCalendar.vue";
import DayCalendar from "@/views/Chat/RichResponses/DayCalendar.vue";
import MonthCalendar from "@/views/Chat/RichResponses/MonthCalendar.vue";
import Modal from "@/components/Modal.vue";
import RegisterBooking from "@/views/components/RegisterBookingTopSheet.vue";
import {Ref, ref} from "vue";
import JobsiteSearch from "@/views/Chat/RichResponses/JobsiteSearch.vue";
import TimesheetList from "@/views/Chat/RichResponses/TimesheetList.vue";
import JobsiteDetails from "@/views/JobsiteDetails.vue";
import AvailabilityAbsenceEditor from "@/components/AvailabilityAbsenceEditor.vue";
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import Calendar from "@/views/Calendar/Calendar.vue";
import JobsiteSearchTopSheet from "@/views/components/JobsiteSearchTopSheet.vue";
import RegisterBookingTopSheet from "@/views/components/RegisterBookingTopSheet.vue";

const registerBookingParams : Ref<registerBookingParameter> = ref({jobsite: undefined, shiftDates: undefined})
const showRegisterBooking: Ref<boolean> = ref(false);
const onRegisterBooking = (a: registerBookingParameter) => {
    registerBookingParams.value = a
    showRegisterBooking.value = true
}

const showDetailTopsheet: Ref<boolean> = ref(true);
const showProcessTopsheet: Ref<boolean> = ref(false);
</script>

<style lang="scss">
// TWINKIIIII
ion-modal .ion-page>.body {
    height: 90vh;
}
</style>
