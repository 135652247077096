<template>
<div>
    <RichResponseHeadline
        title="Neuer Dienst"
    />
    <RichResponseListItem
        :title="renderSet.daysTitle"
        :text="renderSet.daysText"
        :icon="renderSet.daysIcon"
        :icon-blurred="!renderSet.daysIsSet"
        icon-type="text"
    />
    <RichResponseListItem
        :title="renderSet.shiftTitle"
        :text="renderSet.shiftText"
        :icon="renderSet.shiftIcon"
        :icon-blurred="!renderSet.shiftIsSet"
        icon-type="text"
    />
    <RichResponseListItem
        :title="renderSet.jobsiteTitle"
        :text="renderSet.jobsiteText"
        :icon="renderSet.jobsiteIcon"
        :icon-blurred="!renderSet.jobsiteIsSet"
        :icon-type="renderSet.jobsiteIsSet ? 'image' : 'text'"
    />
    <ion-button
        expand="block"
        color="primary"
        class="floating"
    >
        Bestätigen
    </ion-button>
</div>
</template>

<script setup lang="ts">
import date from "@/helper/datetime/date";
import RichResponseHeadline from "./components/RichResponseHeadline.vue";
import RichResponseListItem from "./components/RichResponseListItem.vue";
import { IonButton } from '@ionic/vue';
import { computed, ref, watch } from "vue";
import { Jobsite, ShiftType } from "@/graphql/generated/graphql";
import { getLabelForShift } from '@/helper/shiftTypes';
import { getImageForJobsite } from '@/helper/jobsite';
import { kapitelDateString } from '@/graphql/kapitelTypes';
import {resolveJobsite, shiftDate} from "@/helper/registerBooking";

export type registerBookingParameter = {jobsite: Jobsite | undefined, shiftDates: shiftDate[] | undefined}

const emit = defineEmits<{
    registerBooking: [a: registerBookingParameter]
}>()

const onButtonClick = () => {
    let shiftDates = undefined
    if (props.initData.dates && props.initData.shift) {
        shiftDates = props.initData.dates.map(d => ({
            type: props.initData.shift,
            date: d
        }) as shiftDate)
    }

    emit('registerBooking', {
        jobsite: resolvedJobsite.value,
        shiftDates,
    })
}

const props = defineProps<{
    initData: {
        jobsite: string,
        dates: Array<kapitelDateString>,
        shift: ShiftType
    }
}>()

const resolvedJobsite = ref();
const updateResolvedJobsite = async () => {resolvedJobsite.value = await resolveJobsite(props.initData.jobsite)}
watch(() => props.initData.jobsite, updateResolvedJobsite);
updateResolvedJobsite()

const renderSet = computed(() => {
    return {
        shiftIcon: props.initData.shift || '?',
        shiftTitle: props.initData.shift ? getLabelForShift(props.initData.shift) : 'Schicht',
        shiftText: 'die Uhrzeit der Schicht' || 'noch nicht gesetzt',
        shiftIsSet: props.initData.shift !== undefined,

        jobsiteIcon: resolvedJobsite.value ? getImageForJobsite(resolvedJobsite.value) : '?',
        jobsiteTitle: resolvedJobsite.value?.name || 'Einsatzort',
        jobsiteText: !resolvedJobsite.value ? 'noch nicht gesetzt' : resolvedJobsite.value?.client?.name,
        jobsiteIsSet: !!resolvedJobsite.value,

        daysIcon: props.initData?.dates ? props.initData.dates?.length.toString() : '?',
        daysTitle: props.initData.dates?.length === 1 ? 'Tag' : 'Tage',
        daysText: props.initData.dates ? props.initData.dates.map((d) => date.formatKapitelDate(d, 'd. L. yy')).join(', ') : 'noch nicht gesetzt',
        daysIsSet: props.initData.dates !== undefined
    }
})
</script>

<style lang="scss" scoped>
ion-button {
    margin-top: 1em;
}
</style>
