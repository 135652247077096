<script setup lang="ts">
import {
    IonContent,
    IonList,
    IonItem,
} from "@ionic/vue";
import {computed} from "vue";
import TimesheetHeader from "@/views/Timesheet/components/TimesheetHeader.vue";
import {useTimesheetStore} from "@/store/timesheet";

const store = useTimesheetStore();

const props = defineProps({
    timesheetNavigator: {
        type: Object,
        required: true
    }
})

const timesheetStore = useTimesheetStore()

const timesheetToDoJobsites = computed(()=> store.getToDoJobsites)

const onJobsiteSelection = (jstd:any) => {
    timesheetStore.startSignatureProcess(parseInt(jstd.jobsite.id))
    props.timesheetNavigator.next()
}

</script>

<template>
    <TimesheetHeader :timesheet-navigator="timesheetNavigator"></TimesheetHeader>
    <ion-content class="no-padding-top">
        <ion-list>
            <ion-item v-for="jstd in timesheetToDoJobsites" :key="jstd.jobsite.id" :button="true" @click="onJobsiteSelection(jstd)">
                {{jstd.description}} {{jstd.jobsite.id}}
            </ion-item>
        </ion-list>
    </ion-content>
</template>
