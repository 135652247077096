<script setup lang="ts">
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonItem,
    IonLabel,
    IonList
} from "@ionic/vue";
import {cmpTimesheetBookingBegin, getSummary} from "@/helper/timesheet";
import {computed, ref} from "vue";
import TimesheetEditorModal from "../components/TimesheetEditorModal.vue"
import {TimesheetToDo} from "@/graphql/generated/graphql";
import TimesheetHeader from "@/views/Timesheet/components/TimesheetHeader.vue";
import {useTimesheetStore} from "@/store/timesheet";

const store = useTimesheetStore();

const props = defineProps({
    timesheetNavigator: {
        type: Object,
        required: true
    }
})

const isTimesheetEditorModalOpen = ref(false)
const editTimesheet = ref<TimesheetToDo|undefined>(undefined)


const timesheetToDosInSignatureProcess = computed(()=> 
    store.timesheetToDosInSignatureProcess
        .toSorted(cmpTimesheetBookingBegin)
        .map((tstd)=>getSummary(tstd, store.timesheetToDos))
)


const onEditTimesheetTodo = (timesheetToDo: TimesheetToDo) => {
  editTimesheet.value = timesheetToDo
  isTimesheetEditorModalOpen.value = true
}

const onUpdateTimesheetToDo = (timesheetToDo: TimesheetToDo) => {
  store.updateTimesheet(timesheetToDo)
}

const onDeselectTimesheetToDo = () => {
  if(editTimesheet.value){
    store.removeTimesheetToDoFromSignatureProcess(editTimesheet.value)
  }
}

</script>

<template>
    <TimesheetHeader :timesheet-navigator="timesheetNavigator"></TimesheetHeader>
        <ion-content class="no-padding-top">

        <ion-card class="custom-ion-card-dark">
            <ion-card-content
                class="no-padding-top no-padding-bottom"
                v-if="timesheetToDosInSignatureProcess.length>0"
            >
            <ion-list>
            <ion-item lines="none">
            <ion-label>
            <h3 class="text-white">
                Einsatzort
            </h3>
            <p class="text-light">
                {{
                timesheetToDosInSignatureProcess.length>0?(timesheetToDosInSignatureProcess[0]).jobsiteDescription:""
                }}
            </p>
            </ion-label>
            </ion-item>
            </ion-list>
            </ion-card-content>
        </ion-card>
        <ion-card>
        <ion-card-header>
            <ion-card-title>
                Dienstauswahl und Zeiten anpassen
            </ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <ion-list

                class="custom-ion-card-light"
                >

                <ion-item
                v-for="timesheetToDo in timesheetToDosInSignatureProcess"
                :key="timesheetToDo.date"
                detail="true"
                detailIcon="/icons/caret-right.svg"
                @click="timesheetToDo.ref ? onEditTimesheetTodo(timesheetToDo.ref):()=>{}">
                <ion-label>
                    <h3>{{ timesheetToDo.date }} {{ timesheetToDo.modified ? "(Angepasst)": "" }}</h3>
                    <p>
                    {{ timesheetToDo.begin }} - {{ timesheetToDo.until }}
                    ({{timesheetToDo.breakBegin.length>0?"Pause: ":"Keine Pause"}}{{ timesheetToDo.breakBegin }}{{timesheetToDo.breakBegin.length>0?" - ":""}}{{ timesheetToDo.breakUntil }})
                    </p>
                </ion-label>
                </ion-item>
            </ion-list>
        </ion-card-content>
        </ion-card>
            <TimesheetEditorModal                
                v-model="isTimesheetEditorModalOpen"
                :timesheet-to-do="editTimesheet"
                v-on:update:timesheet="onUpdateTimesheetToDo"
                v-on:toggle:deselect:timesheet="onDeselectTimesheetToDo"
            />
            <!-- v-on:update:time-constraint="onUpdateConstraint"
            v-on:remove:time-constraint="onRemoveConstraint" -->

            <ion-button
                @click="timesheetNavigator.next()"
                expand="block"
                :disabled="timesheetToDosInSignatureProcess.length === 0"
            >Nächster Schritt</ion-button>
        </ion-content>

</template>
