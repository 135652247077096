<template>
    <ion-content class="jobsite-selector-modal-scroller no-padding-top">
        <ion-list>
            <template v-for="segment in segments" :key="segment.category">
                <IonListHeader v-if="segments.length > 1 && categorize">
                    {{ getLabelForJobsiteCategory(segment.category) }}
                </IonListHeader>

                <ion-item
                    v-for="item in segment.jobsites"
                    :key="item.id"
                    :detail="false"
                    @click="onClick(item)"
                    :class="isAlreadySelected(item) ? 'alreadySelected' : ''"
                >
                    <ion-label>
                        <h3>{{ item.name }}</h3>
                        <p>
                            {{ item.client?.abbreviation }}
                            <span v-if="item.house">, {{ item.house?.abbreviation }}</span>
                            <span v-if="item.additionalAmenImportantAbbreviations?.length > 0">
                                , {{ item.additionalAmenImportantAbbreviations.join(", ") }}
                            </span>
                        </p>
                    </ion-label>
                    <ion-icon
                        aria-hidden="true"
                        :icon="checkmarkCircle"
                        color="primary"
                        slot="end"
                        v-if="isAlreadySelected(item)"
                    />
                </ion-item>
            </template>
        </ion-list>
        <ion-infinite-scroll
            :disabled="endOfSearchListReached"
            @ionInfinite="ionInfinite"
        >
            <ion-infinite-scroll-content />
        </ion-infinite-scroll>
    </ion-content>
    <div class="jobsite-selector-actions">
        <ion-searchbar
            :debounce="200"
            @ion-input="({ target }) => searchByNewQuery(target)"
            placeholder="Suchen ..."
            class="jobsite-selector-searchbar"
        />
        <ion-button
            color="secondary"
            expand="block"
            @click="onClose"
        >
            Abbrechen
        </ion-button>
    </div>
</template>

<script setup lang="ts">
import {
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    IonItem,
    IonLabel,
    IonList,
    IonSearchbar,
    IonListHeader,
    IonIcon,
} from "@ionic/vue";
import { ellipseOutline, checkmarkCircle } from 'ionicons/icons';
import {
    JobsiteCategorySegment,
    fetchJobsites
} from "@/helper/jobsite";
import { PropType, Ref, ref, watch, defineExpose } from "vue";
import { Jobsite } from "@/graphql/generated/graphql";
import { JobsiteCategory } from "@/graphql/kapitelTypes";
import { getLabelForJobsiteCategory } from "@/helper/jobsiteCategory";

const props = defineProps({
    categorize: {
        type: Boolean,
        required: false,
        default: true
    },
    alreadySelectedJobsites: {
        type: Array as PropType<Jobsite[]>,
        required: false,
        default: () => []
    },
    quickSelectJobsiteCategorySegments: {
        type: Array as PropType<JobsiteCategorySegment[]>,
        required: false,
        default: () => []
    }
});

const emit = defineEmits(['select', 'close']);

const loading = ref(false);

interface JobsiteListSegment {
    category: string;
    jobsites: Ref<Array<Jobsite>>;
}

const segments: Ref<Array<JobsiteListSegment>> = ref([]);
const searchResultSegment: JobsiteListSegment = {
    category: JobsiteCategory.Other,
    jobsites: ref([])
};
const pagination = ref(0);
const pageSize = 25;
const searchQuery = ref("");
const endOfSearchListReached = ref(false);

const searchByNewQuery = target => {
    searchQuery.value = target.value;
    resetList();
};

const resetList = () => {
    pagination.value = 0;
    searchResultSegment.jobsites.value = [];
    segments.value = [searchResultSegment];
    endOfSearchListReached.value = false;

    resetScrollPosition();

    if (prependingQuickSelectResults()) {
        segments.value.unshift(
            ...props.quickSelectJobsiteCategorySegments.map(({ category, jobsites }) => ({
                category,
                jobsites: ref(jobsites)
            }))
        );
    }

    fetchNextPageResults();
};

const prependingQuickSelectResults = () => !searchQuery.value;

const fetchNextPageResults = () => {
    loading.value = true;
    const currentSearchQuery = searchQuery.value;
    const excludeJobsites = prependingQuickSelectResults()
        ? props.quickSelectJobsiteCategorySegments.reduce(
            (agg: Array<Jobsite>, segment) => agg.concat(segment.jobsites),
            []
        )
        : [];

    return fetchJobsites(searchQuery.value, pagination.value, pageSize, excludeJobsites)
        .then(gqlResult => {
            // Discard outdated queries
            if (currentSearchQuery != searchQuery.value) {
                return;
            }

            if (gqlResult.length == 0 || gqlResult[gqlResult.length - 1].isLastItem) {
                endOfSearchListReached.value = true;
            }

            searchResultSegment.jobsites.value.push(
                ...gqlResult.map(sj => sj.jobsite)
            );
        })
        .finally(() => (loading.value = false));
};

const ionInfinite = ev => {
    pagination.value = pagination.value + pageSize;
    fetchNextPageResults().then(() => {
        ev.target.complete();
    });
};

const onClick = (item: Jobsite) => {
    emit('select', item);
};

const onClose = () => {
    resetComponentState();
    emit('close');
};

const resetComponentState = () => {
    loading.value = false;
    searchResultSegment.jobsites.value = [];
    segments.value = [searchResultSegment];
    pagination.value = 0;
    searchQuery.value = "";
};

function resetScrollPosition() {
    console.log("reset scroll position");
    document
        .querySelector('.jobsite-selector-modal-scroller')
        ?.scrollToTop();
}

function isAlreadySelected(jobsite: Jobsite) {
    return !!props.alreadySelectedJobsites.find(item => item.id === jobsite.id);
}

/**
 * Lifecycle methods
 */
const reload = () => {
    resetList();
};

reload();

defineExpose({
    reload
});
</script>

<style lang="scss" scoped>
.jobsite-selector-modal-scroller {
    height: 70vh;
    overflow: auto;
}

.jobsite-selector-searchbar {
    padding-left: 0;
    padding-right: 0;
}

.jobsite-selector-actions {
    padding-top: 1rem;
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
}

.alreadySelected {
    opacity: 0.75;
}
</style>
