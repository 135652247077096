<template>
    <Skeleton
        v-if="loader.pending.value"
        :items="['margin', 'title', 'block--height-9rem']"
    ></Skeleton>
    <ion-card v-if="!loader.pending.value" class="custom-ion-card-floating">
        <ion-card-header>
            <ion-card-title>Station</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <ion-list>
                <ion-item
                    detail
                    detailIcon="/icons/caret-right.svg"
                    v-on:click="isChangeJobsiteModalActive = true"
                    lines="none"
                >
                    <ion-label>
                        <h3>{{ jobsite?.name }}</h3>
                        <p>{{ jobsite ? getJobsiteShortestCompleteDescription(jobsite, true) : "" }}</p>
                    </ion-label>
                </ion-item>
            </ion-list>
            <JobsiteSelectorModal
                v-model="isChangeJobsiteModalActive"
                @select="onChangeJobsite"
                :alreadySelectedJobsites="alreadySelectedJobsites"
                :quick-select-jobsite-category-segments="employeeJobsiteSegments"

            />
        </ion-card-content>
    </ion-card>
    <Skeleton
        v-if="loader.pending.value"
        :items="['margin', 'title', 'block--height-9rem']"
    ></Skeleton>
    <ion-card v-if="!loader.pending.value" class="custom-ion-card-floating">
        <ion-card-header>
            <ion-card-title>Qualifikation</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <ion-list>
                <ion-item
                    detail
                    detailIcon="/icons/caret-right.svg"
                    v-on:click="isChangeQualificationModalActive = true"
                    lines="none"
                >
                    <ion-label>
                        <h3>{{ qualification?.label }}</h3>
                    </ion-label>
                </ion-item>
            </ion-list>
            <QualificationSelectorModal
                v-model="isChangeQualificationModalActive"
                @select="onChangeQualification"
            />
        </ion-card-content>
    </ion-card>
    <Skeleton
        v-if="loader.pending.value"
        :items="['margin', 'title', 'block--height-9rem']"
    ></Skeleton>
    <ion-card v-if="!loader.pending.value" class="custom-ion-card-floating">
        <!-- <ion-card class="custom-ion-card-dark"> -->
        <ion-card-header>
            <ion-card-title>Schichtauswahl</ion-card-title>
        </ion-card-header>
        <ion-card-content class="no-padding-top no-padding-bottom">
            <ion-list>
                <ion-item
                    v-for="(
                                registerBooking, index
                            ) in sortBookings(newRegisterBookings)"
                    :key="index"
                    detail
                    @click="()=>{onClickBooking(registerBooking)}"
                >
                    <BookingSummary
                        :booking="registerBooking"
                        :shift="reverseGuessShift(registerBooking)"
                    ></BookingSummary>
                </ion-item>
                <ion-item
                    detail
                    detailIcon="/icons/caret-right.svg"
                    v-on:click="isAddShiftModalActive = true"
                    lines="none"
                >
                    <ion-label>
                        <h3>Hinzufügen</h3>
                    </ion-label>
                </ion-item>
                <EditRegisterBookingModal
                    v-model="isEditBookingModalActive"
                    :booking="editBooking"
                    v-on:remove:booking="onRemoveBooking"
                    v-on:start:updateShiftTimes="onStartUpdateShiftTimes"
                />
                <EditRegisterBookingTimesModal
                    v-model="isEditBookingTimesModalActive"
                    :booking="editBooking"
                    v-on:remove:booking="onRemoveBooking"
                    v-on:update:bookingTimes="onUpdateBookingTimes"
                />
                <AddShiftModal
                    v-model="isAddShiftModalActive"
                    v-on:add:shifts="onAddShifts"
                />
            </ion-list>
        </ion-card-content>
    </ion-card>
    <Skeleton
        v-if="loader.pending.value"
        :items="['button']"
    />
    <ion-button
        expand="block"
        :disabled="newRegisterBookings.length == 0"
        color="primary"
        v-if="!loader.pending.value"
        @click="isSubmitModalActive = true"
    >
        Einsatz eintragen
    </ion-button>

    <Modal v-model="isSubmitModalActive" title="Einsätze eintragen">
        Jeder eingetragene Einsatz muss Entleiherseitig gegenbestätigt
        werden.<br /><br />
        <ion-button expand="block" color="primary" @click="onSubmit"
        >Wirklich eintragen</ion-button
        >
    </Modal>
</template>

<script setup lang="ts">
import {
    Jobsite,
    Qualification,
    ShiftType
} from "@/graphql/generated/graphql";
import {
    IonHeader,
    IonToolbar,
    IonLabel,
    IonIcon,
    IonTitle,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonPage,
    IonButton,
    IonItem,
    IonList,
    onIonViewWillEnter, alertController,
} from "@ionic/vue";
import Skeleton from "@/components/Skeleton.vue";
import { DataLoader } from "@/helper/dataLoader";
import {getJobsiteShortestCompleteDescription} from "@/helper/jobsite";
import {ref, computed, PropType} from "vue";
import {kapitelDateString, kapitelTimeString} from "@/graphql/kapitelTypes";
import {
    fetchRegisterBookingDefaults,
    shiftDate,
    setRegisterBookings
} from "@/helper/registerBooking";
import { fetchJobsiteShiftTimes } from "@/helper/jobsite";
import { errorCatcher } from "@/helper/error";
import JobsiteSelectorModal from "@/views/components/JobsiteSelectorModal.vue"
import QualificationSelectorModal from "@/components/QualificationSelectorModal.vue"
import AddShiftModal from "./AddShiftModal.vue";
import EditRegisterBookingModal from "./EditRegisterBookingModal.vue"
import EditRegisterBookingTimesModal from "./EditRegisterBookingTimesModal.vue"
import Modal from "@/components/Modal.vue";
import datetime from "@/helper/datetime/datetime";
import { getEmployeeJobsiteCategorySegments } from "@/helper/jobsite";
import BookingSummary from "@/components/BookingSummary.vue";
import { useRouter } from "vue-router";
import { convertDateTime2Date } from '@/helper/datetime/datetime';
import {pluralize} from "@/helper/amountFormatter";
import {MonthMetaData} from "@/views/Calendar/types";

const props = defineProps({
    initData: {
        jobsite: {
            type: Object as PropType<Jobsite>,
            required: false,
            default: null
        },
        shiftDates: {
            type: Array<shiftDate>,
            required: false,
            default: null
        }
    }
})

const jobsite = ref<Jobsite | undefined>(undefined);
const alreadySelectedJobsites = computed(():Jobsite[] | undefined => jobsite.value ? [jobsite.value]: [])

const qualification = ref<Qualification | undefined>(undefined);

interface shiftTimes {
    [key: string]: {
        begin: kapitelTimeString;
        until: kapitelTimeString;
    };
}

const jobsiteSpecificShiftTimes = ref<shiftTimes>({});

interface times2shiftLookupInterface {
    [key: string]: ShiftType;
}
const times2shiftLookup: times2shiftLookupInterface = {}

const newRegisterBookings = ref<BookingInput[]>([]);

// Navigation Flow
const router = useRouter();
const isChangeJobsiteModalActive = ref(false);
const isChangeQualificationModalActive = ref(false);
const isEditBookingModalActive = ref(false);
const isEditBookingTimesModalActive = ref(false);
const editBooking = ref<BookingInput|undefined>(undefined)
const isAddShiftModalActive = ref(false);
const isSubmitModalActive = ref(false);

const generateBooking = (shift: shiftDate): BookingInput => {
    if (!jobsiteSpecificShiftTimes.value[shift.type]) {
        throw new Error("jobsiteSpecificShiftTimes undefined.");
    }

    if (!jobsite.value) {
        throw new Error("jobsite undefined.");
    }

    if (!qualification.value) {
        throw new Error("qualification undefined.");
    }

    times2shiftLookup[
        jobsiteSpecificShiftTimes.value[shift.type].begin+"-"+jobsiteSpecificShiftTimes.value[shift.type].until
        ] = shift.type

    const begin = datetime.convertDateAndTime2DateTime(
        shift.date,
        jobsiteSpecificShiftTimes.value[shift.type].begin,
    );

    let until = datetime.convertDateAndTime2DateTime(
        shift.date,
        jobsiteSpecificShiftTimes.value[shift.type].until,
    );

    if (datetime.isAfter(begin, until)) {
        until = datetime.addDays(until, 1);
    }

    return {
        begin: begin,
        until: until,
        jobsite: {
            id: jobsite.value.id
        },
        qualification: {
            id: qualification.value.id
        },
    };
};

const reverseGuessShift = (booking: BookingInput) => {
    const beginTime = datetime.convertDateTime2Time(booking.begin)
    const untilTime = datetime.convertDateTime2Time(booking.until)
    return times2shiftLookup[beginTime+"-"+untilTime] ?? ''
}

const sortBookings = (bookings: BookingInput[]) => {
    return bookings.sort((b1, b2) => {
        return datetime.cmpKapitelDateTime(b1.begin, b2.begin)
    })
}

const onChangeJobsite = async (jobsite_: Jobsite) => {
    jobsite.value = jobsite_
    const response = await fetchJobsiteShiftTimes(jobsite_)
    jobsiteSpecificShiftTimes.value = {};
    response.forEach((st) => {
        jobsiteSpecificShiftTimes.value[st.type] = {
            begin: st.begin,
            until: st.until,
        };
    });

}

const onChangeQualification = async (qualification_: Qualification) => {
    qualification.value = qualification_
}


const onAddShifts = (newShifts: shiftDate[]) => {
    const existingDates = newRegisterBookings.value.map((b) => convertDateTime2Date(b.begin));

    newShifts.forEach((s: shiftDate) => {
        const index = existingDates.indexOf(s.date);

        if (index > -1) { // update
            newRegisterBookings.value[index] = generateBooking(s);
        } else { // add
            newRegisterBookings.value.push(generateBooking(s))
        }
    });

    isAddShiftModalActive.value = false;
};

const onClickBooking = (booking: BookingInput) => {
    isEditBookingModalActive.value = true
    editBooking.value = booking
}

const onUpdateBookingTimes = (newBooking: BookingInput, oldBooking: BookingInput) =>  {
    const index = newRegisterBookings.value.indexOf(oldBooking)
    if(index>=0){
        newRegisterBookings.value.splice(index, 1)
    }

    newRegisterBookings.value.push(newBooking)
}

const onSubmit = async () => {
    const {successfulBookings, errors} = await setRegisterBookings(newRegisterBookings.value)

    isSubmitModalActive.value = false;

    const alert = (errors.length > 0)
        ? await alertController.create({
                header: 'Fehler',
                // subHeader: 'A Sub Header Is Optional',
                message: 'Nicht alle Dienste konnten erfolgreich eingetragen werden (' + errors.join('; ') + ')',
                buttons: [
                    {
                        text: 'Zurück',
                    }
                ],
            })
        : await alertController.create({
            header: 'Dienst eintragen erfolgreich',
            // subHeader: 'A Sub Header Is Optional',
            message: pluralize(successfulBookings.length, 'Dienst', 'Dienste')  + ' erfolgreich eingetragen',
            buttons: [{
                text: 'Ok',
                handler: () => {
                    newRegisterBookings.value = [];
                    router.push("/")
                }
            }],
        })

    await alert.present()
};

const onRemoveBooking = (booking: BookingInput) => {
    const index = newRegisterBookings.value.indexOf(booking)
    if(index>=0){
        newRegisterBookings.value.splice(index, 1)
    }
}

const onStartUpdateShiftTimes = () => {
    isEditBookingTimesModalActive.value = true;
}

const employeeJobsiteSegments : Ref<JobsiteCategorySegment[]> = ref([])
getEmployeeJobsiteCategorySegments()
    .then(segments => employeeJobsiteSegments.value = segments)


/**
 * Init
 */

const fetchAndParseRegisterBookingDefaults = async () => {
    const response = await fetchRegisterBookingDefaults();

    if (!jobsite.value) {
        jobsite.value = response.jobsite;
    }

    if (!qualification.value) {
        qualification.value = response.qualification;
    }

    response.shiftTimes.forEach((st) => {
        jobsiteSpecificShiftTimes.value[st.type] = {
            begin: st.begin,
            until: st.until,
        };
    });
};

const loader = new DataLoader(async () => {
    await fetchAndParseRegisterBookingDefaults()

    if (props.jobsite) {
        jobsite.value = props.jobsite
    }
    
    if (props.shiftDates) {
        onAddShifts(props.shiftDates)
    }
});
loader.load();


</script>

<style lang="scss"></style>
