<script setup lang="ts">

import {DataLoader} from "@/helper/dataLoader";
import { ref, Ref } from 'vue';
import { errorCatcher } from "@/helper/error"
import Skeleton from "@/components/Skeleton.vue";
import { IonCardTitle, IonCard, IonCardHeader, IonRange, IonCardContent } from '@ionic/vue'
import { fetchEmployeeTargetAZK, updateEmployeeTargetAZK } from "@/helper/azkTarget"
import Range from '@/components/Range.vue'

const azkTarget:Ref<number|undefined> = ref(undefined)


const onChangeAZKTarget = ()=>{
    if(azkTarget.value){
        updateEmployeeTargetAZK(
            azkTarget.value
        )
    }
}

const fetchAndParseTargetAZK = async () => {
    azkTarget.value = await fetchEmployeeTargetAZK()
}

const loader = new DataLoader(async () => {
    await fetchAndParseTargetAZK()
        .catch(errorCatcher)
})

loader.load()

</script>

<template>
<Skeleton v-if="loader.pending.value" :items="['margin', 'title', 'margin', 'block--height-12rem']"></Skeleton>
<ion-card v-if='!loader.pending.value' class="custom-ion-card-light">
    <ion-card-header>
        <ion-card-title>Zielwert Arbeitszeitkonto (AZK)</ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <span>
            Wir schauen bei der Dienstplanung auf deine Vertragsstunden. Zusätzlich geben wir dir die Möglichkeit, ein Arbeitszeitkonto anzusammeln.
            Dafür kannst du hier einstellen, wie viele Dienste du zusätzlich als Puffer auf deinem Konto anstrebst.
            <br><br><br>
        </span>

        <Range
            :min="0"
            :max="20"
            v-model="azkTarget"
            :pin="true"
            :pin-formatter="(v) => v + ' Dienste'"
            :ticks="false"
            :snaps="true"
            :step="1"
            v-on:ion-change="onChangeAZKTarget"
        />
    </ion-card-content>
</ion-card>

</template>

<style lang="scss" scoped>
</style>
