<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-button
                color="secondary"
                fill="clear"
                v-on:click="router.go(-1)"
                size="small"
            >
                <ion-icon slot="icon-only" src="/icons/caret-left.svg" />
            </ion-button>
        </ion-header>
        <Calendar ref="innerComponentRef" />
    </ion-page>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import {IonButton, IonContent, IonHeader, IonTitle, IonIcon, IonPage, onIonViewDidEnter, onIonViewWillLeave} from '@ionic/vue';
import {useRoute, useRouter} from 'vue-router';
import Calendar from './Calendar.vue';

const innerComponentRef = ref(null);
const route = useRoute();
const router = useRouter()

onIonViewDidEnter(() => {
    if (innerComponentRef.value && innerComponentRef.value.onEnter) {
        innerComponentRef.value.onEnter();
    }
});

onIonViewWillLeave(() => {
    if (innerComponentRef.value && innerComponentRef.value.onLeave) {
        innerComponentRef.value.onLeave();
    }
});

watch(
    () => route.query,
    () => {
        if (innerComponentRef.value && innerComponentRef.value.onRouteQueryChanged) {
            innerComponentRef.value.onRouteQueryChanged();
        }
    }
);
</script>
