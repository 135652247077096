<template>
    <ion-page class="planning-month">
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-button
                    color="secondary"
                    fill="clear"
                    @click="router.go(-1)"
                    size="small"
                >
                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />
                </ion-button>
                <ion-title>
                    {{ calendarMonth.format() }}
                </ion-title>
            </ion-toolbar>
        </ion-header>

        <PlanningMonth
            ref="innerComponentRef"
            :monthId="monthId"
        />
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonButton,
    IonIcon,
    IonPage,
    IonToolbar,
    IonHeader,
    IonTitle,
    onIonViewWillEnter
} from '@ionic/vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { generateCalendarMonth } from "@/helper/calendar/calendarMonth";
import PlanningMonth from "@/views/Planning/PlanningMonth.vue";
import { kapitelDateString } from '@/graphql/kapitelTypes';

const props = defineProps<{
    monthId: kapitelDateString
}>();

const router = useRouter();
const calendarMonth = generateCalendarMonth(props.monthId);
const innerComponentRef = ref(null);

onIonViewWillEnter(() => {
    if (innerComponentRef.value && innerComponentRef.value.reload) {
        innerComponentRef.value.reload();
    }
});
</script>

<style lang="scss">
.planning-month {
    /* Any styles specific to the outer component */
}
</style>
