<template>
    <ion-page class="jobsite-manager">
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-button
                    color="secondary"
                    fill="clear"
                    v-on:click="router.go(-1)"
                    size="small"
                >
                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />
                </ion-button>
                <ion-title>Einsatzorte</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content
            :class="{[selectedSegment]: true}"
        >
            <div
                class="content-fixed"
                slot="fixed"

            >
                <ion-segment
                    v-model="selectedSegment"
                >
                    <ion-segment-button value="fav">
                    <InlineSVG
                        src="/icons/heart.svg"
                    />
                    </ion-segment-button>
                    <ion-segment-button value="alt">
                    <InlineSVG
                        src="/icons/plus.svg"
                    />
                    </ion-segment-button>
                    <ion-segment-button value="blocks">
                    <InlineSVG
                        src="/icons/x.svg"
                    />
                    </ion-segment-button>
                </ion-segment>                
                <h2>{{headerLabel}}</h2>
            </div>
            
            <ion-list>

                    <ion-item
                        v-for="item in jobsitesToRender"
                        @click="onClickJobsite(item)"
                        :key="item.id"
                    >
                        <ion-label>
                            <h3>{{ item.name }}</h3>
                            <p>{{ item.client?.abbreviation }}<span v-if="item.house">, {{ item.house?.abbreviation }}</span><span v-if="item.additionalAmenImportantAbbreviations?.length>0">, {{item.additionalAmenImportantAbbreviations.join(", ")}}</span></p>
                        </ion-label>
                        <ion-reorder slot="end" />
                    </ion-item>
            </ion-list>
            <br>
            <ion-button color="secondary" expand="block" v-on:click="jobsiteAddModalIsOpen = true">Station hinzufügen</ion-button>

            <ion-action-sheet
                :is-open="isActionModalOpen"
                @didDismiss="onActionModalDismiss"
                :buttons="actionSheetButtons"
            />

            <JobsiteSelectorModal
                v-model="jobsiteAddModalIsOpen"
                @select="handleSelectJobsite"
            />
        </ion-content>
    </ion-page>
</template>

<script setup>
import { IonContent, IonIcon, IonPage, IonToolbar, IonHeader, IonTitle, IonButton, IonSegment, IonSegmentButton, IonLabel, IonList, IonListHeader, IonItem, IonSearchbar, IonActionSheet, modalController, IonReorder, IonReorderGroup } from '@ionic/vue'
import { computed } from 'vue'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router';
import Modal from '@/components/Modal.vue'
import JobsiteSelectorModal from "@/views/components/JobsiteSelectorModal.vue";
import {
    fetchFavoriteJobsites,
    fetchSecondChoiceJobsites,
    fetchBlacklistedJobsites,
    addFavoriteJobsite,
    removeFavoriteJobsite,
    addSecondChoiceJobsite,
    removeSecondChoiceJobsite,
    addBlacklistJobsite,
    removeBlacklistJobsite,
    updateSecondChoiceJobsites
} from "@/helper/jobsite"
import InlineSVG from '@/components/InlineSVG.vue'

/**
 * Refs
 */

const router = useRouter()
const favJobsites = ref([])
const altJobsites = ref([])
const blockedJobsites = ref([])

const jobsiteAddModalIsOpen = ref(false)
const isActionModalOpen = ref(false)
const isSortingActive = ref(false)
const selectedJobsite = ref(undefined)
const selectedSegment = ref('fav')

/**
 * Computed
 */

const headerLabel = computed(() => {
    switch (selectedSegment.value) {
        case 'fav': return  "Lieblingsstationen";
        case 'alt': return  "Alternativen";
        case 'blocks': return  "Blockiert";
    }
    return '';
})

const actionSheetButtons = computed(() => {
    const buttons = []
    const jobsite = selectedJobsite.value

    if (!jobsite) {
        return buttons
    }

    buttons.push({
            text: 'Details anzeigen',
            data: 'showDetails',
        })

    if (isJobsiteFavorite(jobsite)) {
        buttons.push({
            text: 'Entfernen',
            data: 'removeFav',
        })
    }
    else {
        buttons.push({
            text: 'Als Lieblingsstation',
            data: 'addFav'
        })
    }

    if (isJobsiteSecondChoice(jobsite)) {
        buttons.push({
            text: 'Entfernen',
            data: 'removeAlt',
        })
    }
    else {
        buttons.push({
            text: 'Als Alternativstation',
            data: 'addAlt'
        })
    }

    if (isJobsiteBlocked(jobsite)) {
        buttons.push({
            text: 'Nicht mehr blockieren',
            data: 'removeBlocked',
        })
    }
    else {
        buttons.push({
            text: 'Blockieren',
            role: 'destructive',
            data: 'addBlocked'
        })
    }

    buttons.push({
        text: 'Abbrechen',
        role: 'cancel',
        data: {
            action: 'cancel',
        },
    })

    return buttons
})

const jobsitesToRender = computed(() => {
    switch(selectedSegment.value) {
        case 'fav':
            return favJobsites.value
        case 'alt':
            return altJobsites.value
        case 'blocks':
            return blockedJobsites.value
        default:
            throw new Error('invalid segment.')
    }
})

/**
 * API
 */


const fetchFavs = () => {
    return fetchFavoriteJobsites().then(res => {
        favJobsites.value = res
    })
}

const fetchAlternatives = () => {
    return fetchSecondChoiceJobsites().then(res => {
        altJobsites.value = res
    })
}

const fetchBlocked = () => {
    return fetchBlacklistedJobsites().then(res => {
        blockedJobsites.value = res
    })
}


const addFav = async jobsite => {

    const requestQueue = [];

    altJobsites.value.some(llJobsite => llJobsite.id === jobsite.id)? requestQueue.push(removeAlt): null
    blockedJobsites.value.some(llJobsite => llJobsite.id === jobsite.id)? requestQueue.push(removeBlock): null
    requestQueue.push(addFavoriteJobsite)

    let result;
    for (const request of requestQueue) {
        result = await request(jobsite)
    }

    favJobsites.value = result
}

const addAlt =  async jobsite => {

    const requestQueue = [];

    favJobsites.value.some(llJobsite => llJobsite.id === jobsite.id)? requestQueue.push(removeFav): null
    blockedJobsites.value.some(llJobsite => llJobsite.id === jobsite.id)? requestQueue.push(removeBlock): null
    requestQueue.push(addSecondChoiceJobsite)

    let result;
    for (const request of requestQueue) {
        result = await request(jobsite)
    }

    altJobsites.value = result

}

const addBlock = async jobsite => {

    const requestQueue = [];

    favJobsites.value.some(llJobsite => llJobsite.id === jobsite.id)? requestQueue.push(removeFav): null
    altJobsites.value.some(llJobsite => llJobsite.id === jobsite.id)? requestQueue.push(removeAlt): null
    requestQueue.push(addBlacklistJobsite)

    let result;
    for (const request of requestQueue) {
        result = await request(jobsite)
    }

    blockedJobsites.value = result

}

const removeFav = jobsite => {
    return removeFavoriteJobsite(jobsite).then(res => {
        favJobsites.value = res
    })
}

const removeAlt = jobsite => {
    return removeSecondChoiceJobsite(jobsite).then(res => {
        altJobsites.value = res
    })
}

const removeBlock = jobsite => {
    return removeBlacklistJobsite(jobsite).then(res => {
        blockedJobsites.value = res
    })
}

const updateAlternativeSorting = () => {
    const ids = altJobsites.value.map(jobsite => jobsite.id)

    return updateSecondChoiceJobsites(ids).then(res => {
        altJobsites.value = res
    })
}

/**
 * Events
 */

const onClickJobsite = jobsite => {
    selectedJobsite.value = jobsite
    isActionModalOpen.value = true
}

const onActionModalDismiss = async (event) => {
    const jobsite = selectedJobsite.value
    const action = event.detail.data

    isActionModalOpen.value = false
    selectedJobsite.value = undefined

    switch(action) {
        case 'addFav':
            if (isJobsiteSecondChoice(jobsite)) {
                await removeAlt(jobsite)
            }
            if (isJobsiteBlocked(jobsite)) {
                await removeBlock(jobsite)
            }
            addFav(jobsite)
            break;

        case 'removeFav':
            removeFav(jobsite)
            break;

        case 'addAlt':
            if (isJobsiteFavorite(jobsite)) {
                await removeFav(jobsite)
            }
            if (isJobsiteBlocked(jobsite)) {
                await removeBlock(jobsite)
            }
            addAlt(jobsite)
            break;

        case 'removeAlt':
            removeAlt(jobsite)
            break;

        case 'addBlocked':
            if (isJobsiteFavorite(jobsite)) {
                await removeFav(jobsite)
            }
            if (isJobsiteSecondChoice(jobsite)) {
                await removeAlt(jobsite)
            }
            addBlock(jobsite)
            break;

        case 'removeBlocked':
            removeBlock(jobsite)
            break;

        case 'showDetails':
            router.push('/jobsite-details/' + jobsite.id)
            break;
    }
}

const handleSelectJobsite = (jobsite)=>{
    switch(selectedSegment.value) {
        case 'fav':
            return addFav(jobsite)
        case 'alt':
            return addAlt(jobsite)
        case 'blocks':
            return addBlock(jobsite)
        default:
            throw new Error('invalid segment.')
    }
}

// const onReorder = event => {
//     altJobsites.value = event.detail.complete(altJobsites.value)
// }


fetchFavs()
fetchAlternatives()
fetchBlocked()

watch(selectedSegment, () => {
    isSortingActive.value = false
})

watch(isSortingActive, value => {
    if (value) {
        return
    }

    updateAlternativeSorting()
})

/**
 * Helper
 */

const isJobsiteFavorite = jobsite => favJobsites.value.find(j => j.id === jobsite.id) !== undefined
const isJobsiteSecondChoice = jobsite => altJobsites.value.find(j => j.id === jobsite.id) !== undefined
const isJobsiteBlocked = jobsite => blockedJobsites.value.find(j => j.id === jobsite.id) !== undefined
</script>

<style lang="scss">
.jobsite-manager {
    ion-content {
        --padding-top: 8rem;

        /* &.all, */
        /* &.alt  */
        /* {
            --padding-top: 10rem;

            ion-segment {
                margin-bottom: 1rem;
            }
        } */

        .content-fixed {
            background-color: var(--ion-background-color);
            padding-bottom: 1rem;
        }
    }
}


.content-fixed {
    width:100%;

    > h2 {
        color: var(--ion-color-black);
    }

    > ion-segment {

        display: flex;
        width:100%;
        align-items: center;

        > ion-segment-button {
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            line-height: 0;

            > .inline-svg {
                width: 1.5rem;
                color: var(--ion-color-primary);
                filter: drop-shadow( 0 0 0.4rem rgba(var(--ion-color-primary-rgb), 0.5));

                &:not(:last-child) {
                    margin-right: 0.25em;
                }
            }
        }
    }

}

.jobsite-manager-action-modal {
    --height: auto;
}
</style>
