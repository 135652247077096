<template>
    <ion-page class="no-padding-top">
        <ion-header :translucent="true">
            <ion-toolbar class="no-border">
<!--                <ion-button-->
<!--                    color="secondary"-->
<!--                    fill="clear"-->
<!--                    v-on:click="router.go(-1)"-->
<!--                    size="small"-->
<!--                >-->
<!--                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />-->
<!--                </ion-button>-->
<!--                <ion-title>Assistent</ion-title>-->
                <ion-buttons slot="end">
                    <ion-button
                        color="secondary"
                        fill="clear"
                        v-on:click="aInesRequestStore.toggleUIState"
                        size="small"
                        class="image-icon has-badge"
                    >
                        <img slot="icon-only" src="/logo.png" />
                        <ion-badge v-if="aInesRequestStore.requests.length">{{ aInesRequestStore.requests.length }}</ion-badge>
                    </ion-button>
                    <ion-button
                        color="secondary"
                        fill="clear"
                        v-on:click="router.push('/calendar')"
                        size="small"
                    >
                        <ion-icon slot="icon-only" src="/icons/calendar-blank.svg" />
                    </ion-button>
                </ion-buttons>

            </ion-toolbar>
        </ion-header>
        <ion-content>
            <AInesRequestAssistant
                v-on:avatar:click="aInesRequestStore.toggleUIState"
                v-on:request:click="aInesRequestStore.selectRequest"
            ></AInesRequestAssistant>
            <div class="chat-container" v-if="aInesRequestStore.uiState === AInesRequestAssistantUIState.MINIMIZED">
                <div class="response-container">
                    <Transitionator :types="['opacity', 'margintop']" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="150">
                        <div
                            v-if="RS_show.messageText || RS_show.messageLoaderMessage"
                            class="message with-avatar"
                        >
                            <p v-if="RS_show.messageLoaderMessage">{{RS_loaderMessage }}</p>
                            <p v-if="RS_show.messageText">{{ RS_assistantMessage?.textContent }}</p>
                        </div>
                    </Transitionator>
                    <Transitionator :types="['opacity', 'margintop']" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="10">
                        <div
                            v-if="RS_show.messageLoaderDots || RS_show.richResponseLoaderDots"
                            class="message pending"
                        >
                            <IonSpinner name="dots" />
                        </div>
                    </Transitionator>
                    <div
                        v-if="RS_show.messageRichResponses && RS_assistantMessage?.richContents"
                        v-for="richContent in RS_assistantMessage.richContents"
                        class='rich-response-container'
                        v-on:click="()=>{onClickRichResponse(richContent)}"
                    >
                        <Transitionator v-if="!!getRichComponentFor(richContent)" :types="['opacity', 'scale']" :module-options="{scale: {base: 0.8}}" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="20">
                            <component
                                :is="getRichComponentFor(richContent)"
                                :init-data="richContent.initData"
                                v-on:details="onRichResponseDetails"
                            />
<!--                                @blockprompt="onRichComponentBlockPrompt"-->
<!--                                @unblockprompt="onRichComponentUnblockPrompt"-->
<!--                                @sendmessage="onRichComponentSendMessage"-->

                        </Transitionator>
                    </div>
                </div>
            </div>

            <Modal v-model="detailModalOpen" title="Detail" style="height:auto">
                <JobsiteDetails
                    v-if="currentDetailModalType == 'Jobsite'"
                    :jobsite-id="currentDetailModalInitData"
                ></JobsiteDetails>
                <AvailabilityAbsenceEditor
                    v-if="currentDetailModalType == 'AvailabilityAbsenceEditor'"
                    :availability="currentDetailModalInitData.availability"
                    :absence="currentDetailModalInitData.absence"
                    :date="currentDetailModalInitData.date"
                ></AvailabilityAbsenceEditor>
                <CalendarDetail
                    v-if="currentDetailModalType == 'CalendarDetail'"
                    :date-id="currentDetailModalInitData.date"
                ></CalendarDetail>
                <Calendar
                    v-if="currentDetailModalType?.type == AInesRichResponseType.MonthCalendar"
                    :date="currentDetailModalInitData.date"
                ></Calendar>
                <PlanningMonth
                    v-if="currentDetailModalType?.type == 'PlanningMonth'"
                    :monthId="currentDetailModalInitData.month"
                ></PlanningMonth>
            </Modal>
            <Modal
                v-model="processModalOpen"
               :initial-breakpoint="1"
               :breakpoints="[0, 1]"
                style="height:auto"
               title="Bestätigung">
                <RegisterBookingTopSheet
                    v-if="currentModalSelection?.type == AInesRichResponseType.RegisterBookingSummary"
                    :init-data="currentModalSelection.initData"
                ></RegisterBookingTopSheet>
                <JobsiteSearchTopSheet
                    v-if="currentModalSelection?.type == AInesRichResponseType.JobsiteSearch"
                    :init-data="currentModalSelection.initData"
                    v-on:send-user-message="sendUserMessage"
                    v-on:close="processModalOpen = false"
                ></JobsiteSearchTopSheet>

            </Modal>
        </ion-content>
        <ion-footer class="footer-input">
            <ion-button
                v-if="isChatInputBlocked"
                color="secondary"
                expand="block"
                @click="onClickRichComponentButtonConfirm"
            >
                {{ blockButtonLabel }}
            </ion-button>

            <ChatInput
                v-if="!isChatInputBlocked"
                v-on:text:submit="(message) => sendUserMessage(message, undefined)"
                v-on:text:input="onChatInputTextInput"
            />

            <!-- only show explore as long as no message was send -->
            <div class="quick-access-buttons" v-if="RS_show.exploreQuickChats">
                <div
                    class="floating-big-pseudo"
                    v-for="exploreQuickChat in exploreQuickChats"
                    :key="exploreQuickChat.label"
                    @click="clickExploreQuickChat(exploreQuickChat)"
                    >
                        {{ exploreQuickChat.label }}
                </div>
            </div>
            <div class="quick-access-buttons" v-else-if="!isChatInputBlocked && aInesRequestStore.selection">
                <div
                    v-for="responseQuickChat in requestResponseQuickChats"
                    :key="responseQuickChat.label"
                    @click="clickRequestResponseQuickChat(aInesRequestStore.selection, responseQuickChat)"
                    >
                        {{ responseQuickChat.label }}
                </div>
            </div>
        </ion-footer>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonPage,
    IonSpinner,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import ChatInput from '@/views/Chat/ChatInput.vue'
import {computed, ComputedRef, Ref, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {
    ExploreQuickChat,
    getExploreQuickChatsMatching,
    getExploreQuickChatsTopN,
    useExploreQuickChatStore
} from "@/helper/chat/exploreQuickChats";
import {useChatStore} from "@/helper/chat/chatStore";
import {
    prependAssistantMessage,
    sendUserMessage,
    warmUpAssistant
} from "@/helper/chat/chatBL";
import JobsiteDetails from "@/views/JobsiteDetails.vue";
import AInesRequestAssistant from "@/views/Chat/AInesRequestAssistant.vue";
import {AInesRequestAssistantUIState, snoozeAInesRequest, useAInesRequestStore} from "@/helper/chat/ainesRequests";
import {AInesRequest, AInesRequestAction, AInesRichResponseType} from "@/graphql/generated/graphql";
import {storeToRefs} from "pinia";
import {convertRichResponseToRichContent, getRichComponentFor, RichContent} from "@/helper/chat/richResponses";
import Transitionator from "../components/transitions/Transitionator.vue";
import {isWriting} from "@/helper/chat/toolCalls";
import {ChatStatus} from "@/helper/chat/chatStatus";
import {requireAssistentForFeatureSet} from "@/helper/chat/assistantFeatureMapping";
import {consoleErrorChat} from "@/helper/console";
import Modal from "@/components/Modal.vue";
import RegisterBookingTopSheet from "@/views/components/RegisterBookingTopSheet.vue";
import JobsiteSearchTopSheet from "@/views/components/JobsiteSearchTopSheet.vue";
import AvailabilityAbsenceEditor from "@/components/AvailabilityAbsenceEditor.vue";
import BookingDetails from "@/views/CalendarDetail/components/BookingDetails.vue";
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import JobsiteSelectorModalInner from "@/views/components/JobsiteSelectorModalInner.vue";
import Calendar from "@/views/Calendar/Calendar.vue";
import PlanningMonth from "@/views/Planning/PlanningMonth.vue";



/*
init
 */

const router = useRouter();
const chatStore = useChatStore();

const aInesRequestStore = useAInesRequestStore();
const isChatInputBlocked = ref(false)
const blockButtonLabel = ref()
const chatInputText = ref('')

// trigger warm up
warmUpAssistant()



/*
TopSheet Handling
 */

const processes: AInesRichResponseType[] = [
    AInesRichResponseType.RegisterBookingSummary,
    AInesRichResponseType.AutopilotStartWizard,
    AInesRichResponseType.JobsiteSearch
]

const currentModalSelection : Ref<RichContent|undefined> = ref(undefined);
const processModalOpen = ref(false)
const onClickRichResponse = (content: RichContent)=>{
    if(processes.indexOf(content.type as AInesRichResponseType)>-1){
        currentModalSelection.value = content
        processModalOpen.value = true
    }
}
// --
const detailModalOpen = ref(false)
const currentDetailModalType : Ref<string|undefined> = ref(undefined);
const currentDetailModalInitData : Ref<object> = ref({});

const onRichResponseDetails = (data: object|string)  => {
    currentDetailModalType.value = data.type
    currentDetailModalInitData.value = data.initData
    detailModalOpen.value = true
}
///


interface CurrentSystemMessage {
    textContent: string,
    richContents: RichContent[]|undefined
}
const aInesRequestStoreRefs = storeToRefs(aInesRequestStore)



/*
rendersets
 */

const x = storeToRefs(chatStore)

const RS_show = computed(() => {
    const s = chatStore.chatStatus

    const show = {
        // default: no loader shown
        messageLoaderDots: false,
        messageLoaderMessage: false,
        richResponseLoaderDots: false,

        // default: no message content shown
        messageText: false,
        messageRichResponses: false,

        exploreQuickChats: !isChatInputBlocked.value && chatStore.userMessageHistoryEmpty && ChatStatus.isResting(s) && !aInesRequestStore.selection
    }

    switch (true) {
        case (s === ChatStatus.READY):
        case (s === ChatStatus.FAILED):
            // show nothing
            break
        case (s === ChatStatus.EXPECTING_NEXT_RESPONSE):
        case (ChatStatus.isChoosing(s)):
        case (ChatStatus.isExperting(s) && s !== ChatStatus.EXPERT_RESPONDING):
            // run in preparation, choosing, or pending -> loader dots
            show.messageLoaderDots = true;
            break;
        case (s === ChatStatus.EXPERT_RESPONDING):
            // expert run pending - differentiate in reverse order: waiting, toolcalls, text streaming, tool call streaming, done
            const recentToolCall = chatStore.currentExpertRunMostRecentToolCall
            const recentMessageStatus = chatStore.currentExpertRunMessageStatus

            if (recentMessageStatus === 'messageDone') { // done
                show.messageText = true
                show.messageRichResponses = true
            } else if (recentMessageStatus === 'textDone') { // tool call streaming
                show.messageText = true
                show.richResponseLoaderDots = true // text is done streaming, but message not - we may be waiting for RRs
            } else if (recentMessageStatus === 'streaming') { // text streaming
                show.messageText = true
            } else if (recentToolCall) { // tool calls
                show.messageLoaderMessage = true
            } else { // waiting
                show.messageLoaderDots = true
            }
            break;
        case (s === ChatStatus.EXPERT_COMPLETE):
            show.messageText = true
            show.messageRichResponses = true
            break;
        default:
            consoleErrorChat('unhandled Chat.vue status for %o', s)
            break;
    }

    return show
})

const RS_assistantMessage: Ref<CurrentSystemMessage | undefined> = computed(() => {
    const requestMessage = aInesRequestStoreRefs.selection.value?.message
    if (requestMessage) {
        let richContents = undefined;
        if (requestMessage.richResponses?.responses) {
            richContents = requestMessage.richResponses.responses.map((richResponse) =>
                convertRichResponseToRichContent({
                    type: richResponse.type as string,
                    reference: richResponse.reference as string
                })
            )
        }

        return {
            textContent: requestMessage.text || '',
            richContents: richContents
        }
    }

    if (chatStore.currentExpertRunMessageText) {
        return {
            textContent: chatStore.currentExpertRunMessageText,
            richContents: chatStore.currentExpertRunMessageRichContents
        }
    }

    return undefined
})

const RS_loaderMessage: Ref<string|undefined> = computed(() => {
    const toolCall = chatStore.currentExpertRun?.getMostRecentToolCall()

    if (toolCall) {
        const loadingMessages = isWriting(toolCall)
            ? [
                'Einen Moment Geduld bitte, ich bin dabei.',
                'Ich mach das für dich.'
            ]
            :[
                'Ich schaue kurz nach.',
                'Eine Sekunde, ich muss nachsehen.'
            ]

        return loadingMessages[Math.floor(Math.random()*loadingMessages.length)]
    }

    return undefined
})



/*
interaction handler
 */

const onChatInputTextInput = (text: string) => {
    chatInputText.value = text
}

// const onRichComponentBlockPrompt = (label: string) => {
//     isChatInputBlocked.value = true
//     blockButtonLabel.value = label
// }
//
// const onRichComponentUnblockPrompt = () => {
//     isChatInputBlocked.value = false
// }

const onClickRichComponentButtonConfirm = () => {
    // if (richComponentRef.value) {
    //     richComponentRef.value.confirm()
    // }
}

// const onRichComponentSendMessage = (message: string) => {
//     blockButtonLabel.value = ''
//     isChatInputBlocked.value = false
//     // TODO: rich response emits message, should choose assistant
//     sendUserMessage(message, undefined)
// }



/*
ExploreQuickActions
 */

const exploreQuickChats = computed(() => {
    const result = chatInputText.value.trim() !== ''
        // show top 3 matches for search string
        ? getExploreQuickChatsMatching(chatInputText.value.trim(), 3)
        // show top 3 quick explores
        : getExploreQuickChatsTopN(3)

    // always reverse, showing top result at the bottom
    return result.reverse()
})

const clickExploreQuickChat = async (qc:ExploreQuickChat) => {

    useExploreQuickChatStore().trackUsage(qc)

    const expertAssistant = await requireAssistentForFeatureSet(qc.featureSet)

    sendUserMessage(qc.prompt ?? qc.label, expertAssistant)
}



/*
AInesRequests & responseQuickActions
 */

// init AInesRequests
aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)
const chatStateReadyForOpenAssistant = () : boolean => {
    return (
        chatInputText.value === '' &&
        !chatStore.currentExpertRun &&
        !ChatStatus.isPending(chatStore.chatStatus)
    )
}
// trigger fetch for new requests
aInesRequestStore
    .refetch()
    .then(() => {
        // unfold into compact automatically - only if nothing happened yet
        if (aInesRequestStore.requests.length > 0 && chatStateReadyForOpenAssistant() && chatStore.userMessageHistoryEmpty) {
            aInesRequestStore.setUIState(AInesRequestAssistantUIState.COMPACT)
        }
    });

// when user starts typing or a system message arrives
watch(chatStateReadyForOpenAssistant, () => {
    if (!chatStateReadyForOpenAssistant()) {
        aInesRequestStore.minimizeIfOpen()
    }
})


interface ResponseQuickChat {
    label: string,
    type: 'suggestedReaction' | 'snoozeButtons',
    action?: AInesRequestAction
}
const requestResponseQuickChats : ComputedRef<ResponseQuickChat[]> = computed(() => {
    if (!aInesRequestStore.selection) {
        return []
    }

    const suggestedQuickChats : ResponseQuickChat[] = aInesRequestStore.selection.suggestions
        .map(action => ({label: action.shortLabel, type: 'suggestedReaction', action: action}))

    const snoozeQuickChat : ResponseQuickChat = {label: 'Jetzt nicht', type: 'snoozeButtons'}

    return suggestedQuickChats.concat([snoozeQuickChat])
})

const clickRequestResponseQuickChat = async (request: AInesRequest, response:ResponseQuickChat) => {
    if (response.type === 'suggestedReaction' && response.action) {
        if (response.action.prompt) {
            const expert = await requireAssistentForFeatureSet(request.featureSet)
            if (request.message.text) {
                await prependAssistantMessage(request.message.text, expert)
            }
            sendUserMessage(response.action.prompt, expert)
        }
        aInesRequestStore.respondToSelectedRequest(response.action.reactionType)
    } else if (response.type === 'snoozeButtons') {
        // show "snooze" action sheet
        snoozeAInesRequest()
    }
}
</script>

<style scoped lang="scss">
.chat-container{
    margin-bottom: 5em;
    width: 100%;
    position: relative;
}
.response-container {
    display: flex;
    flex-direction: column;
    color: black;
    align-items: flex-start;
}

.rich-response-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
}

.message {
    background-color: var(--ion-color-light);
    color: var(--ion-text-color);
    font-size: var(--custom-font-size-regular);
    line-height: 1.36em;
    margin: 0.5em 0 2em 0;
    padding: 1em;
    border-radius: 1rem;
    width: 100%;
    min-height: 3.36em;
    position: relative;
    transition-property: width;
    transition-duration: 0.25s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

    p {
        margin: 0;
        width: 100%;
    }

    ion-spinner {
        height: 1em;
        line-height: 1em;
    }

    &.with-avatar {
        &:after {
            content: '';
            position: absolute;
            top: -0.5em;
            left: -0.5em;
            width: 1.5em;
            height: 1.5em;
            background-color: var(--ion-color-primary);
            background-image: url('/icons/logo/icon-96.webp');
            background-size: cover;
            border-radius: 50%;
        }
    }

    &.pending {
        width: 4em;
        display: inline-block;

        &:not(:first-child) {
            margin-top: -1.5em;
        }
    }


}

.system-message {
    align-self: flex-start;
}

.user-message {
    align-self: flex-end;
}

.footer-input {
    width: 100%;
    background-color: var(--ion-color-white);
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
    padding-bottom: var(--custom-spacing-app-content-padding-vertical);
    padding-top: var(--custom-spacing-app-content-padding-vertical);

    display: flex;
    flex-direction: column;

    > ion-input {
        z-index: 1;
    }
}

.quick-access-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 0.5em;
    transform: translateY(-100%);

    > div {
        display: inline-block;
        font-size: var(--custom-font-size-small);
        color: var(--ion-color-black);
        padding: 0.9em 1.5em;
        border-radius: 1rem;
        background-color: var(--ion-color-white);

        &:not(:last-child) {
            margin-bottom: 0.5em;
        }
    }
}

</style>
