import { mutation, query } from "@/graphql/client";
import { AvailabilityPlanningDates, AvailabilityPlanningDatesInput, EmployeeTargetAzk } from "@/graphql/generated/graphql";
import { gql } from "@urql/vue";
import useEmitter from "@/helper/emitter";
import {errorCatcher} from "@/helper/error";
import { AVERAGE_SHIFT_DURATION } from "./globalShiftTimes";
import { useEmployeeStore } from "@/store/employee";

const emitter = useEmitter()

const fragment = gql`fragment Fragment on EmployeeTargetAZK {
    value,
}`

const bookings2Hours = (input:number):number=> {
    return (input) * AVERAGE_SHIFT_DURATION
}

const hours2Bookings = (input:number):number=> {
    return Math.ceil((input) / AVERAGE_SHIFT_DURATION)
}

export async function fetchEmployeeTargetAZK(): Promise<number> {
    const result = await query(gql`
        ${fragment}
        query GetEmployeeTargetAZK{
              targetAZK {
                  ...Fragment
              }
        }`
    )
    return hours2Bookings(result?.data?.targetAZK?.value)
}

export async function updateEmployeeTargetAZK(newValue: number): Promise<any> {


    const MUTATION = gql`
            ${fragment}
            mutation UpdateEmployeeTargetAZK (
                $targetAZKDings: EmployeeTargetAZKInput!
            ) {
                updateTargetAZK(
                    newTargetAZK: $targetAZKDings,
                )
                {
                    ...Fragment
                }
            }`

    const result = await mutation(
        MUTATION, {
            targetAZKDings: {
                value: bookings2Hours(newValue)
            },
        }
    )

    const response = result?.data?.updateTargetAZK
    if (!response) {
        errorCatcher('Fehler beim Speichern')
        return
    }

    const purge = true
    useEmployeeStore().reloadEmployee(purge)

    return response
}
