<template>
    <ion-page class="page-planned">
        <ion-content fullscreen padding="true">

            <!-- Logo -->

            <ion-row class="ion-justify-content-between ion-align-items-center">
                <img class="logo" src="/logo.png" alt="IBFG Logo">
                <ion-button class="button-settings" fill="clear" color="secondary" v-on:click="router.push('/settings')">
                    <ion-icon slot="icon-only" src="/icons/gear.svg" />
                </ion-button>
            </ion-row>

            <!-- Hello -->

            <Skeleton v-if="employeeLoader.pending.value" :items="['title-big', 'title-big', 'margin']"></Skeleton>

            <h1 class="custom-ricarda"
                v-else-if="employee?.name"
            >
                Hallo<br>{{ employee.name?.split(' ')[0] }}.
            </h1>


            <!-- Upcoming shift -->

            <Skeleton v-if="upcomingBookingLoader.pending.value" :items="['title', 'margin', 'block', 'margin']"></Skeleton>

            <ion-card v-else-if="upcomingBooking" class="custom-ion-card-dark custom-ion-card-floating">
                <ion-card-header>
                    <ion-card-title>
                        {{ datetime.isAfter(now, upcomingBooking.begin) ? 'Aktueller' : 'Nächster' }} Dienst
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content
                    v-on:click="router.push({ name: 'CalendarDetail', params: { dateId: upcomingBooking.date, expectedContent:'booking' }})"
                >
                    <ion-row class="ion-justify-content-between ion-align-items-center next-shift-card-content">
                        <div>
                            <h2 class="text-white next-shift-headline">
                                {{ datetime.formatKapitelDateTime(upcomingBooking.begin, 'cccc, d. LLLL') }}
                            </h2>
                            <div class="text-white text-bold">
                                {{ upcomingBooking.shiftType }} ab {{ datetime.formatKapitelDateTime(upcomingBooking.begin, datetime.kapitelDateTimeFormatTime) }}
                            </div>
                            <div class="text-grey-tint-3">
                                {{ upcomingBooking.jobsite.abbreviation }},
                                {{ upcomingBooking.jobsite.house ? (upcomingBooking.jobsite.house.abbreviation + ', ') : ''}}
                                {{ upcomingBooking.jobsite.client.abbreviation }}
                            </div>
                        </div>
                        <Avatar
                            :src="getImageForJobsite(upcomingBooking.jobsite)"
                        />
                    </ion-row>
                    <ion-button
                        v-if="upcomingTimesheetToDos.length>0"
                        v-on:click="()=>router.push({name: 'TimesheetProcess', params: { jobsiteId: upcomingBooking?.jobsite.id } })"
                        expand="block"
                        color="light"
                        style="margin-top:3%;"
                        >
                        {{timesheetToDoButtonLabel(upcomingTimesheetToDos)}}
                    </ion-button>
                </ion-card-content>
            </ion-card>
            <ion-card v-else-if="!upcomingBooking" class="custom-ion-card-floating">
                <ion-card-header>
                    <ion-card-title>
                        Nächster Dienst
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <ion-row class="ion-justify-content-between ion-align-items-center">
                        <div>
                            <h2 class=" next-shift-headline">
                                Dein erster Einsatz
                            </h2>
                            <div class="">
                                erscheint an dieser Stelle, sobald wir ihn für dich gefunden haben.
                            </div>
                        </div>
                    </ion-row>
                </ion-card-content>
            </ion-card>

            <!-- 7 days preview -->

            <Skeleton v-if="nextSevenDaysLoader.pending.value" :items="['title', 'margin', 'block', 'margin']"></Skeleton>

            <ion-card
                v-else
                class="custom-ion-card-floating"
                v-on:click="router.push('/calendar?date='+nextSevenDaysCalendarDates[0].id)"
            >
                <ion-card-header>
                    <ion-card-title>
                        7-Tage-Vorschau
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <div
                        class="seven-days-calendar"
                    >
                        <div
                            class="seven-days-calendar-date"
                            v-for="date in nextSevenDaysCalendarDates"
                        >
                            <div
                                class="date text-large"
                            >
                                {{ date.day }}
                            </div>
                            <div
                                class="weekday text-small"
                                :class="{'text-primary': date.isWeekend, 'text-grey': !date.isWeekend}"
                            >
                                {{ date.labelWeekdayShort }}
                            </div>
                            <div
                                v-if="nextSevenDays.get(date.id)?.booking"
                                class="text-small text-light text-extrabold booking-bubble"
                            >
                                {{ nextSevenDays.get(date.id)?.booking?.shiftType }}
                            </div>
                            <div
                                v-else-if="nextSevenDays.get(date.id)?.absence"
                                class="text-small text-light text-extrabold absence-bubble"
                            >
                                {{ getLabelForAbsenceType(nextSevenDays.get(date.id)?.absence.type, true) }}
                            </div>
                            <div
                                v-else-if="nextSevenDays.get(date.id)?.availability && nextSevenDays.get(date.id)?.availability?.shifts.length > 0"
                                class="text-small text-light text-extrabold availability-bubble"
                            >
                            </div>
                            <div
                                v-else
                                class="placeholder-bubble"
                            >
                                <!--empty-->
                            </div>
                        </div>
                    </div>
                </ion-card-content>
            </ion-card>


            <!-- Quick Access Menu -->

            <ion-card
                class="custom-ion-card-grid custom-ion-card-light"
            >
                <ion-card-header>
                    <ion-card-title>
                        Schnellzugriff
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content >
                    <div
                        class="quick-access"
                        v-on:click="router.push('/register-booking')"
                    >
                        <div class="text-dark text-bold">
                            <IonIcon slot="icon-only" src="/icons/plus.svg" />
                            Dienst eintragen
                        </div>
                    </div>
                    <div
                        v-if="getToDos.length > 0"
                        class="quick-access"
                        @click="router.push('/timesheet')"
                        >
                        <div class="text-dark text-bold">
                            <IonIcon :icon="timerOutline" />
                            Zeiten erfassen
                        </div>
                    </div>
                    <div
                        class="quick-access"
                        v-on:click="router.push('/chat')"
                    
                        >
                        <div class="text-dark text-bold">
                            <IonIcon :icon="chatbubblesOutline" />
                            Chat
                        </div>
                    </div>

                    <!-- <div class="quick-access" >
                        <div class="text-dark text-bold">
                            Krank melden
                        </div>
                    </div> -->
                    <!-- <div class="quick-access" >
                        <div class="text-dark text-bold">
                            Neues Mitglied werben
                        </div>
                    </div> -->
                </ion-card-content>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import { IonRow, IonIcon, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage, IonButton, onIonViewWillEnter } from '@ionic/vue';
import { ref, computed, Ref } from "vue";
import { generateCalendarDates } from "@/helper/calendar/calendarDate";
import { generateCalendarMonth, CalendarMonth } from "@/helper/calendar/calendarMonth";
import { useRouter } from 'vue-router';
import { fetchDayObjectsForDateRange } from "@/helper/day"
import Avatar from '@/components/Avatar.vue';
import ProgressCircle from '@/components/ProgressCircle.vue';

import datetime from "@/helper/datetime/datetime"
import date from '@/helper/datetime/date';

import {fetchUpcomingBooking} from "@/helper/booking";
import {DataLoader} from "@/helper/dataLoader";
import Skeleton from "@/components/Skeleton.vue";
import {Booking, Day, Employee, TimesheetToDo} from "@/graphql/generated/graphql";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {timesheetToDoButtonLabel} from '@/helper/timesheet';
import { storeToRefs } from 'pinia';
import {getLabelForAbsenceType} from "@/helper/absence";
import {useEmployeeStore} from "@/store/employee";
import {useTimesheetStore} from "@/store/timesheet";
import { getImageForJobsite } from '@/helper/jobsite';
import InlineSVG from '@/components/InlineSVG.vue';
import TextWithIcon from "@/components/TextWithIcon.vue";
import { useClock } from '@/helper/clock';
import { chatbubblesOutline, timeOutline, timerOutline } from 'ionicons/icons';

const router = useRouter()

const timesheetStore = useTimesheetStore()
timesheetStore.ensureInit()
const { getToDos, getToDosByJobsite } = storeToRefs(timesheetStore)

const now = datetime.getNow()
const today = date.getToday()
const thisMonthDate = date.startOfMonth(today)
const thisMonthDateCalendarMonth = generateCalendarMonth(thisMonthDate)

/**
 * HELLO
 */
const employeeStore = useEmployeeStore()
const employee:Ref<Employee|undefined> = computed(() => employeeStore.storedEmployee)
const employeeLoader = new DataLoader(async () => employeeStore.ensureEmployee() )
employeeLoader.load()

/**
 * UPCOMING SHIFT
 */
const upcomingBooking : Ref<Booking | undefined> = ref(undefined)
const upcomingBookingLoader = new DataLoader(async () => {
    upcomingBooking.value = await fetchUpcomingBooking()
})

onIonViewWillEnter(upcomingBookingLoader.load)
upcomingBookingLoader.load()

const clock = useClock()
const upcomingTimesheetToDos = computed((): TimesheetToDo[] => {
  if(!upcomingBooking.value?.jobsite){
    return []
  }
  return getToDosByJobsite.value(upcomingBooking.value.jobsite, clock.kapitelDateTimeString.value)
})


/**
 * NEXT SEVEN DAYS
 */
const nextSevenDaysMin = today
const nextSevenDaysMax = date.addDays(today, 6)
const nextSevenDaysCalendarDates = ref(generateCalendarDates(nextSevenDaysMin,nextSevenDaysMax ))
const nextSevenDays : Ref<Map<kapitelDateString, Day>> = ref(new Map())
const nextSevenDaysLoader = new DataLoader(async () => {
    const days = await fetchDayObjectsForDateRange(nextSevenDaysMin, nextSevenDaysMax)
    days.forEach(day => nextSevenDays.value.set(day.date, day))
})

onIonViewWillEnter(nextSevenDaysLoader.load)
nextSevenDaysLoader.load()


</script>

<style lang="scss">
.page-planned {
    padding-top: var(--ion-safe-area-top);

    .logo {
        width: 12vw;
        margin-bottom: 0.5em;
    }

    .button-settings::part(native) {
        padding: 0;
    }

    .button-booking::part(native) {
        padding: 0;
    }

    .next-shift-headline {
        margin-bottom: 1em;
    }

    .next-shift-card-content {
        flex-wrap: nowrap;
    }

    .seven-days-calendar {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;

        > .seven-days-calendar-date {
            flex: 0 0 calc(100% / 7);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .absence-bubble,
            .placeholder-bubble,
            .booking-bubble {
                width: 2em;
                height: 2em;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-top: 1em;

                &.booking-bubble {
                    background: var(--ion-color-dark);
                }
                &.absence-bubble {
                    background: var(--ion-color-red-tint);
                }
            }

            .availability-bubble {
                background: var(--ion-color-grey-tint-1-tint);
                width: 1em;
                height: 1em;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-top: 1.5em;
            }
        }
    }

    .quick-access {
        div {
            text-align: center;
        }

        .graph {
            display: flex;
            align-items: center;
            justify-content: center;

            .progress-circle {
                width: 5rem;
                height: 5rem;
                margin-bottom: 1rem;
            }

            .text-extralarge {
                height: 5rem;
                margin-bottom: 1rem;
            }
        }

        .text-with-icon {
            margin-top: 1em;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
