import {AssistantRunState} from "@/helper/chat/assistantRun";

export enum ChatStatus {
    READY,
    EXPECTING_NEXT_RESPONSE,
    // Optional:chooser
        CHOOSER_PENDING,
        // Optional: Infrastructure (Thread & assistant)
        // CHOOSER_SETUP = 0,
        // requesting
        // CHOOSER_REQUESTING = 1,
        // response streaming
        // CHOOSER_RESPONDING = 2,
        // complete
        // CHOOSER_COMPLETE = 3,
    // Expert
        // Optional: Infrastructure (Thread & assistant) and also run before request started
        EXPERT_SETUP,
    //
    EXPERT_PREPENDING_ASSISTANT_MESSAGE,
    // requesting
    EXPERT_REQUESTING,
    // response streaming
    EXPERT_RESPONDING,
        // toolcall
        // EXPERT_TOOLCALL,
        // text streaming
        // EXPERT_TEXT_STREAMING,
    // complete
    EXPERT_COMPLETE,
    // any failure lands here
    FAILED,
}

export namespace ChatStatus {
    export const stringify = (status: ChatStatus): string => ChatStatus[status] + " [" + status + "]"

    export const isResting = (status: ChatStatus): boolean => [ChatStatus.READY].includes(status) || ChatStatus.isDone(status)
    export const isPending = (status: ChatStatus): boolean => !ChatStatus.isResting(status)
    export const isChoosing = (status: ChatStatus): boolean => [ChatStatus.CHOOSER_PENDING].includes(status)
    export const isExperting = (status: ChatStatus): boolean => [ChatStatus.EXPERT_SETUP, ChatStatus.EXPERT_PREPENDING_ASSISTANT_MESSAGE, ChatStatus.EXPERT_REQUESTING, ChatStatus.EXPERT_RESPONDING].includes(status)
    export const isDone = (status: ChatStatus): boolean => [ChatStatus.EXPERT_COMPLETE, ChatStatus.FAILED].includes(status)

    export const fromAssistantRunState = (state: AssistantRunState) : ChatStatus => {
        switch (state) {
            case AssistantRunState.INIT: return ChatStatus.EXPERT_SETUP
            case AssistantRunState.REQUESTED: return ChatStatus.EXPERT_REQUESTING
            case AssistantRunState.RUNNING: return ChatStatus.EXPERT_RESPONDING
            case AssistantRunState.PENDING_TOOL_CALL: return ChatStatus.EXPERT_RESPONDING // leave in responding
            case AssistantRunState.COMPLETED: return ChatStatus.EXPERT_COMPLETE
            case AssistantRunState.CANCELLED: return ChatStatus.FAILED
            case AssistantRunState.ABORTED: return ChatStatus.FAILED
        }
    }
    export function transitionTo(nextState: ChatStatus, previousState: ChatStatus, force: boolean = false) : ChatStatus {
        if (
            nextState >= previousState || // always up
            (nextState === ChatStatus.READY && ChatStatus.isDone(previousState)) || // always allow reset to init from done
            (nextState === ChatStatus.READY && force) // always allow reset to init by force
        ) {
            return nextState;
        }

        console.error("drop chatStatus" + (force ? 'FORCED' : '') + " transition from " + ChatStatus.stringify(previousState) + " to " + ChatStatus.stringify(nextState))
        return previousState
    }
}
