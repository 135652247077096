<script setup>
import { IonList, IonRadioGroup, IonRadio, IonItem } from '@ionic/vue'
import { onMounted, ref, watch } from 'vue'

const props = defineProps({
    label: String,
    modelValue: [String, Number, Object, Date],
    items: Array
})
const emit = defineEmits(['update:modelValue'])

onMounted(() => {
    if (!props.modelValue) {
        emit('update:modelValue', props.items[0])
    }
})

const onChange = e => {
    emit('update:modelValue', e.detail.value)
}
</script>

<template>
<div
    class="radio-group"
>
    <div
        class="label"
    >
        {{ props.label }}
    </div>

    <ion-list>
        <ion-radio-group
            @ionChange="onChange"
            :value="props.modelValue || props.items[0]"
        >
            <ion-item
                v-for="(item, index) in props.items"
                :lines="index === (props.items.length - 1) ? 'none' : 'full'"
            >
                <ion-radio
                    label-placement="end"
                    justify="start"
                    :value="item"
                >
                    <slot
                        :item="item"
                    >
                        {{ item }}
                    </slot>
                </ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>
</div>
</template>

<style lang="scss">
.radio-group {
    > ion-list {
        background-color: var(--ion-color-light);
        border-radius: 1rem;

        ion-radio {
            padding-left: 1em;
        }
    }

    > .label {
        font-size: var(--custom-font-size-small);
        color: var(--ion-color-grey);
        margin-bottom: 0.5em;
    }

    margin-bottom: 1rem;
}
</style>