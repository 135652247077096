<script setup lang="ts">
import { ref, watch, computed, PropType, Ref } from "vue";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonModal,
} from "@ionic/vue";
import { fetchAvailabilityShiftDefaults } from "@/helper/availabilityShiftDefault";
import Modal from "@/components/Modal.vue";
import { setAvailabilityTemplate } from "@/helper/availabilityTemplate";
import {
    availabilityShiftsAndTimeConstraintsToIntervals,
    generateAvailability,
    generatePropertiesFromDefault,
    setAvailability,
} from "@/helper/availability";
import AvailabilityConstraintEditorModal from "./AvailabilityConstraintEditorModal.vue";

import { orderedShiftTypes } from "@/helper/shiftTypes";
import { getShortJobsiteDescription } from "@/helper/jobsite";
import {
    Availability,
    AvailabilityShift,
    AvailabilityTemplate,
    AvailabilityTemplateShift,
    AvailabilityTemplateTimeConstraint,
    AvailabilityTimeConstraint,
    Jobsite,
    ShiftType,
} from "@/graphql/generated/graphql";
import AvailabilityShiftsTimeEditor from "@/components/AvailabilityShiftsTimeEditor.vue";

import datetime from "@/helper/datetime/datetime";
import time from "@/helper/datetime/time";

const props = defineProps({
    availabilityTemplate: {
        type: Object as PropType<AvailabilityTemplate>,
        default: undefined,
        required: false,
    },
    // the heading
    title: {
        type: String,
        default: undefined,
        required: true,
    },
    // special for template
    date: {
        type: String,
        default: undefined,
        required: false,
    },
});

const editItem: Ref<AvailabilityTemplate | undefined> = ref(undefined);
const editItemIndex = ref(-1);
const editTimeConstraint = ref(undefined);
const editJobsiteConstraint = ref(undefined);
const isTimeConstraintModalOpen = ref(false);
const isJobsiteConstraintModalOpen = ref(false);
const isAvailabilityShiftTimeModalOpen = ref(false);
const availabilityShiftDefaults = ref();
const isJobsiteExclusionModalOpen = ref(false);
const selectedExcludedJobsite = ref<Jobsite | undefined>(undefined);
const jobsiteExclusionModal = ref();

const sortedConstraints = computed(() => {
    let sortFn;
    if (editItem?.value) {
        sortFn = (
            a: AvailabilityTemplateTimeConstraint,
            b: AvailabilityTemplateTimeConstraint,
        ) => {
            return time.cmpKapitelTime(a.begin, b.begin);
        };
    }
    return !editItem?.value ? [] : editItem.value?.constraints.sort(sortFn);
});

const getIndexOfShift = (shift) =>
    editItem.value?.shifts.findIndex((sh) => sh.type === shift);
const isShiftActive = (shift) => getIndexOfShift(shift) > -1;

const onClickShift = (shift) => {

    const index = getIndexOfShift(shift);

    if (index > -1) {
        editItem.value.shifts.splice(index, 1);
    } else {
        editItem.value.shifts.push({ type: shift });
    }

    pushChanges();
};

const onClickExistingJobsiteConstraint = (jobsite) => {
    isJobsiteExclusionModalOpen.value = true;
    selectedExcludedJobsite.value = jobsite;
};

const onClickExistingTimeConstraint = (constraint) => {

    const  constraintInterface = {
        begin: constraint.begin,
        until: constraint.until,
        comment: constraint.comment,
    };

    editItemIndex.value = editItem.value?.constraints.indexOf(constraint);

    editTimeConstraint.value = constraintInterface;
    isTimeConstraintModalOpen.value = true;
};

const onUpdateTimeConstraint = (constraintInterface) => {

    const constraint = {
        begin: constraintInterface.begin || undefined,
        until: constraintInterface.until || undefined,
        comment: constraintInterface.comment,
    };

    // replace existing item if necessary
    if (editItemIndex?.value >= 0) {
        editItem.value?.constraints.splice(editItemIndex.value, 1);
        editItemIndex.value = -1;
    }

    editItem.value.constraints = editItem.value.constraints || [];
    editItem.value.constraints.push(constraint);

    pushChanges();
};

const onRemoveTimeConstraint = (constraint) => {
    if (editItemIndex?.value >= 0) {
        editItem.value?.constraints.splice(editItemIndex.value, 1);
        editItemIndex.value = -1;
        pushChanges();
    }
};

const pushChanges = () => {
    setAvailabilityTemplate(editItem.value);
};

const fetchAndParseAvailabilityShiftDefaults = () =>
    fetchAvailabilityShiftDefaults().then(
        (res) => (availabilityShiftDefaults.value = res),
    );

watch(
    () => props.availabilityTemplate,
    (value) => {
        editItem.value = value;
    },
);

watch(
    () => props.availability,
    (value) => {
        editItem.value = value;
    },
);

watch(
    () => isTimeConstraintModalOpen.value,
    (value) => {
        if (!value) {
            editTimeConstraint.value = undefined;
        }
    },
);

watch(
    () => isJobsiteConstraintModalOpen.value,
    (value) => {
        if (!value) {
            editJobsiteConstraint.value = undefined;
        }
    },
);

fetchAndParseAvailabilityShiftDefaults();
editItem.value = props.availabilityTemplate
</script>

<template>
    <ion-card class="custom-ion-card-light availability-template-editor">
        <ion-card-header v-if="title">
            <ion-card-title>
                {{ title }}
            </ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <div class="shift-toggler">
                <ion-button
                    v-for="shift in orderedShiftTypes"
                    :color="isShiftActive(shift) ? 'secondary' : 'light'"
                    v-on:click="onClickShift(shift)"
                >
                    {{ shift }}
                </ion-button>
            </div>
            <ion-list class="custom-ion-card-content-item-full-width">
                <ion-item-divider class="list-divider" />
                <ion-item
                    v-for="constraint in sortedConstraints"
                    detail="true"
                    detailIcon="/icons/caret-right.svg"
                    @click="onClickExistingTimeConstraint(constraint)"
                >
                    <ion-label>
                        <p
                            class="text-dark"
                            v-if="editItem === availabilityTemplate"
                        >
                            nicht
                            {{ constraint.begin || "egal" }}
                            {{
                                constraint.begin && !constraint.until
                                    ? "Uhr"
                                    : ""
                            }}
                            -
                            {{ constraint.until || "egal" }}
                            {{ constraint.until ? "Uhr" : "" }}
                            <span v-if="constraint.comment">
                                ({{ constraint.comment }})
                            </span>
                        </p>
                    </ion-label>
                </ion-item>
                <ion-item>
                    <ion-button
                        class="button-expand-full"
                        fill="clear"
                        size="default"
                        color="secondary"
                        v-on:click="isTimeConstraintModalOpen = true"
                    >
                        Zeit einschränken
                    </ion-button>
                </ion-item>
            </ion-list>
        </ion-card-content>
    </ion-card>

    <AvailabilityConstraintEditorModal
        v-model="isTimeConstraintModalOpen"
        :time-constraint="editTimeConstraint"
        v-on:update:time-constraint="onUpdateTimeConstraint"
        v-on:remove:time-constraint="onRemoveTimeConstraint"
    />

    <Modal
        v-model="isAvailabilityShiftTimeModalOpen"
        :initial-breakpoint="1"
        :breakpoints="[0, 1]"
    >
        <AvailabilityShiftsTimeEditor
            v-if="availability"
            :availabilityShifts="availability.shifts || []"
            v-on:update:available-shifts="onUpdateAvailabilityShifts"
        ></AvailabilityShiftsTimeEditor>
    </Modal>
</template>

<style lang="scss" scoped>
ion-modal {
    --height: auto;
    min-height: 200px;
}

.availability-template-editor {
    ion-card-content.card-content-ios {
        padding-bottom: 0 !important;

        .shift-toggler {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > * {
                flex: 0 1 22%;
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .list-divider {
            height: 1px;
            min-height: 0;
        }

        .button-expand-full {
            width: 100%;
        }
    }
}
</style>
