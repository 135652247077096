<script setup>
import { IonButton, IonCard, IonCardContent } from '@ionic/vue';
import { ref, computed, watch } from 'vue';
import { fetchDaysForDateRange, yieldEffectiveAbsences, yieldEffectiveAvailabilities, yieldEffectiveBookings } from "@/helper/day";
import Modal from '@/components/Modal.vue'
import date from "@/helper/datetime/date";

const props = defineProps({
    modelValue: Boolean,
    beginDate: String,
    endDate: String,
    summary: Object,
})

const emit = defineEmits(['update:modelValue', 'save'])
const isOpen = ref(false)
// const bookings = ref();
// const absences = ref();
// const availabilities = ref();
const endDateFormatted = computed(() => {
    return props.endDate ? date.formatKapitelDate(props.endDate, 'MMMM') : ''
})

const summary = computed(() => {
    return [
        {
            label: 'Tage verfügbar',
            value: props.summary.availabilities.length || 0,
        },
        /* {
            label: 'Tage sind abweichend von der Vorlage',
            value: props.summary.availabilities.filter(av => av.differsFromTemplate).length || 0,
        }, */
        {
            label: 'Tage abwesend',
            value: props.summary.absences.length || 0,
        },
        {
            label: 'Tage bereits geplant',
            value: props.summary.bookings.length || 0,
        }
    ]
})

watch(() => props.modelValue, value => {
    isOpen.value = value

    if (value) {
        // fetchDays()
    }
})
watch(() => isOpen.value, value => {
    emit('update:modelValue', value)
})

const fetchDays = () => {
    fetchDaysForDateRange(props.beginDate, props.endDate).then(res => {
        bookings.value = yieldEffectiveBookings(res)
        absences.value = yieldEffectiveAbsences(res)
        availabilities.value = yieldEffectiveAvailabilities(res)
    })
}

const onPressCancel = () => {
    isOpen.value = false
}

const onPressConfirm = () => {
    isOpen.value = false
    emit('save')
}
</script>

<template>
<Modal
    v-model="isOpen"
    title="Planung abschließen"
>
    <span>
        Hiermit werden die Verfügbarkeiten für den Monat {{ endDateFormatted }} verbindlich kommuniziert und die Dienstplanung gestartet.
    </span>

    <ion-card
        class="custom-ion-card-light planning-summary-card"
    >
        <ion-card-content>
            <div
                class="summary"
            >
                <div
                    v-for="item in summary"
                >
                    <div
                        class="value text-extralarge text-extrabold"
                    >
                        {{ item.value }}
                    </div>
                    <div
                        class="label"
                    >
                        {{ item.label }}
                    </div>
                </div>
            </div>
        </ion-card-content>
    </ion-card>
    <ion-button
        @click="onPressCancel"
        fill="clear"
        color="secondary"
        expand="block"
    >
        Abbrechen
    </ion-button>
    <ion-button
        @click="onPressConfirm"
        expand="block"
        color="secondary"
    >
        Planung für {{ endDateFormatted }} abschließen
    </ion-button>
</Modal>
</template>

<style lang="scss">
.planning-summary-card {
    .summary {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1em;

        > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            > .value {
                color: var(--ion-color-dark);
            }

            > .label {
                color: var(--ion-color-medium);
                margin-top: 0.5em;
                margin-bottom: 1rem;
                text-align: center;
            }
        }
    }
}
</style>
