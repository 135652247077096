import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {Absence, AbsenceType} from "@/graphql/generated/graphql";
import useEmitter from "@/helper/emitter";
import {kapitelDateString} from "@/graphql/kapitelTypes";

const emitter = useEmitter()

const orderedAbsenceTypes = [
    AbsenceType.Timeoff,
    AbsenceType.ExternalBooking,
    AbsenceType.Vacation,
    AbsenceType.Sickness
]

export const defaultAbsenceType = orderedAbsenceTypes[0]

export const absenceFragment = gql`fragment AbsenceFragment on Absence {
    id,
    date,
    type,
    comment,
    worktimeValuation
}`

export const getLabelForAbsenceType = (absenceType: AbsenceType, abbreviated = false): string => {
    switch (absenceType) {
        case AbsenceType.Sickness:
            return  abbreviated ? 'K' : 'Krank'
        case AbsenceType.Timeoff:
            return  abbreviated ? '-' : 'Wunschfrei'
        case AbsenceType.Vacation:
            return  abbreviated ? 'U' : 'Urlaub'
        case AbsenceType.ExternalBooking:
            return  abbreviated ? 'D' : 'Externer Dienst'
    }

    return  abbreviated ? '-' : 'Abwesend'
}

export function generateAbsence(
    date: kapitelDateString,
    type: AbsenceType
): Absence {
    return <Absence>{
        date: date,
        type,
    }
}

export async function fetchAbsencesForDate(date: kapitelDateString): Promise<any> {
    const result = await query(
        gql`${absenceFragment}
            query GetAbsence (
            $date: KapitelDateImmutable!
        ) {
            absence(date: $date) {
                ...AbsenceFragment
            }
        }`, {
            date: date
        }
    )

    const absences = result?.data?.absences;
    if (!absences) {
        throw new Error("no absences in absence response")
    }

    return absences
}



export async function fetchAbsencesForDateRange(begin: kapitelDateString, until: kapitelDateString): Promise<any> {
    const result = await query(
        gql`${absenceFragment}
            query GetAbsences (
                $min: KapitelDateImmutable!
                $max: KapitelDateImmutable!
            ) {
            absences(betweenDates: {max: $max, min: $min}) {
                ...AbsenceFragment
            }
        }`, {
                min: begin,
                max: until
            }
        )

    const absences = result?.data?.absences;
    if (!absences) {
        throw new Error("no absences in absence response")
    }

    return absences
}

export function getAbsenceTypes() {
    return Object.values(AbsenceType)
    // Object.keys(AbsenceType).filter(key => !isNaN(Number(AbsenceType[key])));
}

export const cmpAbsenceTypes = (at1:AbsenceType, at2:AbsenceType) => orderedAbsenceTypes.indexOf(at1) - orderedAbsenceTypes.indexOf(at2)

export async function setAbsence(absence: Absence) {

    emitter.emit('absence:mutating', absence.date)

    const MUTATION = gql`
        mutation setAbsence(
            $date: KapitelDateImmutable!,
            $type: AbsenceType!,
            $worktimeValuation: Float,
            $comment: String
        ) {
            setAbsence(
                date: $date,
                type: $type,
                worktimeValuation: $worktimeValuation,
                comment: $comment
            ) {
                date
            }
        }`

    const result = await mutation(
        MUTATION, {
            date: absence.date,
            type: absence.type,
            worktimeValuation: absence.worktimeValuation,
            comment: absence.comment
        }
    )

    emitter.emit('absence:mutated', absence.date)

    const success = result?.data?.setAbsence?.date

    if (!success) {
        throw new Error("error while storing absence")
    }

    return success
}

export async function removeAbsence(absence: Absence) {

    emitter.emit('absence:mutating', absence.date)

    const MUTATION = gql`
        mutation removeAbsence(
            $date: KapitelDateImmutable!
        ) {
            removeAbsence(
                date: $date
            )
        }`

    const result = await mutation(
        MUTATION, {
            date: absence.date
        }
    )

    emitter.emit('absence:mutated', absence.date)

    const success = result?.data

    if (!success) {
        throw new Error("error while removing absence")
    }

    return success
}


export function getAbsenceSummary(absences:Absence[]) {

    const typeList:Array<AbsenceType> = []
    absences.forEach(absence => {
        if (!typeList.includes(absence.type)) {
            typeList.push(absence.type)
        }
    })

    return {
        count: absences.length,
        typeList,
        typeLabelList: typeList.map(t => getLabelForAbsenceType(t))
    }
}
