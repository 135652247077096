
import { App } from 'vue';
import { env as envHelper } from '@/helper/env';
import * as Sentry from "@sentry/vue";
import { useAuthStore } from '@/store/auth';
import { useAppState } from './appState';
import useEmitter, { GlobalEvents } from './emitter';

let initialized = false;

const sentryEnabled = () => {
    const flag = import.meta.env.VITE_SENTRY_ENABLED
    return flag === 'true';
}

const updateUserContext = () => {
    if (!initialized) {
        return
    }

    const authStore = useAuthStore()
    
    const employeeName = authStore.isConsultant()
        ? authStore.getImpersonationUsername()
        : authStore.getCurrentUserIdentifier();

    const userName = authStore.getCurrentUserIdentifier()
    
    // use employee as user
    const scope = Sentry.getCurrentScope();
    scope.setUser({
        id: employeeName,
        username: employeeName,
    });

    // log real user context
    Sentry.setContext('realUser', {
        username: userName,
        roles: authStore.roles,
        consultant: authStore.isConsultant(),
        impersonating: authStore.isImpersonating(),
        impersonatingAs: authStore.getImpersonationUsername()
    });
}

export const useSentry = (app: App) => {
    if (!sentryEnabled()) {
        return;
    }

    Sentry.init({
        app,
        dsn: "https://52c016c11d8e82f3c80717fa757b8b41@o4507033914245120.ingest.us.sentry.io/4507033918242816",
        environment: envHelper.getEnvironment(),
        integrations: [
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
            }),
        ],
        // Session Replay
        replaysSessionSampleRate: 1, // 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    initialized = true;

    updateUserContext();        

    useEmitter().on(GlobalEvents.AppStateChanged, updateUserContext)

    //@ts-ignore
    window.SENTRY_ERROR_TEST = () => { throw new Error("Sentry Error Test") }
}
