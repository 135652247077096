<script setup lang="ts">
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
} from "@ionic/vue";
import TextWithIcon from "@/components/TextWithIcon.vue";
import InlineSVG from "@/components/InlineSVG.vue";
import {computed, Ref, ref} from "vue";
import {getSummary} from "@/helper/timesheet";
import {useRoute} from "vue-router";
import {pluralize} from "@/helper/amountFormatter";
import TimesheetHeader from "@/views/Timesheet/components/TimesheetHeader.vue";
import {useTimesheetStore} from "@/store/timesheet";

const route = useRoute();

const store = useTimesheetStore();

const props = defineProps({
    timesheetNavigator: {
        type: Object,
        required: true
    }
})

const signateurNameDefault = computed(()=>
  Number(route.params.jobsiteId) in store.getPreviousTimesheetSignateurNamesLookup
  ? store.getPreviousTimesheetSignateurNamesLookup[Number(route.params.jobsiteId)]
  : ""
)
const signateurName : Ref<string> = ref(signateurNameDefault.value);
const emailWanted = ref(false);

const signateurEMailDefault = computed(()=>
  Number(route.params.jobsiteId) in store.getPreviousTimesheetSignateurEMailsLookup
  ? store.getPreviousTimesheetSignateurEMailsLookup[Number(route.params.jobsiteId)]
  : ""
)
const signateurEmail = ref(signateurEMailDefault.value);

const validName = computed(() => signateurName.value != "");
const validEmail = computed(
    () => !emailWanted.value || signateurEmail.value != "",
);

const valid = computed(() => {
    return validName.value && validEmail.value;
});

const setSignateur = () => {
    if (valid.value) {
        store.setSignateur(signateurName.value, signateurEmail.value);
        props.timesheetNavigator.next()
    }
};
</script>

<template>
        <TimesheetHeader :timesheet-navigator="timesheetNavigator"></TimesheetHeader>
        <ion-content class="no-padding-top">
            <ion-card class="custom-ion-card-dark">
                <ion-card-content
                    class="no-padding-top no-padding-bottom"
                    v-if="store.timesheetToDosInSignatureProcess.length > 0"
                >
                    <ion-list>
                        <ion-item lines="none">
                            <ion-label>
                                <h3 class="text-white">Einsätze</h3>
                                <p class="text-white">
                                    {{
                                        store.timesheetToDosInSignatureProcess
                                            ? getSummary(
                                                  store
                                                      .timesheetToDosInSignatureProcess[0],
                                                  store.timesheetToDos,
                                              ).jobsiteDescription
                                            : ""
                                    }}
                                </p>
                                <p class="text-white">
                                    {{
                                        pluralize(
                                            store.timesheetToDosInSignatureProcess.length,
                                            "Dienst",
                                            "Dienste"
                                        )
                                    }}
                                </p>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
            </ion-card>
            <ion-card>
                <ion-card-header>
                    <ion-card-title> Wer Signiert? </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <ion-input
                        @ion-input="
                            ({ target }) => {
                                signateurName = target.value?.toString() || '';
                            }
                        "
                        :value="signateurNameDefault"
                        placeholder="Vor- und Nachname"
                    ></ion-input>
                    <TextWithIcon
                        :small="true"
                        :warning="!validName"
                        :success="validName"
                    >
                        <template #icons>
                            <InlineSVG
                                v-if="!validName"
                                src="/icons/info.svg"
                            />
                            <InlineSVG v-else src="/icons/check-circle.svg" />
                        </template>
                        <p>
                            {{ !validName ? "Kein Name angegeben" : "" }}
                        </p>
                    </TextWithIcon>
                </ion-card-content>
            </ion-card>
            <ion-card>
                <ion-card-header>
                    <ion-card-title> E-Mail Bestätigung </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <ion-button
                        v-if="!emailWanted"
                        color="light"
                        v-on:click="emailWanted = true"
                        expand="block"
                    >
                        Der Kunde wünscht eine Bestätigung
                    </ion-button>
                    <ion-input
                        v-if="emailWanted"
                        @ion-input="
                            ({ target }) => {
                                signateurEmail = target.value?.toString() || '';
                            }
                        "
                        type="email"
                        :value="signateurEMailDefault"
                        placeholder="email@domain.com"
                    ></ion-input>

                    <TextWithIcon
                        v-if="emailWanted"
                        :small="true"
                        :warning="!validEmail"
                        :success="validEmail"
                    >
                        <template #icons>
                            <InlineSVG
                                v-if="!validEmail"
                                src="/icons/info.svg"
                            />
                            <InlineSVG v-else src="/icons/check-circle.svg" />
                        </template>
                        <p>
                            {{
                                !validEmail
                                    ? "Keine E-Mail Adresse angegeben"
                                    : ""
                            }}
                        </p>
                    </TextWithIcon>
                </ion-card-content>
            </ion-card>

            <ion-button
                :disabled="!valid"
                expand="block"
                v-on:click="setSignateur()"
                >Dienste Signieren</ion-button
            >
        </ion-content>
</template>
