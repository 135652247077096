import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {AInesJobsiteListType, House, Jobsite} from "@/graphql/generated/graphql";
import {JobsiteCategory} from "@/graphql/kapitelTypes";
import {pluralize, pluralizeUnitOnly} from "./amountFormatter";

export const jobsiteFragment = gql`fragment JobsiteFragment on Jobsite {
    id,
    name,
    abbreviation,
    additionalAmenImportantAbbreviations,
    additionalAmenImportantLabels,
    isFavorite,
    isAlternative,
    isBooked
}`

export const jobsiteDetailsFragment = gql`${jobsiteFragment} fragment JobsiteDetailsFragment on Jobsite {
    ...JobsiteFragment
    about,
    requirements
    fachbereiche {
        name,
        abbreviation
    }
}`
export const clientFragment = gql`fragment ClientFragment on Client {
    name
    abbreviation
    logo
    imageURL
}`
export const houseFragment = gql`fragment HouseFragment on House {
    name
    abbreviation
    imageURL
}`
export const addressFragment = gql`fragment AddressFragment on Address {
    name
    company
    street
    district
    postalCode
    city
    country
}`
export const contactFragment = gql`fragment ContactFragment on Contact {
    name
    phone
    mobile
    mail
}`

export const getImageForJobsite = (jobsite: Jobsite | undefined) => {
    return jobsite?.house?.imageURL || jobsite?.client?.imageURL || '/clients/generative.jpg'
}

export async function fetchJobsites(queryStr = '', offset = 0, count = 25, excludeJobsites : Jobsite[] = []): Promise<any> {
    const result = await query(gql`
        ${jobsiteFragment}
        ${clientFragment}
        ${houseFragment}
        query QueryJobsites ($query: String, $pagination: Int, $pageSize: Int, $excludeJobsiteIds: [Int!]) {
            jobsitesByQuery (query: $query, pagination: $pagination, pageSize: $pageSize, excludeJobsiteIds: $excludeJobsiteIds) {
                jobsite {
                    ...JobsiteFragment
                    client {
                        ...ClientFragment
                    }
                    house {
                        ...HouseFragment
                    }
                }
                isLastItem
              }
        }`,
        {
            query: queryStr,
            pagination: offset,
            pageSize: count,
            excludeJobsiteIds: excludeJobsites.map((j) => j.id)
        }
    )

    return result?.data?.jobsitesByQuery
}


export const getJobsitesByType = async (listType: AInesJobsiteListType): Promise<any> => {
    switch (listType){
        case AInesJobsiteListType.Favorite:
            return fetchFavoriteJobsites()
        case AInesJobsiteListType.Alternative:
            return fetchSecondChoiceJobsites()
        default:
            throw new Error("Unable to fetch this jobsite list type.")
    }
}

export async function fetchFavoriteJobsites(): Promise<any> {
    const result = await query(gql`
        ${clientFragment}
        ${houseFragment}
        ${jobsiteFragment}
        query GetFavoriteJobsites{
              favoriteJobsites {
                ...JobsiteFragment
                client {
                    ...ClientFragment
                }
                house {
                    ...HouseFragment
                }
              }
        }`
    )

    return result?.data?.favoriteJobsites
}

export async function fetchSecondChoiceJobsites(): Promise<any> {
    const result = await query(gql`
        ${clientFragment}
        ${houseFragment}
        ${jobsiteFragment}
        query GetSecondChoiceJobsites{
            secondChoiceJobsites {
                ...JobsiteFragment
                client {
                    ...ClientFragment
                }
                house {
                    ...HouseFragment
                }
              }
        }`
    )

    return result?.data?.secondChoiceJobsites
}

export async function fetchBlacklistedJobsites(): Promise<any> {
    const result = await query(gql`
        ${clientFragment}
        ${houseFragment}
        ${jobsiteFragment}
        query GetBlacklistedJobsites{
            blacklistJobsites {
                ...JobsiteFragment
                client {
                    ...ClientFragment
                }
                house {
                    ...HouseFragment
                }
              }
        }`
    )

    return result?.data?.blacklistJobsites
}

export const fetchJobsiteDetail = async (jobsiteId: number): Promise<any>=> {

    const result = await query(gql`
        ${jobsiteDetailsFragment}
        ${clientFragment}
        ${houseFragment}
        ${addressFragment}
        ${contactFragment}
        query GetJobsite ($idDings: Int!){
            jobsite (
            id: $idDings
            ){
                ...JobsiteDetailsFragment
                client {
                    ...ClientFragment
                    address {...AddressFragment}
                },
                house {
                    ...HouseFragment
                    address {...AddressFragment}
                }
                address {...AddressFragment},
                contact {...ContactFragment}
            }
        }`,
        {
            idDings: jobsiteId
        }
    )

    return result?.data?.jobsite
}


export const getJobsites = async (ids: Array<number>): Promise<any> => {

    const result = await query(
        gql`${jobsiteFragment}
            query GetJobsites (
                $ids: [Int!]!
            ){
              jobsites (
                ids: $ids
              ){
                  ...JobsiteFragment
              }
        }`,
        {
            ids: ids
        }
    )

    return result?.data?.jobsites
}

export const getJobsiteDetails = async (ids: Array<number>): Promise<any> => {

    const result = await query(gql`
        ${jobsiteDetailsFragment}
        ${clientFragment}
        ${houseFragment}
        ${addressFragment}
        ${contactFragment}
        query GetJobsiteDetails (
            $ids: [Int!]!
        ){
            jobsites (
                ids: $ids
            ){
                ...JobsiteDetailsFragment
                client {
                    ...ClientFragment
                    address {...AddressFragment}
                },
                house {
                    ...HouseFragment
                    address {...AddressFragment}
                }
                address {...AddressFragment},
                contact {...ContactFragment}
            }
        }`,
        {
            ids: ids
        }
    )

    return result?.data?.jobsites
}



export const fetchJobsiteShiftTimes = async (jobsite: Jobsite) => {

    const result = await query(gql`
        query GetJobsiteShiftTimes (
            $jobsiteDings: Int!
        ){
            jobsiteShiftTimes (jobsite: { id: $jobsiteDings}) {
                type,
                begin,
                until
            }
        }`,
        {
            jobsiteDings: jobsite.id
        }
    )

    return result?.data?.jobsiteShiftTimes
}


// Mutations

export const addFavoriteJobsite = async (jobsite: Jobsite) => {
    const MUTATION = gql`
            ${clientFragment}
            ${houseFragment}
            ${jobsiteFragment}
            mutation AddFavoriteJobsite (
                    $idDings: Int!,
            ) {
                addFavoriteJobsite(
                    id: $idDings
                )
                {
                    ...JobsiteFragment
                    client {
                        ...ClientFragment
                    },
                    house {
                        ...HouseFragment
                    }
                }
            }`

    const result = await mutation(
        MUTATION, {
            idDings: jobsite.id,
            nameDings: jobsite.name
        }
    )

    return result?.data.addFavoriteJobsite
}

export const removeFavoriteJobsite = async (jobsite: Jobsite) => {
    const MUTATION = gql`
            ${clientFragment}
            ${houseFragment}
            ${jobsiteFragment}
            mutation RemoveFavoriteJobsite (
                    $idDings: Int!,
            ) {
                removeFavoriteJobsite(
                id: $idDings,
                )
                {
                    ...JobsiteFragment
                    client {
                        ...ClientFragment
                    },
                    house {
                        ...HouseFragment
                    }
                }
            }`

    const result = await mutation(
        MUTATION, {
            idDings: jobsite.id
        }
    )

    return result?.data.removeFavoriteJobsite
}

export const addSecondChoiceJobsite = async (jobsite: Jobsite) => {
    const MUTATION = gql`
        ${clientFragment}
        ${houseFragment}
        ${jobsiteFragment}
        mutation AddSecondChoiceJobsite (
                $idDings: Int!,
        ) {
            addSecondChoiceJobsites(
                jobsiteId: $idDings
            )
            {
                ...JobsiteFragment
                client {
                    ...ClientFragment
                },
                house {
                    ...HouseFragment
                }
            }
        }`

    const result = await mutation(
    MUTATION, {
        idDings: jobsite.id,
        nameDings: jobsite.name
    }
    )

    return result?.data.addSecondChoiceJobsites
}

export const removeSecondChoiceJobsite = async (jobsite: Jobsite) => {
    const MUTATION = gql`
            ${clientFragment}
            ${houseFragment}
            ${jobsiteFragment}
            mutation RemoveSecondChoiceJobsite (
                    $idDings: Int!,
            ) {
                removeSecondChoiceJobsites(
                    jobsiteId: $idDings,
                )
                {
                    ...JobsiteFragment
                    client {
                        ...ClientFragment
                    },
                    house {
                        ...HouseFragment
                    }
                }
            }`

    const result = await mutation(
        MUTATION, {
            idDings: jobsite.id
        }
    )

    return result?.data.removeSecondChoiceJobsites
}

export const updateSecondChoiceJobsites = async (jobsiteIds: Array<number>) => {
    const MUTATION = gql`
        ${clientFragment}
        ${houseFragment}
        ${jobsiteFragment}
        mutation UpdateSecondChoiceJobsites (
                $orderedJobsites: [Int!]!,
        ) {
            updateSecondChoiceJobsites(
                orderedJobsites: $orderedJobsites,
            )
            {
                ...JobsiteFragment
                client {
                    ...ClientFragment
                },
                house {
                    ...HouseFragment
                }
            }
        }`

    const result = await mutation(
        MUTATION, {
            orderedJobsites: jobsiteIds
        }
    )

    return result?.data.updateSecondChoiceJobsites
}

export const addBlacklistJobsite = async (jobsite: Jobsite) => {
    const MUTATION = gql`
        ${clientFragment}
        ${houseFragment}
        ${jobsiteFragment}
        mutation AddBlacklistJobsite (
                $idDings: Int!,
        ) {
            addBlacklistJobsite(
                id: $idDings
            )
            {
                ...JobsiteFragment
                client {
                    ...ClientFragment
                },
                house {
                    ...HouseFragment
                }
            }
        }`

    const result = await mutation(
    MUTATION, {
        idDings: jobsite.id,
        nameDings: jobsite.name
    }
    )

    return result?.data.addBlacklistJobsite
}

export const removeBlacklistJobsite = async (jobsite: Jobsite) => {
    const MUTATION = gql`
            ${clientFragment}
            ${houseFragment}
            ${jobsiteFragment}
            mutation RemoveBlacklistJobsite (
                    $idDings: Int!,
            ) {
                removeBlacklistJobsite(
                id: $idDings,
                )
                {
                    ...JobsiteFragment
                    client {
                        ...ClientFragment
                    },
                    house {
                        ...HouseFragment
                    }
                }
            }`

    const result = await mutation(
        MUTATION, {
            idDings: jobsite.id
        }
    )

    return result?.data.removeBlacklistJobsite
}

export const fetchBookedJobsites = async (): Promise<Array<Jobsite>> => {

    const result = await query(gql`
        ${clientFragment}
        ${houseFragment}
        ${jobsiteFragment}
        query GetBookedJobsites {
            bookedJobsites {
                ...JobsiteFragment
                client {
                    ...ClientFragment
                }
                house {
                    ...HouseFragment
                }
            }
        }`
    )

    return result.data.bookedJobsites
}

export const getJobsiteDescription = (jobsite: Jobsite, prefixOnly:boolean = false): string => {
    const result = []

    if (jobsite.client) {
        result.push(jobsite.client.name)
    }
    if (jobsite.house) {
        result.push(jobsite.house.name)
    }

    if (!prefixOnly) {
        result.push(jobsite.name)
    }

    return result.join(" > ")

}

export const getShortJobsiteDescription = (jobsite: Jobsite, prefixOnly:boolean = false): string => {
    const result = []

    if (jobsite.client) {
        result.push(jobsite.client.abbreviation)
    }
    if (jobsite.house) {
        result.push(jobsite.house.abbreviation)
    }

    if (!prefixOnly) {
        result.push(jobsite.abbreviation)
    }

    return result.join(" > ")

}


export const getJobsiteShortestCompleteDescription = (jobsite: Jobsite, prefixOnly:boolean = false): string => {
    const result = []

    if (jobsite.client) {
        result.push(jobsite.client.abbreviation)
    }
    if (jobsite.house) {
        result.push(jobsite.house.abbreviation)
    }
    if(jobsite.additionalAmenImportantAbbreviations.length>0){
        result.push(jobsite.additionalAmenImportantAbbreviations.join(" > "))
    }

    if (!prefixOnly) {
        result.push(jobsite.abbreviation)
    }

    return result.join(" > ")
}

// compare by id, not by object equality - lists contain jobsite objects from different sources
export const findJobsiteInList = (list: Array<Jobsite>, jobsite: Jobsite) => list.find(j => j.id === jobsite.id)
export const includesListJobsite = (list: Array<Jobsite>, jobsite: Jobsite) => list.some(j => j.id === jobsite.id)
export const indexOfJobsiteInList = (list: Array<Jobsite>, jobsite: Jobsite) => {
    const j = findJobsiteInList(list, jobsite)
    return j ? list.indexOf(j) : -1
}

export const getEmployeeJobsites = async () => {
    const [favorite,secondChoice,booked] : [Jobsite[],Jobsite[],Jobsite[]]= await Promise.all([
        fetchFavoriteJobsites(),
        fetchSecondChoiceJobsites(),
        fetchBookedJobsites()
    ])

    return {favorite, secondChoice, booked}
}
export const getEmployeeJobsiteCategorySegments = async () : Promise<JobsiteCategorySegment[]>  => {
    const {favorite, secondChoice, booked} = await getEmployeeJobsites()
    return [
        {category: JobsiteCategory.Favorite, jobsites: favorite},
        {category: JobsiteCategory.Alternative, jobsites: secondChoice},
        {category: JobsiteCategory.Booked, jobsites: booked}
    ]
}

export interface JobsiteCategorySegment {
    category: JobsiteCategory,
    jobsites: Jobsite[]
}

export const splitByJobsiteCategory = (jobsites: Jobsite[]) => {
     const favorite = {category: JobsiteCategory.Favorite, jobsites: [] as Jobsite[]}
    const alternative = {category: JobsiteCategory.Alternative, jobsites: [] as Jobsite[]}
    const booked = {category: JobsiteCategory.Booked, jobsites: [] as Jobsite[]}
    const other = {category: JobsiteCategory.Other, jobsites: [] as Jobsite[]}

    jobsites.forEach(jobsite => {
        if (jobsite.isFavorite) {
            favorite.jobsites.push(jobsite)
        } else if (jobsite.isAlternative) {
            alternative.jobsites.push(jobsite)
        } else if (jobsite.isBooked) {
            booked.jobsites.push(jobsite)
        } else {
            other.jobsites.push(jobsite)
        }
    })

    return {favorite, alternative, booked, other}

    // const result = [favorite, alternative, booked, other]

    // return result.filter(segment => segment.jobsites.length > 0)
}

export const pluralizedJobsiteAmount = (amount: number, lowercase = false) => {
    const word =`${lowercase ? 's' : 'S'}tation`
    return pluralize(amount, word, `${word}en`)
}

export const pluralizedJobsiteLabel = (amount: number, lowercase = false) => {
    const word =`${lowercase ? 's' : 'S'}tation`
    return pluralizeUnitOnly(amount, word, `${word}en`)
}

export const jobsiteSummaryLabel = (jobsites: Jobsite[]) : string => {

    const favoriteCount = jobsites.filter(j => j.isFavorite).length
    const alternativeCount = jobsites.filter(j => j.isAlternative).length
    const remainingCount = jobsites.filter(j => !j.isAlternative && !j.isFavorite).length

    const components = [];

    if (favoriteCount > 0) {
        components.push(`${favoriteCount} Lieblings${(remainingCount + alternativeCount > 0) ? '-' : pluralizedJobsiteLabel(favoriteCount, true)}`)
    }
    if (alternativeCount > 0) {
        components.push(`${alternativeCount} Alternativ${(remainingCount > 0) ? '-' : pluralizedJobsiteLabel(alternativeCount, true)}`)
    }
    if (remainingCount > 0) {
        components.push(`${remainingCount} weitere ${pluralizedJobsiteLabel(remainingCount)}`)
    }

    const formatter = new Intl.ListFormat('de', { style: 'long', type: 'conjunction' });
    console.log(components, formatter.format(components))
    return formatter.format(components)
}

export const clientListLabel = (jobsites: Jobsite[]) : string => {

    // take houses where possible and clients from the rest
    let houses = jobsites.map((js) => js.house).filter((h):h is House => !!h)
    let clients = jobsites.filter((j) => !j.house).map(js => js.client)

    // unique
    houses = houses.filter((j, i) => houses.findIndex(j2 => j2.name === j.name) === i)
    clients = clients.filter((j, i) => clients.findIndex(j2 => j2.name === j.name) === i)
    
    const components = houses.map(h => h.abbreviation || '').concat(clients.map(c => c.abbreviation || ''))
    
    // const formatter = new Intl.ListFormat('de', { style: 'short', type: 'conjunction' });    
    // return formatter.format(components)
    return components.join(', ')
}
