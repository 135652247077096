import mitt, {Emitter} from 'mitt';
const emitter = mitt();

export enum GlobalEvents {
    AppStateChanged
}

export default function useEmitter(): Emitter<any> {
    return emitter;
}
