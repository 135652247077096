<script setup>
import { IonModal, IonToolbar, IonHeader, IonTitle, IonButtons, IonButton, IonIcon } from '@ionic/vue'
import { ref, watch } from 'vue'

const props = defineProps({
    modelValue: Boolean,
    title: String
})

const jonModal = ref()


const emit = defineEmits(['update:modelValue'])
const isOpen = ref(props.modelValue)

const onDidDismiss = () => {
}

const onWillDismiss = () => {
    dismiss()
}

const dismiss = () => {
    return new Promise(
        (resolve) => {
            jonModal.value.$el.onDidDismiss().then(resolve)
            isOpen.value = false
            emit('update:modelValue', false)
        }
    )

}

watch(() => props.modelValue, value => {
    isOpen.value = value
})

defineExpose({
    dismiss
})

</script>

<template>
    <ion-modal
        class="modal"
        :is-open="isOpen"
        :initial-breakpoint="1"
        :breakpoints="[0, 1]"
        v-on:willDismiss="onWillDismiss"
        v-on:didDismiss="onDidDismiss"
        ref="jonModal"
    >
        <ion-header>
            <ion-toolbar>
                <ion-title v-if="title">
                    {{ title }}
                </ion-title>
                <ion-buttons slot="end">
                    <ion-button
                        @click="dismiss"
                        class="button-close"
                        shape="round"
                        fill="solid"
                        color="light"
                    >
                        <ion-icon
                            color="secondary"
                            size="small"
                            src="/icons/x.svg"
                            slot="icon-only"
                        />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <div
            class="body"
        >
            <slot />
        </div>


    </ion-modal>
</template>

<style lang="scss">
.modal {
    --height: auto;

    ion-toolbar {
        --border-width: 0 !important;

        padding-top: calc(var(--custom-spacing-app-content-padding-vertical) / 1.5) !important;
        padding-left: var(--custom-spacing-app-content-padding-horizontal) !important;
        padding-right: calc(var(--custom-spacing-app-content-padding-horizontal) / 2) !important;

        ion-title {
            text-align: left;
            padding-left: 0;
        }

        ion-button {
            margin-left: 0;
            margin-right: 0;
            width: 2rem;
        }
    }

    .body {
        padding-top: var(--custom-spacing-app-content-padding-vertical);
        padding-left: var(--custom-spacing-app-content-padding-horizontal);
        padding-right: var(--custom-spacing-app-content-padding-horizontal);
        padding-bottom: var(--custom-spacing-app-content-padding-vertical);
        margin-bottom: env(safe-area-inset-bottom, 0);
    }

    &.no-padding-left .body {
        padding-left: 0;
    }

    &.no-padding-right .body {
        padding-right: 0;
    }
}
</style>
