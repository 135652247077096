<template>
<div>
    <RichResponseHeadline
        :title="headline"
    />
    <CalendarListItem
        v-for="d in days"
        :key="d.date"
        :date="d.date"
        :day-data="d"
    />
</div>
</template>

<script setup lang="ts">
import RichResponseHeadline from "./components/RichResponseHeadline.vue";
import CalendarListItem from "./components/CalendarListItem.vue";
import date from "@/helper/datetime/date";
import { computed, ref } from "vue";
import { Day } from "@/graphql/generated/graphql";
import { kapitelDateString } from "@/graphql/kapitelTypes";
import { fetchDaysForDateRange } from "@/helper/day";
import { DataLoader } from "@/helper/dataLoader";
import { generateCalendarDate } from '@/helper/calendar/calendarDate';

const props = defineProps<{
    initData: kapitelDateString // week start
}>()

const days = ref<Day[]>([])
const dates = ref<kapitelDateString[]>([])

let iterator = -1
while(iterator++ < 6) {
    dates.value.push(date.addDays(props.initData, iterator))
}

const headline = computed(() => {
    const from = dates.value[0]
    const to = dates.value[dates.value.length - 1]
    
    const calendarDateFrom = generateCalendarDate(from)
    const calendarDateTo = generateCalendarDate(to)

    const monthDiffers = calendarDateFrom.month !== calendarDateTo.month
    const yearDiffers = calendarDateFrom.year !== calendarDateTo.year

    const weekStr = `KW ${calendarDateFrom.week}`
    let yearAndMonthStr

    if (monthDiffers && yearDiffers) {
        yearAndMonthStr = `${calendarDateFrom.labelMonth} ${calendarDateFrom.year} / ${calendarDateTo.labelMonth} ${calendarDateTo.year}`
    }
    else if (monthDiffers) {
        yearAndMonthStr = `${calendarDateFrom.labelMonth} / ${calendarDateTo.labelMonth} ${calendarDateFrom.year}`
    }
    else {
        yearAndMonthStr = `${calendarDateFrom.labelMonth} ${calendarDateFrom.year}`
    }

    return `${weekStr} ${yearAndMonthStr}`
})

const loader = new DataLoader(
    async () => {
        const from = dates.value[0]
        const to = dates.value[dates.value.length - 1]

        days.value = await fetchDaysForDateRange(from, to)
    }
)
loader.load()
</script>