<template>
<div
    @click="onClick"
>
    <RichResponseHeadline
        :title="headline"
    />
    <RichResponseListItem
        :title="`${pluralizeUnitOnly(renderSet.booking.booked, 'Dienst', 'Dienste')} geplant`"
        :icon="renderSet.booking.booked.toString()"
        icon-type="text"
        :text="renderSet.status === PlanningMode.BookingPlanning ? `Planungsziel sind ${renderSet.planning.target} Tage` : 'Planung noch nicht gestartet'"
    >
        <template v-slot:end>
            <ProgressCircle
                :stroke-width="15"
                track-color="grey-tint-1"
                stroke-color="black"
                :value=".7"
                :glow="false"
            />
        </template>
    </RichResponseListItem>
    <RichResponseListItem
        :title="`${pluralizeUnitOnly(renderSet.availability.amount, 'Verfügbarer Tag', 'Verfügbare Tage')}`"
        :icon="renderSet.availability.amount.toString()"
        icon-type="text"
        icon-color="green"
        :text="`im ${renderSet.availability.shifts.toSorted(sortShiftTypes).join(', ')}`"
    />
    <RichResponseListItem
        :title="`${pluralizeUnitOnly(renderSet.vacations, 'Tag', 'Tage')} Urlaub`"
        :icon="renderSet.vacations.toString()"
        icon-type="text"
        icon-color="red"
        text=""
    />
    <RichResponseListItem
        :title="`${pluralizeUnitOnly(renderSet.planning?.jobsites?.length, 'Station', 'Stationen')} ausgewählt`"
        :icon="renderSet.planning?.jobsites?.length.toString()"
        icon-type="text"
        icon-color="grey"
        :text="`${jobsiteSummaryLabel(renderSet.planning.jobsites)} (${clientListLabel(renderSet.planning.jobsites)})`"
    />
</div>
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import RichResponseHeadline from "./components/RichResponseHeadline.vue"
import ProgressCircle from '@/components/ProgressCircle.vue'
import date from "@/helper/datetime/date"
import { Ref, computed, ref } from "vue"
import { Jobsite, PlanningMode, PlanningStatus, ShiftType } from "@/graphql/generated/graphql";
import { clientListLabel, jobsiteSummaryLabel } from '@/helper/jobsite';
import { kapitelDateString } from '@/graphql/kapitelTypes';
import { fetchPlanningStatus } from '@/helper/autopilotStatus';
import { generateCalendarMonth } from '@/helper/calendar/calendarMonth';
import { getLabelForPlanningStatus } from '@/helper/planningMode';
import { formatMonthFromKapitelDate, getYearFromKapitelDate } from '@/helper/datetime/date';
import { pluralizeUnitOnly } from '@/helper/amountFormatter';
import { sortShiftTypes } from '@/helper/shiftTypes';


const props = defineProps<{
    initData: kapitelDateString
}>()

const emit = defineEmits(['details'])
const onClick = () => {
    emit("details", {
        type: "PlanningMonth",
        initData: {
            month: props.initData,
        }
    })
}

const headline = computed(() => {
    return `Planungsstatus ${formatMonthFromKapitelDate(props.initData)} ${getYearFromKapitelDate(props.initData)}`
})

const status : Ref<PlanningStatus|undefined> = ref(undefined);

const renderSet = computed(() => {
    const s = status.value
    const renderSet = {
        month: date.formatMonthFromKapitelDate(props.initData),
        status: PlanningMode.Uncontracted,
        monthIsStatusLabel: '',        
        availability: {
            shifts: [] as ShiftType[],
            amount: 0
        },
        vacations: 0,
        booking: {
            booked: 0,
            jobsites: [] as Jobsite[],
            completed: false
        },
        planning: {            
            missing: 0,
            target: 0,
            targetReached: false,
            jobsites: [] as Jobsite[],
            favoriteJobsites: [] as Jobsite[],
            nonFavoriteJobsites: [] as Jobsite[],
        }
    }

    if (!s) {
        return renderSet
    }

    renderSet.status = s.planningMode
    renderSet.monthIsStatusLabel = getLabelForPlanningStatus(s, true)
    renderSet.vacations = s.vacations

    if (s.planningMode !== PlanningMode.Uncontracted) {
        renderSet.availability.shifts = s.availabilityShifts
        renderSet.availability.amount = s.availabilities

        renderSet.booking.booked = s.bookings
        renderSet.booking.jobsites = s.bookedJobsites
        renderSet.booking.completed = s.bookingCompleted
    }
        
    if (s.planningMode === PlanningMode.BookingPlanning && s.autopilotStatus) {
        const jobsites = s.autopilotStatus.autopilotPreferences.autopilotJobsites.map(ajs => ajs.jobsite)
        renderSet.planning = {            
            missing: s.autopilotStatus.missingBookings,
            target: s.autopilotStatus.bookingTarget,
            targetReached: s.autopilotStatus.bookingHoursTargetReached,
            jobsites,
            favoriteJobsites: jobsites.filter(j => j.isFavorite),
            nonFavoriteJobsites: jobsites.filter(j => !j.isFavorite),
        }
    }

    return renderSet
}) 

fetchPlanningStatus(generateCalendarMonth(props.initData)).then((r) => {
    status.value = r
})


</script>

<style lang="scss" scoped>
.progress-circle {
    width: 2.5em;
}
</style>
