<template>
    <ion-page
        class="planning-dashboard-view"
    >
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title>Planung</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="no-padding-top">

            <!-- AZK -->

            <Skeleton
                v-if="employeeLoader.pending.value"
                :items="['margin', 'title', 'block--height-9rem']"
            />
            <ion-card
                class="custom-ion-card-floating"
                v-else-if="userAZK"
            >
                <ion-card-header>
                    <ion-card-title>
                        Aktuelles AZK
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <div class="azk-info">
                        <Speedometer
                            :min="userAZKGraphMin"
                            :max="userAZKGraphMax"
                            :value="speedometerValue"
                        />
                        <div class="azk-current">
                            <div>
                                <span v-if="userAZKValue == 0">&plusmn;</span>
                                <span v-else-if="userAZKValue > 0">+</span>
                                <span>{{ userAZKValue }}</span>
                            </div>
                            <div>{{ pluralizeUnitOnly( userAZKValue, "Dienst", "Dienste") }}</div>
                        </div>
                    </div>
                    <p class="text-center azk-date-info">
                        Berechnet bis einschließlich Ende {{ date.formatKapitelDate(userAZK.validMonth, "MMMM yyyy") }}.
                    </p>
                </ion-card-content>
            </ion-card>

            <!-- Planning -->

            <p @click="onLoadPast" v-if="useAuthStore().isConsultant()" style="font-weight: bold; text-align: center">
                <IonIcon :icon="chevronUpCircle"></IonIcon>
                Vergangene Monate anzeigen
                <IonIcon :icon="chevronUpCircle"></IonIcon>
            </p>

            <Skeleton
                v-if="dataLoader.pending.value"
                :items="['title--width-20%', 'margin', 'block--height-5rem', 'margin--height-0.2rem', 'block--height-5em', 'margin--height-0.2rem', 'block--height-5em', 'margin--height-0.2rem', 'block--height-5em']"
            />
            <ion-card
                v-else
                v-for="yearRenderItem in yearsRenderSet"
                :key="yearRenderItem.year"
                class="custom-ion-card-floating"
            >
                <ion-card-header>
                    <ion-card-title>
                        {{ yearRenderItem.year }}
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <ion-list>
                        <ion-item
                            v-for="(monthRenderItem, index) in yearRenderItem.months"
                            :key="monthRenderItem.month"
                            class="month-list-item"
                            :class="{'disabled': !monthRenderItem.onClick}"
                            :detail="!!monthRenderItem.onClick"
                            detailIcon="/icons/caret-right.svg"
                            v-on:click="monthRenderItem.onClick"
                            :lines="index === yearRenderItem.months.length - 1 ? 'none' : undefined"
                        >
                            <PlanningProgressCircle
                                slot="start"
                                :amount="monthRenderItem.amount"
                                :target-amount="monthRenderItem.targetAmount"                                
                                :mode="monthRenderItem.isPlanning ? PlanningMode.BookingPlanning : PlanningMode.AvailabilityPlanning"
                            >
                            </PlanningProgressCircle>
                            <ion-label>
                                <h3>
                                    <span>{{ monthRenderItem.month }}</span>
                                    <JobsiteSelectionChip
                                        v-if="monthRenderItem.isPlanning && !monthRenderItem.jobsiteDetails.justAllFavorites && monthRenderItem.jobsiteDetails.numFavoriteJobsites > 0" 
                                        :mode="SimplifiedJobsiteCategory.Favorite"
                                        :amount="monthRenderItem.jobsiteDetails.numFavoriteJobsites"    
                                    ></JobsiteSelectionChip>
                                    <JobsiteSelectionChip 
                                        v-if="monthRenderItem.isPlanning && monthRenderItem.jobsiteDetails.numAdditionalJobsites > 0" 
                                        :mode="SimplifiedJobsiteCategory.Additional"
                                        :amount="monthRenderItem.jobsiteDetails.numAdditionalJobsites"    
                                    ></JobsiteSelectionChip>                                    
                                </h3>
                                <p>{{ monthRenderItem.autopilotStatusLabel }}</p>
                                <p>{{ monthRenderItem.numBookingsLabel }}</p>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
            </ion-card>

            <ConfirmPlanningDateChangeModal
                v-model="isConfirmPlanningDateChangeModalActive"
                :begin-date="planningDaysSummary.begin"
                :end-date="planningDaysSummary.until"
                :summary="planningDaysSummary"
                v-on:save="onChangePlanningThreshold"
            />
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,    
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,    
    IonList,
    IonItem,
    IonLabel,
    onIonViewWillEnter,
    IonIcon
} from '@ionic/vue'
import {computed, Ref, ref} from "vue";
import {useRouter} from 'vue-router'
import Speedometer from '@/components/Speedometer.vue'
import {DataLoader} from "@/helper/dataLoader.js";
import Skeleton from "@/components/Skeleton.vue";
import useEmitter, {GlobalEvents} from "@/helper/emitter";
import {AutopilotStatus, Booking, Employee, EmployeeAzk, EmployeeTargetAzk, Absence, Availability, PlanningMode} from "@/graphql/generated/graphql";
import date from "../../helper/datetime/date";
import {AVERAGE_SHIFT_DURATION} from "@/helper/globalShiftTimes";
import {pluralizeUnitOnly, pluralize} from '@/helper/amountFormatter';
import {
    fetchAutopilotStatus,
    fetchCurrentAutopilotStatus,
    getAutopilotStatusSummary,
    sorter
} from "@/helper/autopilotStatus";
import { fetchAvailabilityPlanningDates, setAvailabilityPlanningDates } from "@/helper/availabilityPlanningDates"
import {fetchDayObjectsForDateRange, yieldEffectiveBookings, yieldEffectiveAvailabilities, yieldEffectiveAbsences} from "@/helper/day"
import {generateCalendarMonth} from "@/helper/calendar/calendarMonth"
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {useEmployeeStore} from "@/store/employee";
import {onIonViewEnteredAndCapicatorAppActivate} from "@/helper/onIonViewEnteredAndCapicatorAppActivate";
import {hasShifts} from "@/helper/availability";
import ConfirmPlanningDateChangeModal from "@/views/components/ConfirmPlanningDateChangeModal.vue"
import { SimplifiedJobsiteCategory } from '@/helper/jobsiteCategory';
import JobsiteSelectionChip from '@/components/JobsiteSelectionChip.vue';
import PlanningProgressCircle from '@/components/PlanningProgressCircle.vue';
import {useAuthStore} from "@/store/auth";
import {caretUpCircle, chevronUpCircle} from "ionicons/icons";

const router = useRouter()
const emitter = useEmitter()

const isConfirmPlanningDateChangeModalActive = ref(false)

/**
 * user
 */

const user : Ref<Employee|undefined> = ref()
const userAZK : Ref<EmployeeAzk|undefined> = computed(() => user.value?.aZK || undefined)
const userAZKTarget : Ref<EmployeeTargetAzk|undefined> = computed(() => user.value?.targetAZK || undefined)
// On purpose we floor the actual value and ceil the target value
const userAZKValue = computed(() => Math.floor((userAZK.value?.value || 0) / AVERAGE_SHIFT_DURATION))
const userAZKTargetValue = computed(() => Math.ceil((userAZKTarget.value?.value || 0) / AVERAGE_SHIFT_DURATION))

const speedometerValue: Ref<number> = computed(()=>{
    if(userAZKValue.value){
        if(userAZKValue.value > userAZKGraphMax.value){
            return userAZKGraphMax.value
        }
        if(userAZKValue.value < userAZKGraphMin.value){
            return userAZKGraphMin.value
        }
        return userAZKValue.value
    }
    return 0
})
const userAZKGraphMin: Ref<number> = computed(() => {
    return userAZKTargetValue.value ? userAZKTargetValue.value - 3: -3
})
const userAZKGraphMax: Ref<number> = computed(() => {
    return userAZKTargetValue.value ? userAZKTargetValue.value + 3: 3
})

const employeeLoader = new DataLoader(async () => user.value = await useEmployeeStore().getEmployee())
employeeLoader.load()
onIonViewWillEnter(() => {
    employeeLoader.load()    
})

/**
 * months render set
 */

const numUnplannedMonths = 3
const monthsRenderSet = computed(() => {
    const result = autopilotStatus.value.map(singleStatus => {
        const summary = getAutopilotStatusSummary(singleStatus)

        const numTarget = singleStatus.bookingTarget
        const numBooked = singleStatus.booked
       
        return {
            kapitelDate: singleStatus.month,
            onClick: () => router.push('/planning/' + singleStatus.month),
            month: date.formatKapitelDate(singleStatus.month, "MMMM"),
            year: date.formatKapitelDate(singleStatus.month, "yyyy"),
            isPlanning: true,      
            amount: summary.booked,   
            targetAmount: summary.bookingTarget,   
            numBookingsLabel: summary.bookingHoursTargetReached
                ? `${numBooked} ${pluralizeUnitOnly(numBooked, 'Dienst', 'Dienste')} geplant`
                : `${numBooked} von ${numTarget} ${pluralizeUnitOnly(numTarget, 'Dienst', 'Diensten')} geplant`,
            
            autopilotStatusLabel: summary.bookingHoursTargetReached
                ? 'Dienstplanung abgeschlossen'
                : 'In Dienstplanung',
            jobsiteDetails: {            
                numFavoriteJobsites: summary.jobsiteSummary.favoriteCount,
                numAdditionalJobsites: summary.jobsiteSummary.alternativeCount + summary.jobsiteSummary.remainingCount,
                justAllFavorites: summary.jobsiteSummary.favoriteOnly
            }
        };
    })

    if (autopilotStatus.value.length) {
        const latestAutopilotStatus = autopilotStatus.value[autopilotStatus.value.length - 1]

        for (let i = 1; i <= numUnplannedMonths; i++) {
            const unplannedMonth = date.addMonths(latestAutopilotStatus.month, i)
            const numAvailabilities = availabilitiesByMonth.value[unplannedMonth]?.filter(hasShifts).length || 0
            const onClickHandler = i === 1
                ? () => isConfirmPlanningDateChangeModalActive.value = true
                : undefined

            result.push({
                kapitelDate: unplannedMonth,
                onClick: onClickHandler,
                month: date.formatKapitelDate(unplannedMonth, "MMMM"),
                year: date.formatKapitelDate(unplannedMonth, "yyyy"),                
                isPlanning: false,
                amount: numAvailabilities,
                targetAmount: undefined,
                numBookingsLabel: pluralize(numAvailabilities, 'Verfügbarkeit', 'Verfügbarkeiten', 'keine') + ' angegeben',                
                autopilotStatusLabel: 'In Verfügbarkeitsplanung',
                jobsiteDetails: undefined
            })
        }
    }

    return result
})

const yearsRenderSet = computed(() => {
    return monthsRenderSet.value.reduce((acc, current) => {
        if (!acc.find((item) => item.year === current.year)) {
            acc.push({
                year: current.year,
                months: []
            })
        }
        acc.find((item) => item.year === current.year).months.push(current)
        return acc
    }, [])
})

const additionalPastMonthsToShow : Ref<kapitelDateString[]> = ref([])
const autopilotStatus:Ref<AutopilotStatus[]> = ref([])

const currentMonth = () => date.startOfMonth(date.getToday())

const dataReset = () => {
    planningThreshold.value = undefined
    daysBeforeAutoPlan.value = undefined
    autopilotStatus.value = []
    bookingsByMonth.value = {}
    availabilitiesByMonth.value = {}
    absencesByMonth.value = {}
    dataLoader.reset()
}

/**
 * booking / availability status
 */

interface BookingsByMonth {
    [key: kapitelDateString]: Booking[]
}
interface AvailabilitiesByMonth {
    [key: kapitelDateString]: Availability[]
}
interface AbsencesByMonth {
    [key: kapitelDateString]: Absence[]
}

const bookingsByMonth = ref<BookingsByMonth>({})
const availabilitiesByMonth = ref<AvailabilitiesByMonth>({})
const absencesByMonth = ref<AbsencesByMonth>({})

/**
 * Availability Planning Dates
 * needed for ConfirmPlanningDateChangeModal
 */

const fetchAndParseAvailabilityPlanningDates = async () => {
}

const planningThreshold = ref()
const daysBeforeAutoPlan = ref()
const planningDaysSummary = computed(() => {
    if (!autopilotStatus.value.length) {
        return {}
    }

    const latestAutopilotStatus = autopilotStatus.value[autopilotStatus.value.length - 1]
    const unplannedMonth = date.addMonths(latestAutopilotStatus.month, 1)

    return {
        begin: date.startOfMonth(unplannedMonth),
        until: date.endOfMonth(unplannedMonth),
        bookings: bookingsByMonth.value[unplannedMonth] || [],
        absences: absencesByMonth.value[unplannedMonth] || [],
        availabilities: availabilitiesByMonth.value[unplannedMonth] || [],
    }
})

const onChangePlanningThreshold = () => {
    isConfirmPlanningDateChangeModalActive.value = false

    setAvailabilityPlanningDates({
        planningThreshold: planningDaysSummary.value.begin as string,
        daysBeforeAutoPlan: daysBeforeAutoPlan.value
    }).then(dataLoader.reload)
}

// init

const dataLoader = new DataLoader([
    async () => {
        const planningDates = await fetchAvailabilityPlanningDates()
        planningThreshold.value = planningDates.planningThreshold
        daysBeforeAutoPlan.value = planningDates.daysBeforeAutoPlan
    },
    async () => {
        // reset
        autopilotStatus.value = []

        // fetch current (future) and past if needed
        await new DataLoader([
            async () => {
                const currentStatus = await fetchCurrentAutopilotStatus(false)
                autopilotStatus.value.push(...currentStatus)
            },
            ...additionalPastMonthsToShow.value.map(month => {
                return async () => {
                    const monthStatus = await fetchAutopilotStatus(generateCalendarMonth(month))
                    if (monthStatus) {
                        autopilotStatus.value.push(monthStatus)
                    }
                }
            })
        ]).load()

        // to sorted
        autopilotStatus.value.sort(sorter)

        // fetch for all status + for additional x unplanned month
        const monthsToFetch = autopilotStatus.value.map(status => status.month)
        const latestMonth = autopilotStatus.value[autopilotStatus.value.length - 1]?.month ?? currentMonth()
        for (let i = 1; i <= numUnplannedMonths; i++) {
            monthsToFetch.push(date.addMonths(latestMonth, i))
        }


        // fetch days
        await Promise.all(
            monthsToFetch.map((month) => {
                    return (async () => {
                        const calendarMonth = generateCalendarMonth(month)
                        const days = await fetchDayObjectsForDateRange(calendarMonth.begin, calendarMonth.until)

                        bookingsByMonth.value[month] = yieldEffectiveBookings(days)
                        availabilitiesByMonth.value[month] = yieldEffectiveAvailabilities(days)
                        absencesByMonth.value[month] = yieldEffectiveAbsences(days)
                    })()
                }
            )
        )
    }
])

// initial load
dataLoader.load()

// refresh
onIonViewWillEnter(dataLoader.load)
onIonViewEnteredAndCapicatorAppActivate(dataLoader.load)

emitter.on('availabilityPlanningDates:mutated', dataLoader.load)

emitter.on(GlobalEvents.AppStateChanged, dataReset)

const onLoadPast = () => {
    const oldestMonth = additionalPastMonthsToShow.value[0] || currentMonth()
    const x = 3
    for (let i = 1; i <= x; i++) {
        additionalPastMonthsToShow.value.unshift(date.subMonths(oldestMonth, i))
    }
    dataLoader.load()
}

</script>

<style lang="scss">
.planning-dashboard-view {
    .azk-date-info {
        margin-top: 1em;
    }

    .azk-info {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 1rem;
        width: 100%;

        .speedometer {
            flex: 0 0 50%;
            width: auto;
        }

        > div:first-child {
            margin-right: 1rem;
        }

        > .azk-current, .azk-target {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            > div:first-child {
                height: 4rem;
                font-size: var(--custom-font-size-extralarge);
                font-weight: var(--custom-font-weight-extrabold);
                color: var(--ion-color-dark);
            }
        }
    }


    .month-list-item {
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}
</style>
