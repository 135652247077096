<template>
    <template v-if="loader.pending.value">
        <!-- Booking Skeleton -->
        <Skeleton
            v-if="expectedContent === 'booking'"
            :items="['block--height-10rem', 'margin', 'title', 'margin', 'block--height-10rem']"
        ></Skeleton>

        <!-- Availability Skeleton -->
        <Skeleton
            v-else
            :items="['margin', 'title', 'margin', 'block']"
        ></Skeleton>
    </template>
    <template v-else>
        <!-- Booking Details -->
        <BookingDetails
            v-if="booking"
            :booking="booking"
        />

        <!-- Absence Editor -->
        <!-- Uncomment if needed
        <AbsenceEditor
            v-else-if="absence"
            :date="calendarDate.id"
            :absence="absence"
            @update="loader.reload"
        /> -->

        <!-- Availability or Absence Editor -->
        <template v-else>
            <AvailabilityAbsenceEditor
                :availability="availability"
                :absence="absence"
                :date="calendarDate.id"
            />
            <!-- Uncomment if needed
            <AbsenceEditor
                :date="calendarDate.id"
                :absence="undefined"
                @update="loader.reload"
            /> -->
        </template>
    </template>
</template>

<script setup lang="ts">
import { ref, computed, defineProps, defineExpose } from 'vue';
import { useRoute } from 'vue-router';
import { generateCalendarDateFromKapitelDateString } from '@/helper/calendar/calendarDate';
import { generateAvailability } from '@/helper/availability';
import { fetchDayForDate } from '@/helper/day';
import BookingDetails from '@/views/CalendarDetail/components/BookingDetails.vue';
import AvailabilityAbsenceEditor from '@/components/AvailabilityAbsenceEditor.vue';
import { DataLoader } from '@/helper/dataLoader';
import Skeleton from '@/components/Skeleton.vue';

const props = defineProps({
    dateId: String,
    expectedContent: {
        type: String,
        required: false,
        default: undefined
    },
});

const availability = ref();
const booking = ref();
const absence = ref();
const route = useRoute();
const calendarDate = generateCalendarDateFromKapitelDateString(props.dateId || route.params.dateId);
const kapitelDate = computed(() => calendarDate.id);

const loader = new DataLoader([
    async () => {
        const day = await fetchDayForDate(
            kapitelDate.value,
            'availability {effectiveJobsites {id}, differsFromTemplate}'
        );
        availability.value = day.availability || generateAvailability(kapitelDate.value);
        absence.value = day.absence || undefined;
        booking.value = day.booking || undefined;
    }
]);

const reload = () => {
    loader.load();
};
reload();

defineExpose({
    reload
});
</script>
