import { kapitelTimeString as kapitelTimeStringType, kapitelDateTimeString as kapitelDateTimeStringType } from "@/graphql/kapitelTypes";
import { Ref, computed, ref } from "vue";
import datetimeHelper from "./datetime/datetime";
import dateHelper from '@/helper/datetime/date'
import timeHelper from '@/helper/datetime/time'

const seconds = ref(0);
const minutes = ref(0);
const hours = ref(0);

const updateClock = () => {
    const now = new Date()
    
    seconds.value = now.getSeconds()

    const newMinutes = now.getMinutes()
    if (minutes.value !== newMinutes) {
        minutes.value = newMinutes
    }

    const newHours = now.getHours()
    if (hours.value !== newHours) {
        hours.value = newHours
    }

    // console.log("it is %d:%d:%d", hours.value, minutes.value, seconds.value)
};

const kapitelTimeString : Ref<kapitelTimeStringType> = computed(() => {
    return timeHelper.parseHoursAndMinutes(hours.value, minutes.value)
})

const kapitelDateTimeString : Ref<kapitelDateTimeStringType> = computed(() => {
    const date = new Date()
    date.setHours(hours.value, minutes.value, 0,0)

    const today = dateHelper.getToday()

    return datetimeHelper.convertDateAndTime2DateTime(today, kapitelTimeString.value)
})

let initialized = false
const ensureInit = () => {
    if (initialized) {
        return
    }

    updateClock()
    setInterval(updateClock, 1000)
    
    initialized = true
}

export const useClock = () => {
    ensureInit()

    return {
        seconds,
        minutes,
        hours,
        kapitelTimeString,
        kapitelDateTimeString
    }
};
