
<script setup lang="ts">

import { IonButton, IonTitle, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonList, IonListHeader, IonModal, IonPage, IonRadio, IonToolbar, IonLabel, modalController, onIonViewWillEnter } from '@ionic/vue'
import { computed, ref, Ref} from "vue";
import { fetchBookedJobsites, fetchFavoriteJobsites, fetchSecondChoiceJobsites, getEmployeeJobsites, includesListJobsite, indexOfJobsiteInList } from "@/helper/jobsite"
import { informationCircleOutline } from "ionicons/icons"
import { generateCalendarMonth } from "@/helper/calendar/calendarMonth"
import { AutopilotJobsite, AutopilotJobsiteSource, Jobsite } from "@/graphql/generated/graphql"
import { DataLoader } from "@/helper/dataLoader"
import { addJobsiteToAutopilot, AutopilotPreferenceObject, fetchAutopilotPreferenceObject, removeJobsiteFromAutopilot } from "@/helper/autopilotPreference"
import JobsiteSelectorModal from "@/views/components/JobsiteSelectorModal.vue"
import router from "@/router"
import { JobsiteCategory, kapitelDateString } from '@/graphql/kapitelTypes';
import { getLabelForJobsiteCategory, getIconForJobsiteCategory, sortedJobsiteCategories } from '@/helper/jobsiteCategory';


import InlineSVG from '@/components/InlineSVG.vue';

/**
 * properties & local variables
 */

const props = defineProps<{
    monthId: kapitelDateString
}>()
const calendarMonth = generateCalendarMonth(props.monthId)

const favoriteJobsites : Ref<Array<Jobsite>> = ref([]);
const secondChoiceJobsites : Ref<Array<Jobsite>> = ref([]);
const bookedJobsites : Ref<Array<Jobsite>> = ref([]);

const autopilotPreference : Ref<AutopilotPreferenceObject | undefined> = ref()

const initialDataLoader = new DataLoader([
    async () => {
        autopilotPreference.value = await fetchAutopilotPreferenceObject(calendarMonth)
        autopilotPreference.value?.autopilotJobsites.forEach((autopilotJobsite: AutopilotJobsite) => {
            const jobsite = autopilotJobsite.jobsite
            listNewJobsite(jobsite, true, true)
        })
    },
    async () => {        
        const {favorite,secondChoice, booked} = await getEmployeeJobsites()
        
        favoriteJobsites.value = favorite
        favoriteJobsites.value.forEach(jobsite => listNewJobsite(jobsite, false, true))

        secondChoiceJobsites.value = secondChoice
        secondChoiceJobsites.value.forEach(jobsite => listNewJobsite(jobsite, false, true))

        bookedJobsites.value = booked
        bookedJobsites.value.forEach(jobsite => listNewJobsite(jobsite, false, true))    
    }
])

onIonViewWillEnter(initialDataLoader.load)
initialDataLoader.load()

const jobsiteAddModalIsOpen = ref(false)

/**
 * jobsite list handling
 */

const listedJobsites : Ref<Array<Jobsite>> = ref([])
const selectedJobsites : Ref<Array<Jobsite>> = ref([])

const listNewJobsite = (jobsite: Jobsite, selectedState = true, silent = false) => {
    if (!includesListJobsite(listedJobsites.value, jobsite)) {
        listedJobsites.value.push(jobsite)
    }
    if (selectedState) {
        selectJobsite(jobsite, true, silent)
    }
}
const isSelected = (jobsite: Jobsite) => includesListJobsite(selectedJobsites.value, jobsite)
const selectJobsite = (jobsite: Jobsite, state = true, silent = false) => {
    const arr = selectedJobsites.value
    const selected =  includesListJobsite(arr, jobsite)
    if (state && !selected) {
        arr.push(jobsite)
        if (!silent) {
            addJobsiteToAutopilot(calendarMonth, jobsite)
        }
    } else if (!state && selected) {
        arr.splice(indexOfJobsiteInList(arr, jobsite),1)
        if (!silent) {
            removeJobsiteFromAutopilot(calendarMonth, jobsite)
        }
    }
}

/**
 * render sets / UI
 */

const getJobsiteCategory = (jobsite: Jobsite) : JobsiteCategory => {
    switch (true) {
        case includesListJobsite(favoriteJobsites.value, jobsite): return JobsiteCategory.Favorite
        case includesListJobsite(secondChoiceJobsites.value, jobsite): return JobsiteCategory.Alternative
        case includesListJobsite(bookedJobsites.value, jobsite): return JobsiteCategory.Booked
        default: return JobsiteCategory.Other
    }
}
const listedJobsiteSortedRenderSets = computed(() => {
    return listedJobsites.value
        .map(jobsite => ({
            jobsite,
            label: jobsite.name || jobsite.abbreviation || '(fehlt)',
            clientLabel: jobsite.client?.abbreviation,
            houseLabel: jobsite.house?.abbreviation,
            additionalImportantLabel: jobsite.additionalAmenImportantAbbreviations ? jobsite.additionalAmenImportantAbbreviations:[],
            category: getJobsiteCategory(jobsite)
        }))
        .sort((a, b) =>
            a.label.localeCompare(b.label, undefined, {numeric: true, sensitivity: 'base'})
        )
})
const jobsiteCategoriesRenderSets = computed(() => {
    return sortedJobsiteCategories
        .map((category) => { 
            const jobsiteRenderSets = listedJobsiteSortedRenderSets.value.filter(jobsiteRS => jobsiteRS.category === category)            
            return {
                category,
                label: getLabelForJobsiteCategory(category),
                icon: getIconForJobsiteCategory(category),
                jobsiteRenderSets,
                getSelectedJobsites() { return jobsiteRenderSets.map(jobsiteRS => jobsiteRS.jobsite).filter(isSelected) },
                getJobsiteCountLabel() {
                    const selectedCount = this.getSelectedJobsites().length
                    const totalCount = this.jobsiteRenderSets.length

                    return totalCount === selectedCount ? totalCount : (selectedCount + '/' + totalCount)
                }                                
            }
        })
        .filter(categoryRS => categoryRS.jobsiteRenderSets.length > 0)
})

</script>


<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-button
                    color="secondary"
                    fill="clear"
                    v-on:click="router.go(-1)"
                    size="small"
                >
                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />
                </ion-button>
                <ion-title>
                    Stationen für {{ calendarMonth.format("MMMM") }}
                </ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <ion-list  v-if="!initialDataLoader.pending.value" >
                <template v-for="categoryRS in jobsiteCategoriesRenderSets" :key="categoryRS.categoryKey">
                    <ion-list-header>
                        {{ categoryRS.label }} 
                        <div
                            class="icon"
                            v-if="categoryRS.icon"
                        >
                            <InlineSVG
                                :src="categoryRS.icon"
                            />
                        </div>
                        ({{ categoryRS.getJobsiteCountLabel() }})
                    </ion-list-header>
                    <ion-item
                        v-for="jobsiteRS in categoryRS.jobsiteRenderSets"
                        :key="jobsiteRS.jobsite.id"
                        :class="'jobsiteItem ' + (isSelected(jobsiteRS.jobsite) ? '' : 'unselected')"
                        @click="(e) => { selectJobsite(jobsiteRS.jobsite, !isSelected(jobsiteRS.jobsite)) }"
                    >
                        <ion-label>
                            <h3>{{ jobsiteRS.label }}</h3>
                            <p>{{ jobsiteRS.clientLabel }}<span v-if="jobsiteRS.houseLabel">, {{ jobsiteRS.houseLabel }}</span><span v-if="jobsiteRS.additionalImportantLabel.length>0">, {{jobsiteRS.additionalImportantLabel.join(", ")}}</span></p>
                        </ion-label>
                        <ion-radio
                            :aria-label="jobsiteRS.label"
                            slot="start"
                            :checked="isSelected(jobsiteRS.jobsite)"
                            label-placement="end"
                            justify="start"

                        />
                        <ion-button
                            slot="end"
                            class="jobsiteInfo"
                            fill="clear"
                            color="secondary"
                            @click.stop="router.push('/jobsite-details/' + jobsiteRS.jobsite.id)"
                        >
                            <ion-icon
                                slot="icon-only"
                                :icon="informationCircleOutline"
                            />
                        </ion-button>
                    </ion-item>
                </template>
            </ion-list>
        </ion-content>

        <ion-footer
            class="ion-padding"
        >
            <ion-button
                color="secondary"
                expand="block"
                @click="jobsiteAddModalIsOpen = true"
            >
                Zusätzliche Station hinzufügen
            </ion-button>
        </ion-footer>

        <JobsiteSelectorModal
            v-model="jobsiteAddModalIsOpen"
            @select="jobsite => listNewJobsite(jobsite, true)"
        />
    </ion-page>
</template>


<style scoped lang = "scss">
.icon {
    width: 1em;
    line-height: 0.75em;
    margin: 0 0.5em 0 0.2em;
    color:var(--ion-color-primary)
}

.jobsiteItem {
    ion-checkbox {
        width: 75%;
    }

    ion-button.jobsiteInfo {
        position: fixed;
        right: 0px;
    }

    &.unselected ion-label {
        opacity: 0.75;
    }
}
</style>
