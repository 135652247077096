<template>
    <ion-content class="no-padding own-content-calendar" :fullscreen="false">
        <IonProgressBar type="indeterminate" v-if="isFetchingData" />
        <div class="calendar-header safe-area-top">
            <MonthVue :month="currentVisibleMonth" :data="monthMetaDataMap[currentVisibleMonth]" />
        </div>
        <div class="calendar">
            <MNSTR
                :ref="mnstr"
                :elements="currentMNSTRListElements"
                :initialScrollToElement="initialMNSTRScrollToElement"
                @renderfirstelement="onListRenderFirstElement"
                @renderlastelement="onListRenderLastElement"
                @firstinboundselementchanged="onListFirstInBoundsElementChanged"
                class="calendar-list"
                observe-cell-bounds
                keep-scroll-state-on-update
            >
                <template v-slot="{ element }">
                    <MonthVue
                        v-if="element.type === 'month'"
                        :month="element.month"
                        :data="monthMetaDataMap[element.month]"
                    />
                    <DayVue
                        v-else
                        :calendarDateSingleton="element"
                        :availability="getDayForDate(element)?.availability"
                        :booking="getDayForDate(element)?.booking"
                        :absence="getDayForDate(element)?.absence"
                        :demandSuggestions="getDayForDate(element)?.demands"
                        :availabilityShiftDefaults="availabilityShiftDefaults"
                        :propsLoading="isPropsLoadingFor(element)"
                        @set:availability="onSetAvailability"
                        @set:absence="onSetAbsence"
                    />
                </template>
            </MNSTR>
        </div>
    </ion-content>
</template>

<script setup lang="ts">
import { ref, computed, watch, defineExpose, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import date from '@/helper/datetime/date';
import MNSTR from '@/libs/mnstr/Vmnstr.vue';
import useEmitter from '@/helper/emitter';
import { useEmployeeStore } from '@/store/employee';
import { useTimesheetStore } from '@/store/timesheet';
import {
    fetchDayForDate,
    fetchDaysForDateRange,
} from '@/helper/day';
import { fetchAvailabilityPlanningDates } from '@/helper/availabilityPlanningDates';
import { fetchCurrentAutopilotStatus } from '@/helper/autopilotStatus';
import DayVue from '@/views/Calendar/Day.vue';
import MonthVue from '@/views/Calendar/Month.vue';
import { fetchAvailabilityShiftDefaults } from '@/helper/availabilityShiftDefault';
import { getAvailabilitySummary, setAvailability } from '@/helper/availability';
import { getAbsenceSummary, setAbsence } from '@/helper/absence';
import { getBookingSummary } from '@/helper/booking';
import {
    CalendarDateSingleton,
    CalendarMonthSingleton,
    getCalendarDatesSingleton,
} from '@/helper/calendar/calendarDateSingleton';
import type {
    AvailabilityPlanningDates,
    Day as DayObject,
    Availability,
    Absence,
    Booking,
    AutopilotStatus,
} from '@/graphql/generated/graphql';
import { MonthMetaData } from './types';
import { kapitelDateString } from '@/graphql/kapitelTypes';
import {IonContent, IonProgressBar, IonButton} from '@ionic/vue'

const route = useRoute();
const router = useRouter();
const emitter = useEmitter();
const employeeStore = useEmployeeStore();
const timesheetStore = useTimesheetStore();

type mnstrListElement = CalendarDateSingleton | CalendarMonthSingleton;
type DayDateMap = { [key: kapitelDateString]: DayObject };

const availabilityShiftDefaults = ref();

const mnstr = ref(null); // Initialize with null

const viewIsActive = ref(true);
const isFetchingData = ref(false);
const fetchedMonths = ref<Array<string>>([]);
const dayDataMap = ref<DayDateMap>({});
const monthMetaDataMap = ref({});
const planningDates = ref();
const autoPilotStatus = ref<Array<AutopilotStatus> | undefined>();
const initialDate = ref(
    (route.query?.date as string) || date.getToday()
);
const calendarMin = ref(date.subMonths(date.startOfMonth(initialDate.value), 1));
const calendarMax = ref(date.endOfMonth(date.addMonths(calendarMin.value, 2)));
const currentVisibleMonth = ref<kapitelDateString>(date.startOfMonth(initialDate.value));
const employeeContractStart = ref<kapitelDateString>();

/**
 * Reinit on query param date change while active.
 */
watch(
    () => route.query,
    () => {
        if (route.query?.date && viewIsActive.value) {
            reinit();
        }
    }
);

/**
 * These events are triggered from the outside.
 * They will invalidate the data of a specific date.
 */
emitter.on('availability:mutating', (date: kapitelDateString) => setDateContentDirty(date));
emitter.on('absence:mutating', (date: kapitelDateString) => setDateContentDirty(date));
emitter.on('availability-absence:mutating', (date: kapitelDateString) => setDateContentDirty(date));

const onSetAvailability = async (availability: Availability) => {
    await setAvailability(availability);
    const day = await fetchDayForDate(availability.date);
    dayDataMap.value[availability.date] = day;
};

const onSetAbsence = async (absence: Absence) => {
    await setAbsence(absence);
    const day = await fetchDayForDate(absence.date);
    dayDataMap.value[absence.date] = day;
};

/**
 * Reinit the view. Scroll to new date if param is set.
 */
const reinit = () => {
    if (route.query?.date) {
        const scrollToDate = route.query?.date as kapitelDateString;

        removeDateFromQueryIfSet();
        calendarMin.value = date.subMonths(date.startOfMonth(scrollToDate), 1);
        calendarMax.value = date.endOfMonth(date.addMonths(calendarMin.value, 2));

        const scrollToElement = getMNSTRListElementByDate(scrollToDate);
        if (mnstr.value) {
            mnstr.value.scrollToElement(scrollToElement);
        }
    }

    monthMetaDataMap.value = {};
    planningDates.value = undefined;
    fetchedMonths.value = [];
    employeeContractStart.value = undefined;

    fetchDataIfNeeded();
};

/**
 * Get the mnstr list element of type "date" by its date.
 * @param dateStr
 */
const getMNSTRListElementByDate = (dateStr: kapitelDateString): mnstrListElement => {
    return currentMNSTRListElements.value.find(
        (element) => element.id === 'date_' + dateStr
    );
};

/**
 * Remove the query param "date" from the query.
 * This helps us for when navigating forth and back.
 */
const removeDateFromQueryIfSet = () => {
    router.replace({
        path: route.path,
    });
};

/**
 * The renderset for the mnstr list,
 * computed by the calendarMin and calendarMax dates.
 */
const currentMNSTRListElements = computed(() => {
    console.log(`MNSTR list bounds from ${calendarMin.value} to ${calendarMax.value}`);
    return getCalendarDatesSingleton(calendarMin.value, calendarMax.value, true);
});

const initialMNSTRScrollToElement = getMNSTRListElementByDate(initialDate.value);

/**
 * A computed representation of the current list elements' months
 * in the format YYYY-MM-DD[].
 */
const currentRenderedMonths = computed(() => {
    return currentMNSTRListElements.value.reduce((acc: Array<string>, element: mnstrListElement) => {
        if (element.type === 'month' && !acc.includes(element.month)) {
            acc.push(element.month);
        }
        return acc;
    }, []);
});

/**
 * Triggered when mnstr renders the very first element
 * of its current list elements.
 * Will prepend 1 month and remove the last one,
 * then fetch data if needed.
 */
const onListRenderFirstElement = () => {
    if (!mnstr.value?.isInitialized()) {
        return;
    }

    if (
        employeeContractStart.value &&
        date.differenceInMonths(employeeContractStart.value, calendarMin.value) >= 0
    ) {
        return;
    }

    calendarMin.value = date.subMonths(calendarMin.value, 1);
    calendarMax.value = date.endOfMonth(date.subDays(calendarMax.value, 42));

    fetchDataIfNeeded();
};

/**
 * Triggered when mnstr renders the very last element
 * of its current list elements.
 * Will append 1 month and remove the first one,
 * then fetch data if needed.
 */
const onListRenderLastElement = () => {
    if (!mnstr.value?.isInitialized()) {
        return;
    }

    calendarMin.value = date.addMonths(calendarMin.value, 1);
    calendarMax.value = date.endOfMonth(date.addDays(calendarMax.value, 7));

    fetchDataIfNeeded();
};

/**
 * Triggered when the first visible element of mnstr has changed.
 * Used to update the month indicator in the page header.
 * @param element
 */
const onListFirstInBoundsElementChanged = (element: mnstrListElement) => {
    if (element.type === 'date') {
        currentVisibleMonth.value = date.startOfMonth(element.date);
    }
    if (element.type === 'month') {
        const previousMonth = date.subMonths(element.month, 1);
        if (employeeContractStart.value && date.isBefore(previousMonth, employeeContractStart.value)) {
            return;
        }
        currentVisibleMonth.value = previousMonth;
    }
};

/**
 * Fetch day data for all necessary months
 * and store it in dayDataMap for quick access.
 * Remembers fetched months so they only get fetched once.
 * This will be invalidated when the view gets entered again.
 */
const fetchDataIfNeeded = () => {
    const monthsToFetch = currentRenderedMonths.value.filter(
        (month) => !fetchedMonths.value.includes(month)
    );
    isFetchingData.value = true;

    const fetchPromises: Array<Promise<void | AvailabilityPlanningDates>> = monthsToFetch.map(
        (month) => {
            const begin = month;
            const end = date.endOfMonth(begin);

            console.log(`Fetching days for month ${month} (${begin} - ${end})`);

            return fetchDaysForDateRange(begin, end).then((days: Array<DayObject>) => {
                days.forEach((day) => (dayDataMap.value[day.date] = day));
                fetchedMonths.value.push(month);
            });
        }
    );

    if (!planningDates.value) {
        fetchPromises.push(
            fetchAvailabilityPlanningDates().then((res) => (planningDates.value = res))
        );
    }

    if (!autoPilotStatus.value) {
        fetchPromises.push(
            fetchCurrentAutopilotStatus().then((res) => (autoPilotStatus.value = res))
        );
    }

    if (!employeeContractStart.value) {
        fetchPromises.push(
            employeeStore.getEmployee().then((res) => {
                employeeContractStart.value = res.firstContract.validFrom;
            })
        );
    }

    Promise.all(fetchPromises).finally(() => {
        isFetchingData.value = false;
        updateMetaDataForMonthsIfNeeded(monthsToFetch);
    });
};

/**
 * Invalidates the loaded day data for a single date.
 * This will result in showing a skeleton for
 * this single date.
 * @param date
 */
const setDateContentDirty = (date: kapitelDateString) => {
    delete dayDataMap.value[date];
};

/**
 * As the title suggests - this will return the
 * day data for a single date.
 * @param date
 */
const getDayForDate = (date: CalendarDateSingleton): DayObject | undefined => {
    return dayDataMap.value[date.date];
};

/**
 * This will check if there is day data loaded for
 * a single date.
 * @param date
 */
const isPropsLoadingFor = (date: CalendarDateSingleton) => !dayDataMap.value[date.date];

/**
 * Calculates the meta data for each currently displayed month.
 * Is triggered after fetching all dependency data.
 * Calculated data is cached until the view is leaved.
 */
const updateMetaDataForMonthsIfNeeded = (months: Array<kapitelDateString>) => {
    (months || []).forEach((month) => {
        if (monthMetaDataMap.value[month]) {
            return;
        }

        console.time('calculate meta data for month ' + month);

        const days = Object.values(dayDataMap.value).filter((dayData: DayObject) =>
            date.isSameMonth(dayData.date, month)
        );
        const currentAutopilotStatus = autoPilotStatus.value?.find(
            (status) => status.month === month
        );

        const isBooking = currentAutopilotStatus !== undefined;
        const isPlanning = planningDates.value
            ? date.isAfter(month, planningDates.value.planningThreshold)
            : false;

        const bookings = days
            .map((d) => d.booking)
            .filter((b): b is Booking => b !== null && b !== undefined);
        const numBookings = getBookingSummary(bookings).shifts;

        const availabilities = days
            .map((d) => d.availability)
            .filter((a): a is Availability => a !== null && a !== undefined);
        const numAvailabilities = getAvailabilitySummary(availabilities).count;

        const absences = days
            .map((d) => d.absence)
            .filter((a): a is Absence => a !== null && a !== undefined);
        const numAbsences = getAbsenceSummary(absences).count;

        const numUntrackedPastBookings = timesheetStore.getToDosByMonth(month).length;
        const isTimeTracking = numUntrackedPastBookings > 0;

        const isContractStart = month === employeeContractStart.value;

        monthMetaDataMap.value[month] = {
            month: month,
            isTimeTracking: isTimeTracking,
            isBooking: isBooking,
            isPlanning: isPlanning,
            isContractStart,
            numBookings: numBookings,
            numUntrackedPastBookings: numUntrackedPastBookings,
            numAvailabilities: numAvailabilities,
            numAbsences: numAbsences,
        };

        console.timeEnd('calculate meta data for month ' + month);
    });
};

const fetchAndParseAvailabilityShiftDefaults = () =>
    fetchAvailabilityShiftDefaults().then((res) => (availabilityShiftDefaults.value = res));

fetchAndParseAvailabilityShiftDefaults();
removeDateFromQueryIfSet();

/**
 * Lifecycle methods to be called from the outer component.
 */
const onEnter = () => {
    viewIsActive.value = true;
    reinit();
};

const onLeave = () => {
    viewIsActive.value = false;
};

const onRouteQueryChanged = () => {
    if (route.query?.date && viewIsActive.value) {
        reinit();
    }
};

defineExpose({
    onEnter,
    onLeave,
    onRouteQueryChanged,
});
reinit()
</script>

<style lang="scss" scoped>
// TWINKIIIII
.own-content-calendar {
    height: 100%;
}
.own-content-calendar::part(scroll) {
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
    height: 100%
}

.calendar-header {
    flex: 0 0 auto;
}

.calendar {
    flex: 1 1 100%;
    position: relative;
    overflow: hidden;

    .calendar-list {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;

        .divider-month {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 6rem;
            color: var(--ion-color-dark);
            font-size: var(--custom-font-size-larger);
            font-weight: var(--custom-font-weight-bold);
            padding-left: var(--custom-spacing-app-content-padding-horizontal);
            padding-right: var(--custom-spacing-app-content-padding-horizontal);

            .planning-threshold {
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 2rem;
                text-align: center;
            }
        }

        .divider-planning-threshold {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6rem;
        }
    }
}
</style>
