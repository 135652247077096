import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {
    AutopilotJobsite,
    AutopilotPreference,
    AutopilotPlayState,
    Jobsite,
    WorktimeMode
} from "@/graphql/generated/graphql";
import {CalendarMonth, sorter as calendarMonthSorter, generateCalendarMonth} from "@/helper/calendar/calendarMonth";
import {pauseCircle, pauseCircleOutline, playCircleOutline, stopOutline} from "ionicons/icons";
import useEmitter from "@/helper/emitter";
import {cmpKapitelDateTime} from  "@/helper/datetime/datetime";
import { clientFragment, houseFragment, jobsiteFragment } from "./jobsite";

const emitter = useEmitter()

export const autopilotPreferenceFragment = gql`
${jobsiteFragment}
fragment AutopilotPreferenceFragment on AutopilotPreference {
    month
    worktimeMode
    playState
    autopilotJobsites {
        source
        jobsite {
            ...JobsiteFragment
            client {
                ...ClientFragment
            }
            house {
                ...HouseFragment
            }
        }
    }
}`

export class AutopilotPreferenceObject {
    constructor(
        public id: string,
        public month: CalendarMonth,
        public autopilotJobsites: Array<AutopilotJobsite>,
        public worktimeMode: WorktimeMode,
        public playState: AutopilotPlayState
    ) {}

    static createFromGraphQL(autopilotPreference: AutopilotPreference) : AutopilotPreferenceObject {
        return new AutopilotPreferenceObject(
            autopilotPreference.month,
            generateCalendarMonth(autopilotPreference.month),
            autopilotPreference.autopilotJobsites,
            autopilotPreference.worktimeMode,
            autopilotPreference.playState
        )
    }
}

export async function fetchAutopilotPreferenceObject(month: CalendarMonth): Promise<AutopilotPreferenceObject | undefined> {
    const result = await query(gql`
        ${autopilotPreferenceFragment}
        ${clientFragment}
        ${houseFragment}
        query getAutopilotPreference(
            $month: KapitelDateImmutable!
        ) {
            autopilotPreference (month: $month){
                ...AutopilotPreferenceFragment
            }
        }`, {
            month: month.begin
        }
    )

    const autopilotPreference = result?.data?.autopilotPreference

    return autopilotPreference
        ? AutopilotPreferenceObject.createFromGraphQL(autopilotPreference)
        : undefined
}

export async function updateAutopilotPreference(
    month: CalendarMonth,
    autopilotPreferenceProperties: {
        worktimeMode?: WorktimeMode
        playState?: AutopilotPlayState
    }
): Promise<AutopilotPreferenceObject> {

    emitter.emit('autopilotPreferences:mutated', month.getId())

    const result = await mutation(gql`
        ${autopilotPreferenceFragment}
        ${clientFragment}
        ${houseFragment}
        mutation updateAutopilotPreference(
            $month: KapitelDateImmutable!,
            $worktimeMode: WorktimeMode,
            $playState: AutopilotPlayState
        ) {
            updateAutopilotPreference(
                month: $month
                worktimeMode: $worktimeMode,
                playState: $playState
            ) {
                ...AutopilotPreferenceFragment
            }
        }`, {
            month: month.begin,
            worktimeMode: autopilotPreferenceProperties.worktimeMode || null,
            playState: autopilotPreferenceProperties.playState || null
        }
    )

    return AutopilotPreferenceObject.createFromGraphQL(result.data.updateAutopilotPreference)
}

export async function addJobsiteToAutopilot(month: CalendarMonth, jobsite: Jobsite): Promise<AutopilotJobsite> {

    emitter.emit('autopilotPreferences:mutated', month.getId())

    const result = await mutation(gql`
            mutation addJobsiteToAutopilot(
                $month: KapitelDateImmutable!,
                $jobsiteId: Int!
            ) {
                addJobsiteToAutopilot(
                    month: $month
                    jobsiteId: $jobsiteId
                ) {
                    jobsite {
                        id
                    }
                }
            }`, {
            month: month.begin,
            jobsiteId: jobsite.id
        }
    )

    return result.data
}

export async function removeJobsiteFromAutopilot(month: CalendarMonth, jobsite: Jobsite): Promise<AutopilotJobsite> {

    emitter.emit('autopilotPreferences:mutated', month.getId())

    const result = await mutation(gql`
            mutation removeJobsiteFromAutopilot(
                $month: KapitelDateImmutable!,
                $jobsiteId: Int!
            ) {
                removeJobsiteFromAutopilot(
                    month: $month
                    jobsiteId: $jobsiteId
                ) {
                    jobsite {
                        id
                    }
                }
            }`, {
            month: month.begin,
            jobsiteId: jobsite.id
        }
    )

    return result.data
}

/**
 * to be used as compareFn for AutopilotPreferenceObject[].sort()
 */
export function sorter(a : AutopilotPreferenceObject, b : AutopilotPreferenceObject) {
    return calendarMonthSorter(a.month, b.month)
}

export function getWorktimeModes() {
    return [
        WorktimeMode.More,
        WorktimeMode.SpotOn,
        WorktimeMode.Less
    ]
}

export function getWorktimeModeLabel(worktimeMode: string) {
    switch(worktimeMode) {
        case WorktimeMode.Less: return 'Überstunden abbauen'
        case WorktimeMode.SpotOn: return 'Sollstunden'
        case WorktimeMode.More: return 'Überstunden ansammeln'
    }
}

export const getIconForWorktimeMode = (worktimeMode: string) => {
    switch (worktimeMode) {
        case WorktimeMode.Less: return '/icons/trend-down.svg'
        case WorktimeMode.SpotOn: return '/icons/target.svg'
        case WorktimeMode.More: return '/icons/trend-up.svg'
    }
}

export function getIconForPlayState(type: AutopilotPlayState | AutopilotPreference ) {
    switch (type) {
        case AutopilotPlayState.Running: return '/icons/play-circle.svg'
        case AutopilotPlayState.Paused: return '/icons/pause-circle.svg'
        case AutopilotPlayState.Scrapped: return '/icons/stop-circle.svg'
    }
}

export function getAutopilotWorktimeModeIcon(preference: AutopilotPreferenceObject | AutopilotPreference | undefined) {
    return getIconForWorktimeMode(preference?.worktimeMode as string)
}

export function getAutopilotWorktimeModeLabel(preference: AutopilotPreferenceObject | AutopilotPreference | undefined) {
    switch (preference?.worktimeMode) {
        case WorktimeMode.Less: return 'Überstunden abbauen (Freizeitausgleich)'
        case WorktimeMode.SpotOn: return 'Sollstunden erreichen'
        case WorktimeMode.More: return 'Überstunden ansammeln'
    }
}
