<script setup lang="ts">
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    onIonViewWillEnter
} from '@ionic/vue'
import { useRouter } from 'vue-router'
import { ref, computed, Ref} from 'vue'
import { fetchJobsiteDetail } from '@/helper/jobsite'
import { getImageForJobsite } from '@/helper/jobsite'
import Contact from "@/views/CalendarDetail/components/Contact.vue"
import Address from "@/views/CalendarDetail/components/Address.vue"
import {DataLoader} from "@/helper/dataLoader.js";
import {Jobsite} from "@/graphql/generated/graphql";

const props = defineProps({
    jobsiteId: String,
})

const router = useRouter()
const jobsiteDetail:Ref<Jobsite|undefined> = ref(undefined)
const fachbereiche = computed(() => jobsiteDetail.value ? jobsiteDetail.value.fachbereiche : [])

const initialDataLoader = new DataLoader([
    async () => { jobsiteDetail.value = props.jobsiteId ? await fetchJobsiteDetail(parseInt(props.jobsiteId)) : undefined },
])

onIonViewWillEnter(() => initialDataLoader.load())
initialDataLoader.load()

</script>

<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-button
                    color="secondary"
                    fill="clear"
                    v-on:click="router.go(-1)"
                    size="small"
                >
                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />
                </ion-button>
                <ion-title>{{ jobsiteDetail?.abbreviation }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>

            <!-- Image -->

            <img
                v-show="jobsiteDetail"
                class="teaser-image"
                :src="jobsiteDetail ? getImageForJobsite(jobsiteDetail) : ''"
                alt="jobsite image"
            />

            <!-- Jobsite -->

            <ion-card
                class="no-padding-top"
            >
                <ion-card-header>
                    <ion-card-title>
                        Station
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content
                    class="no-padding-top"
                >
                    <ion-list>
                        <ion-item>
                            <ion-label>
                                <h3>Name</h3>
                                <p>{{ jobsiteDetail?.name }}</p>
                                <p v-if="jobsiteDetail?.additionalAmenImportantAbbreviations?.length">
                                    <br>
                                    {{ jobsiteDetail?.additionalAmenImportantAbbreviations.join(", ") }}
                                </p>
                            </ion-label>
                        </ion-item>
                        <ion-item
                            v-if="fachbereiche"
                        >
                            <ion-label>
                                <h3>Fachbereiche</h3>
                                <p>{{ fachbereiche.map(fachbereich => fachbereich.name).join(', ') || 'keine' }}</p>
                            </ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label>
                                <h3>Anforderungen</h3>
                                <p>{{ jobsiteDetail?.requirements }}</p>
                            </ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label>
                                <h3>Über uns</h3>
                                <p>{{ jobsiteDetail?.about }}</p>
                            </ion-label>
                        </ion-item>
                        <ion-item
                            v-if="jobsiteDetail?.contact"
                        >
                            <ion-label>
                                <h3>Kontakt</h3>
                                <Contact :contact="jobsiteDetail.contact" />
                            </ion-label>
                        </ion-item>
                        <ion-item
                        v-if="jobsiteDetail?.address"
                        >
                            <ion-label>
                                <h3>Adresse</h3>
                                <Address :address="jobsiteDetail.address" />
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
            </ion-card>

            <!-- House -->

            <ion-card
                v-if="jobsiteDetail?.house"
            >
                <ion-card-header>
                    <ion-card-title>
                        {{ jobsiteDetail?.house?.name }}
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content
                    class="no-padding-top"
                >
                    <ion-list>
                        <ion-item
                            v-if="jobsiteDetail.house.address"
                        >
                            <ion-label>
                                <h3>Adresse</h3>
                                <Address :address="jobsiteDetail.house.address" />
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
            </ion-card>

            <!-- Client -->

            <ion-card
                v-if="jobsiteDetail?.client"
            >
                <ion-card-header>
                    <ion-card-title>
                        {{ jobsiteDetail?.client?.name }}
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content
                    class="no-padding-top"
                >
                    <ion-list>
<!--                        <ion-item-->
<!--                            v-if="jobsiteDetail.client.contact" -->
<!--                        >-->
<!--                            <ion-label>-->
<!--                                <h3>Kontakt</h3>-->
<!--                                <Contact :contact="jobsiteDetail.client.contact" />-->
<!--                            </ion-label>-->
<!--                        </ion-item>-->
                        <ion-item
                            v-if="jobsiteDetail.client.address"
                        >
                            <ion-label>
                                <h3>Adresse</h3>
                                <Address :address="jobsiteDetail.client.address" />
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<style scoped lang="scss">
.teaser-image {
    width: 100%;
    height: 25vh;
    object-fit: cover;
    border-radius: 1rem;
}
</style>
