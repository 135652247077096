import {useChatStore} from "@/helper/chat/chatStore";
import {AInesAssistantType} from "@/graphql/generated/graphql";

import {createAssistant, createThread, sendMessage} from "@/helper/chat/assistantAPI";
import {AssistantRunExpert} from "@/helper/chat/assistantRunExpert";
import {AssistantRunChooser} from "@/helper/chat/assistantRunChooser";
import {warmUpAssistantFeatureMapping} from "@/helper/chat/assistantFeatureMapping";
import {AssistantRunConfig} from "@/helper/chat/assistantRun";
import {ChatStatus} from "@/helper/chat/chatStatus";
import {consoleErrorChat, consoleLogChat} from "@/helper/console";
import {errorCatcher} from "@/helper/error";
import {ThreadMessage} from "@/helper/chat/threadMessage";

export const prependAssistantMessage = async (
    message: string,
    expertAssistant: AInesAssistantType
) : Promise<boolean> => {
    const chatStore = useChatStore()

    const expertRunConfig = await getExpertAssistantRunConfig(expertAssistant);

    chatStore.setChatStatus(ChatStatus.EXPERT_PREPENDING_ASSISTANT_MESSAGE)

    chatStore.addToMessageHistory(new ThreadMessage(
        message,
        'assistant'
    ))

    return await sendMessage(message, 'assistant', expertRunConfig.threadId)
}

let sendUserMessagePending = false
export const sendUserMessage = async (
    message: string,
    targetExpertAssistant: AInesAssistantType | undefined = undefined
) : Promise<void> => {
    const chatStore = useChatStore()

    // concurrency lockout
    if (sendUserMessagePending) {
        consoleErrorChat('concurrency lockout active')
        errorCatcher('concurrency lockout active')
        return
    }
    sendUserMessagePending = true

    consoleLogChat('sending "%s" to %o', message, targetExpertAssistant ? AInesAssistantType[targetExpertAssistant] : 'unknown expert')

    // collect user message
    chatStore.addToMessageHistory(new ThreadMessage(
        message,
        'user'
    ))

    // reset to init
    chatStore.setChatStatus(ChatStatus.READY)

    // if this failed
    if (ChatStatus.READY !== chatStore.chatStatus) {
        consoleErrorChat("resetting chatState to initial state despite currently pending run %o %o", chatStore.chatStatus, chatStore.currentExpertRun)

        chatStore.setChatStatus(ChatStatus.READY, true)

        // reset currentExpertRun to prevent reuse
        // TODO: reusing threads might still be an issue
        chatStore.resetCurrentExpertRun()
    }

    // preserve previousExpertRun before resetting
    const previousExpertRun = chatStore.currentExpertRun as AssistantRunExpert || undefined
    chatStore.resetCurrentExpertRun()

    // set state to "expecting"
    chatStore.setChatStatus(ChatStatus.EXPECTING_NEXT_RESPONSE)

    // determine next expert run
    let nextExpertRun : AssistantRunExpert | undefined;

    try {
        // no target assistant given? ask chooser.
        if (!targetExpertAssistant) {
            chatStore.setChatStatus(ChatStatus.CHOOSER_PENDING);
            [targetExpertAssistant, nextExpertRun] = await _sendUserMessageToChooser(message, previousExpertRun)
        } else {
        // at least inform chooser assistant about the message for subsequent chooser questions
            sendMessage(message, 'user', (await getChooserRunConfig()).threadId)
        }

        // target assistant known? send to expert
        if (!nextExpertRun && targetExpertAssistant) {
            chatStore.setChatStatus(ChatStatus.EXPERT_SETUP);
            nextExpertRun = await _sendUserMessageToTargetAssistant(message, targetExpertAssistant, previousExpertRun)
        }
    } catch (e: unknown) {
        errorCatcher(e as Error)
    } finally {
        // release concurrency lockout
        sendUserMessagePending = false
    }

    if (!nextExpertRun) {
        chatStore.setChatStatus(ChatStatus.FAILED)
        return
    }

    chatStore.setCurrentExpertRun(nextExpertRun)
}
// message sender requires choosing of target assistant by chooserAssistant
const _sendUserMessageToChooser = async (
    message: string,
    previousExpertRun: AssistantRunExpert | undefined
): Promise<[AInesAssistantType|undefined, AssistantRunExpert|undefined]> => {

    // kick off targetExpertAssistant chooser
    consoleLogChat('choosing expertAssistant')
    const chooserPromise = chooseExpertAssistant(message)

    // potential shortcut: check for existing expert and optimistically send message there as well
    if (previousExpertRun) {
        consoleLogChat('send message to previous expertAssistant as well')

        const previousExpertReuseConfirmedPromise = chooserPromise.then((targetExpertAssistant) : boolean => previousExpertRun.runConfig.assistantType === targetExpertAssistant)

        const expertRunWithReusedExpert = await _sendUserMessageToTargetAssistant(
            message,
            previousExpertRun.runConfig.assistantType,
            previousExpertRun,
            previousExpertReuseConfirmedPromise
        )

        // wait for confirmation of previous expert and return (already running) run
        // otherwise continue with chosen expert and a new run like that shortcut never happened
        if (await previousExpertReuseConfirmedPromise) {
            return [previousExpertRun.runConfig.assistantType, expertRunWithReusedExpert]
        }
    }

    // wait for target assistant choice
    const targetExpertAssistant = await chooserPromise
    if (!targetExpertAssistant) {
        return [undefined, undefined]
    }

    return [targetExpertAssistant, undefined]
}
// message sender defines target assistant: no chooser needed
const _sendUserMessageToTargetAssistant = async (
    message: string,
    targetExpertAssistant: AInesAssistantType,
    previousExpertRun: AssistantRunExpert | undefined,
    optimisticExpertConfirmedPromise: Promise<boolean> | undefined = undefined
): Promise<AssistantRunExpert> => {

    const expertRunConfig = await getExpertAssistantRunConfig(targetExpertAssistant);

    if (previousExpertRun && previousExpertRun.runConfig.assistantType !== targetExpertAssistant) {
        consoleLogChat('switched expertAssistant runConfig from previous config %O to %O', previousExpertRun.runConfig, expertRunConfig)
    }

    return new AssistantRunExpert(expertRunConfig, message, optimisticExpertConfirmedPromise)
}


////// Experts //////

const existingExperts = new Map<AInesAssistantType, Promise<AssistantRunConfig>>

let warmUpExpertsPromise: Promise<AssistantRunConfig[]>|undefined = undefined;
const warmUpExperts = () => {
    if (!warmUpExpertsPromise) {
        const experts = Object.values(AInesAssistantType);
        const logString = "warming up " + experts.length + " expert assistants & threads";

        consoleLogChat(logString)
        console.time(logString)
        console.groupCollapsed()

        const expertConfigPromises = experts.map(
            (assistantType: AInesAssistantType) => {
                consoleLogChat("creating new expert assistant & thread for " + assistantType)
                const configPromise = Promise
                    .all([
                        createAssistant(assistantType),
                        createThread()
                    ])
                    .then((v) => {
                        const [assistantId, threadId] = v
                        return {
                            assistantType: assistantType,
                            assistantId,
                            threadId
                        }
                    })
                existingExperts.set(assistantType, configPromise)
                return configPromise
            }
        )
        console.groupEnd();

        warmUpExpertsPromise = Promise.all(expertConfigPromises)
        warmUpExpertsPromise.then(() => console.timeEnd(logString))
    }

    return warmUpExpertsPromise;
}

const getExpertAssistantRunConfig = async (targetExpertAssistant: AInesAssistantType) : Promise<AssistantRunConfig> => {
    if (!warmUpExpertsPromise) {
        warmUpExperts()
    }

    return await existingExperts.get(targetExpertAssistant) as AssistantRunConfig
}


/////// Chooser ///////

let warmUpChooserPromise: Promise<AssistantRunConfig>|undefined = undefined;
const warmUpChooser = () => {
    if(!warmUpChooserPromise){
        consoleLogChat("warming up chooser assistant & thread")

        warmUpChooserPromise = Promise
            .all([
                createAssistant(AInesAssistantType.Meta),
                createThread()
            ])
            .then((v) => {
                const [assistantId, threadId] = v
                return {
                    assistantType: AInesAssistantType.Meta,
                    assistantId,
                    threadId
                }
            })
    }

    return warmUpChooserPromise
}

const getChooserRunConfig = async () : Promise<AssistantRunConfig> => await (warmUpChooserPromise || warmUpChooser())

const chooseExpertAssistant = async (message:string): Promise<AInesAssistantType | undefined> => {
    const metaAssistantRunConfig = await getChooserRunConfig()

    const metaRun = new AssistantRunChooser(metaAssistantRunConfig, message)

    const assistant = await metaRun.assistantChoicePromise

    if (!assistant) {
        consoleErrorChat('chooser failed to choose expertAssistant: %O', metaRun.response)
        return undefined
        // throw new Error('chooser failed to choose expertAssistant')
    }

    consoleLogChat('choose expertAssistant: ' + assistant)
    return assistant
}


////// Warmup ////

export const warmUpAssistant = async () => {
    return Promise.all([
        warmUpExperts(),
        warmUpChooser(),
        warmUpAssistantFeatureMapping()
    ])
}
