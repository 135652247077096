<script setup lang="ts">
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonRange, IonButton, IonContent} from '@ionic/vue'

import {computed, ref, watch} from 'vue'
import {getLabelForShift, orderedShiftTypes, sortShifts} from '@/helper/shiftTypes'
import {
    convertMinutesToTime,
    convertShiftTimeToMinutes
} from '@/helper/datetime/timeRangeHelper'

import type { AvailabilityShift } from "@/graphql/generated/graphql";
import { Availability,  ShiftType } from "@/graphql/generated/graphql";
import type {PropType} from 'vue'
import datetime from "@/helper/datetime/datetime";
import {ShiftTime} from "@/helper/calendar/shiftTime";
import TextWithIcon from "@/components/TextWithIcon.vue";
import InlineSVG from "@/components/InlineSVG.vue";
import Range from '@/components/Range.vue'

const props = defineProps({
    shift: {
        type: Object<AvailabilityShift>,
        required: true
    }
})

const valueBegin = ref(undefined)
const valueUntil = ref(undefined)
const errorMessage = ref("")

const validateNonND = ():boolean =>{

    if(valueBegin.value == valueUntil.value){
        errorMessage.value = "Schichtzeit zu kurz."
        return false
    }

    if(!datetime.isSame(
            datetime.startOfDay(valueUntil.value),
            datetime.startOfDay(valueBegin.value)
        )
    ){
        errorMessage.value = "Beginn und Ende nicht am selben Tag."
        return false
    }

    errorMessage.value = ""
    return true
}

const validateND = ():boolean =>{

    if(valueBegin.value == valueUntil.value){
        errorMessage.value = "Schichtzeit zu kurz."
        return false
    }

    if(!datetime.isSame(
            datetime.startOfDay(datetime.addDays(valueBegin.value, 1)),
            datetime.startOfDay(valueUntil.value)
        )
    ){
        if(!datetime.isSame(
            datetime.startOfDay(valueBegin.value),
            datetime.startOfDay(props.shift.begin),
        )){
            errorMessage.value = "Begin nicht am Verfügbarkeitstag."
            return false
        }
        errorMessage.value = "Ende nicht am Folgetag."
        return false
    }

    errorMessage.value = ""
    return true

}

const validate = (): boolean => {
    if(props.shift.type == ShiftType.Nd){
        return validateND()
    }
    return validateNonND()
}

const updateValues = () => {
    valueBegin.value = props.shift.begin
    valueUntil.value = props.shift.until
}

watch(() => props.shift, updateValues, {immediate: true})


const emit = defineEmits(['update:availableShift']); //'update:availableShifts'

function convertAvailabilityShift2Minutes(begin: kapitelDateTimeString, until: kapitelDateTimeString) : {begin: number, until: number} {
    const shiftTime = new ShiftTime(
        datetime.convertDateTime2Time(begin),
        datetime.convertDateTime2Time(until)
    )
    return convertShiftTimeToMinutes(shiftTime)
}

function convertMinutesToNewAvailabilityShiftTimes(beginMinutes: number, untilMinutes: number) : AvailabilityShift {

    const date = datetime.convertDateTime2Date(props.shift.begin) // all shifts start on the same date

    let begin = datetime.convertDateAndTime2DateTime(date, convertMinutesToTime(beginMinutes))
    let until = datetime.convertDateAndTime2DateTime(date, convertMinutesToTime(untilMinutes))

    if(beginMinutes>=60*24){
        begin = datetime.addDays(begin, 1)
    }

    if (datetime.isAfter(begin, until)){
        until = datetime.addDays(until, 1)
    }


    return {
        begin: begin,
        until: until
    }
}

const availabilityShiftRenderSet = computed(() => {
    const minutes = convertAvailabilityShift2Minutes(
        valueBegin.value,
        valueUntil.value
    )

    let min = 0
    let max = 23*60+59
    if (props.shift.type === ShiftType.Nd) {
        min = 12*60
        max = 36*60
    }


    if(datetime.isSame(
            datetime.addDays(datetime.startOfDay(props.shift.begin), 1),
            datetime.startOfDay(valueBegin.value)
        )
    ){
        minutes.begin += 60*24
        minutes.until += 60*24
    }

    return {
        shiftType: props.shift.type,
        title: getLabelForShift(props.shift.type),
        min: min,
        beginMinutes: minutes.begin,
        untilMinutes: minutes.until,
        max: max
    }

})


const onChange = (beginMinutes: number, untilMinutes: number) => {

    const newShiftTimes = convertMinutesToNewAvailabilityShiftTimes(beginMinutes, untilMinutes)

    valueBegin.value = newShiftTimes.begin
    valueUntil.value = newShiftTimes.until


    if(validate()){
        emit('update:availableShift', {
            begin: valueBegin.value,
            until: valueUntil.value,
            type: props.shift.type
        });
    }

}


const pinFormatter = (value:number) => {
    return convertMinutesToTime(value) + ' Uhr'
}

</script>

<template>
        <ion-card-header>
            <ion-card-title>{{ availabilityShiftRenderSet.title }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <Range
                aria-label="Custom range"
                :dual-knobs="true"
                :min="availabilityShiftRenderSet.min"
                :max="availabilityShiftRenderSet.max"
                :value="{lower: availabilityShiftRenderSet.beginMinutes, upper: availabilityShiftRenderSet.untilMinutes}"
                :pin="true"
                :ticks="false"
                :snaps="true"
                :step="15"
                :pin-formatter="pinFormatter"
                v-on:ion-change="($event) => onChange(
                    $event.detail.value.lower,
                    $event.detail.value.upper)
                "
            />
            <TextWithIcon
                          :small="true"
                          :warning="errorMessage.length>0"
                          :success="errorMessage.length==0"
                      >
                          <template #icons>
                              <InlineSVG v-if="errorMessage.length>0" src="/icons/info.svg" />
                              <InlineSVG v-else src="/icons/check-circle.svg" />
                          </template>
                          <p>
                              {{
                                  errorMessage
                              }}
                          </p>
                      </TextWithIcon>
        </ion-card-content>
</template>

<style lang="scss" scoped>
.shift-time-editor {
    .hull-information {
        background: var(--ion-color-light);
        color: var(--ion-color-medium);
        padding: 1rem;
        border-radius: 1rem;
        margin-top: 0.5rem;
    }
}
</style>
