<template>
<div>
    <RichResponseHeadline
        :title="headline"
    />
    
    <div
        class="rich-month-calendar"
        @click="onClick"
    >
        <MonthCalendar
            :date="initData"
        >
            <template v-slot="{ date, adjacent }">
                <div
                    v-if="date && !adjacent"
                    class="calendar-date-content"
                    :class="{booked: !!days[date.id]?.booking, available: (!!days[date.id]?.availability && hasShifts(days[date.id].availability || {shifts: [], date: date.id, constraints: []} as Availability)), absence: !!days[date.id]?.absence}"
                />
            </template>
        </MonthCalendar>
        <div class="legend">
            <div class="legend-item booked">gebucht</div>
            <div class="legend-item available">verfügbar</div>
            <div class="legend-item absence">abwesend</div>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import RichResponseHeadline from "./components/RichResponseHeadline.vue";
import MonthCalendar from '@/components/MonthCalendar.vue';
import {Availability, Day} from "@/graphql/generated/graphql";
import { computed, ref } from "vue";
import { kapitelDateString } from "@/graphql/kapitelTypes";
import { DataLoader } from "@/helper/dataLoader";
import { startOfMonth, endOfMonth, formatMonthFromKapitelDate, getYearFromKapitelDate } from '@/helper/datetime/date';
import { fetchDaysForDateRange } from "@/helper/day";
import {hasShifts} from "@/helper/availability";
import {useRouter} from "vue-router";

const props = defineProps<{
    initData: kapitelDateString // month start
}>()

const router = useRouter()

const emit = defineEmits(['details'])
const onClick = () => {
    router.push('/calendar?date=' + props.initData)
    // emit("details", {
    //     type: "ScrollCalendar",
    //     initData: {
    //         date: props.initData,
    //     }
    // })
}


type DayMap = {
  [date: kapitelDateString]: Day;
};
const days = ref<DayMap>({})

const headline = computed(() => {
    return `${formatMonthFromKapitelDate(props.initData)} ${getYearFromKapitelDate(props.initData)}`
})

const loader = new DataLoader(
    async () => {
        const from = startOfMonth(props.initData)
        const to = endOfMonth(from)

        const data = await fetchDaysForDateRange(from, to)
        
        data.forEach(day => {
            days.value[day.date] = day
        })
    }
)
loader.load()
</script>

<style scoped  lang="scss">

.rich-month-calendar {
    background-color: var(--ion-color-light);
    border-radius: 1rem;
    padding: 0.5em 1em 1em 1em;
    width: 100%;
    margin-top: 0.5em;
}

.calendar-date-content {
    $SIZE: 0.5em;
    width: $SIZE;
    height: $SIZE;
    border-radius: 50%;
    transform: translateY(1.1em);
    
    &.booked {
        background-color: var(--ion-color-black);
    }

    &.available {
        background-color: var(--ion-color-green);
    }

    &.absence {
        background-color: var(--ion-color-red);
    }
}

.legend {
    display: flex;
    justify-content: center;
    margin-top: 1.5em;

    > .legend-item {
        margin: 0 0.5em;
        font-size: var(--custom-font-size-small);
        color: var(--ion-color-grey);

        &:before {
            $SIZE: 0.6em;
            content: '';
            width: $SIZE;
            height: $SIZE;
            border-radius: 50%;
            margin-right: 0.5em;
            display: inline-block;
        }

        &.booked:before {
            background-color: var(--ion-color-black);
        }

        &.available:before {
            background-color: var(--ion-color-green);
        }

        &.absence:before {
            background-color: var(--ion-color-red);
        }
    }
}
</style>
