<script setup lang="ts">
import {
    IonButton,
    IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonRange} from '@ionic/vue'
import {computed, onBeforeMount, ref} from 'vue'
import {fetchAvailabilityShiftDefaults} from '@/helper/availabilityShiftDefault'
import {getLabelForShift, orderedShiftTypes} from '@/helper/shiftTypes'
import {fetchGlobalShiftHullTimes} from "@/helper/globalShiftTimes"
import {
    convertMinutesToShiftTime,
    convertMinutesToTime,
    convertShiftTimeToMinutes,
    shiftTimesExceedHullTimes
} from '@/helper/datetime/timeRangeHelper'
import {Availability, AvailabilityShiftDefault, ShiftType} from "@/graphql/generated/graphql";
import TextWithIcon from '@/components/TextWithIcon.vue'
import Modal from '@/components/Modal.vue'
import { ShiftTime } from "@/helper/calendar/shiftTime";
import InlineSVG from '@/components/InlineSVG.vue';
import Range from '@/components/Range.vue'

const props = defineProps<{
    availabilityShiftDefaults: AvailabilityShiftDefault[];
}>();

const emit = defineEmits(['update:availableShiftDefault']);

const editItem = ref<string|undefined>(undefined)
const editorModalOpen = ref<boolean>(false)

const availabilityShiftIconMap = {
    FD: '/icons/sun-horizon.svg',
    ZD: '/icons/sun-dim.svg',
    SD: '/icons/sun.svg',
    ND: '/icons/moon-stars.svg',
}

const openEditShiftDefaultsModal  = (shift:AvailabilityShiftDefault) => {
    editItem.value = shift.type
    editorModalOpen.value = true
}

const shifts = ref([])
const hullTimes = ref([])

const shiftBoundariesInMinutes = computed(() => {
    const boundaryThresholdInMinutes = 120

    return hullTimes.value.reduce((acc, hullTime) => {
        acc[hullTime.type] = convertShiftTimeToMinutes(hullTime)
        acc[hullTime.type].begin-= boundaryThresholdInMinutes
        acc[hullTime.type].until+= boundaryThresholdInMinutes
        return acc
    }, {})
})

const shiftsInMinutes = computed(() => {
    return props.availabilityShiftDefaults.reduce((acc, shift) => {
        acc[shift.type] = convertShiftTimeToMinutes(shift)
        return acc
    }, {})
})

const getHullInformationStringForShift = shift => {
    const times = hullTimes.value.find(hullTime => hullTime.type === shift)
    const shiftLabel = getLabelForShift(shift)

    return times
        ? `Die meisten ${shiftLabel}e finden zwischen ${times.begin} Uhr und ${times.until} Uhr statt.`
        : undefined
}

const isInHull = shiftType => {
    const hullTimeShift:AvailabilityShiftDefault|undefined = hullTimes.value.find(hullTime => hullTime.type === shiftType)
    if(!hullTimeShift){
        return true
    }
    const hullTime = new ShiftTime(
        hullTimeShift.begin,
        hullTimeShift.until
    )

    const shift:AvailabilityShiftDefault|undefined = props.availabilityShiftDefaults.find(llShift => llShift.type==shiftType)
    if(!shift){
        return true
    }
    const shiftTime = new ShiftTime(
        shift.begin,
        shift.until
    )

    return(!shiftTimesExceedHullTimes(shiftTime, hullTime))
}

const pinFormatter = value => {
    return convertMinutesToTime(value) + ' Uhr'
}

const fetchHullTimes = () => {
    return fetchGlobalShiftHullTimes()
        .then(res => hullTimes.value = res)
}

const onChangeShift = (event, shift) => {
    const values = event.detail.value
    const times = convertMinutesToShiftTime(values.lower, values.upper)

    const newAvailabilityShiftDefault: AvailabilityShiftDefault = {
        begin: times.begin,
        until: times.until,
        type: shift
    }
    emit('update:availableShiftDefault', newAvailabilityShiftDefault);
}

onBeforeMount(() => {
    fetchHullTimes();
})
</script>

<template>

<ion-card class="custom-ion-card-light">
    <ion-card-header>
        <ion-card-title>
            Schichtzeiten anpassen
        </ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <div
            class="availability-shifts"
        >
            <div
                v-for="shift in availabilityShiftDefaults"
            >
                <div @click="()=> {openEditShiftDefaultsModal(shift) }">
                    <InlineSVG
                        :src="availabilityShiftIconMap[shift.type]"
                    />
                    <div
                        class="type"
                    >
                        {{ shift.type }}
                    </div>
                    <div
                        class="times"
                    >
                        {{ shift.begin }} - {{ shift.until }}
                    </div>
                </div>
            </div>
        </div>
    </ion-card-content>
</ion-card>

<Modal
    v-model="editorModalOpen"
    title="Schichtzeiten anpassen"
>
<div class="shift-time-editor" v-if="hullTimes.length">
   <ion-card>
        <ion-card-header>
            <ion-card-title>
                Mein {{ getLabelForShift(editItem) }}
            </ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <Range
                aria-label="Custom range"
                :dual-knobs="true"
                :min="shiftBoundariesInMinutes[editItem]?.begin"
                :max="shiftBoundariesInMinutes[editItem]?.until"
                :value="{lower: shiftsInMinutes[editItem].begin, upper: shiftsInMinutes[editItem].until}"
                :pin="true"
                :ticks="false"
                :snaps="true"
                :step="15"
                :pin-formatter="pinFormatter"
                v-on:ion-change="$event => onChangeShift($event, editItem)"
            />
            <TextWithIcon
                v-if="getHullInformationStringForShift(editItem)"
                :small="true"
                :warning="!isInHull(editItem)"
                :success="isInHull(editItem)"
            >
                <template
                    #icons
                >
                    <InlineSVG
                        v-if="!isInHull(editItem)"
                        src="/icons/info.svg"
                    />
                    <InlineSVG
                        v-else
                        src="/icons/check-circle.svg"
                    />
                </template>
                <p>
                    {{ getHullInformationStringForShift(editItem) }}
                </p>
            </TextWithIcon>

        </ion-card-content>
    </ion-card>
</div>
</Modal>


</template>

<style lang="scss" scoped>
.shift-time-editor {
    .hull-information {
        background: var(--ion-color-light);
        color: var(--ion-color-medium);
        padding: 1rem;
        border-radius: 1rem;
        margin-top: 0.5rem;
    }
}
</style>
