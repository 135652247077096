import {AssistantStream} from "openai/lib/AssistantStream";
import mitt, {Emitter} from 'mitt';
import {Text} from "openai/resources/beta/threads/messages";
import {AssistantStreamEvent} from "openai/resources/beta/assistants";
import {AInesAssistantType, AInesFeatureSet} from "@/graphql/generated/graphql";
import {sendMessageAndRun} from "@/helper/chat/assistantAPI";

import {getAssistentForFeatureSet} from "@/helper/chat/assistantFeatureMapping";
import {AssistantRunConfig, AssistantRunEvents, AssistantRunState} from "@/helper/chat/assistantRun";
import {consoleLogChat} from "@/helper/console";


export class AssistantRunChooser {
    public mitt : Emitter<AssistantRunEvents>;

    public state : AssistantRunState = AssistantRunState.INIT;

    private isStreamingTextAttribute = false

    public assistantChoicePromise : Promise<AInesAssistantType|undefined>

    public response : string|object|undefined

    constructor(
        public runConfig: AssistantRunConfig,
        public userMessage: string
    ) {
        // init event bus
        this.mitt = mitt<AssistantRunEvents>();

        // prepare choice promise
        let choicePromiseResolve : (value: AInesAssistantType | undefined) => void // , choicePromiseReject;
        this.assistantChoicePromise = new Promise(function(resolve, reject){
            choicePromiseResolve = resolve;
            // choicePromiseReject = reject;
        });

        consoleLogChat('%s: sending userMessage "' + this.userMessage + '" to  %O',AInesAssistantType[this.runConfig.assistantType],  this.runConfig)
        const runRequestPromise = sendMessageAndRun(
            this.runConfig.assistantId,
            this.runConfig.threadId,
            this.userMessage
        )

        runRequestPromise.then((stream: AssistantStream) => {
            consoleLogChat('%s: receiving response stream for userMessage "' + this.userMessage + '" from %O',AInesAssistantType[this.runConfig.assistantType],  this.runConfig)

            // response stream started: emit event
            this.mitt.emit('runResponseStarted')

            //state: progress state from INIT > RUNNING
            this.state = AssistantRunState.RUNNING

            // streamEvent: forward general purpose event-event
            // stream.on('event', (event: AssistantStreamEvent) => this.mitt.emit('runStreamEvent', event))

            stream.on('textDone', async (content: Text) => {
                // resolve choice promise
                let message
                try {
                    message = JSON.parse(content.value)
                } catch {
                    message = undefined
                }
                this.response = message || content

                consoleLogChat('%s: response stream completed for userMessage "' + this.userMessage + '" from %O: %O',AInesAssistantType[this.runConfig.assistantType],  this.runConfig, this.response)

                const featureSet = (message && message.chosenFeatureSet && AInesFeatureSet.hasOwnProperty(message.chosenFeatureSet as string)) ? (message.chosenFeatureSet as AInesFeatureSet) : undefined

                const assistant = featureSet ? await getAssistentForFeatureSet(featureSet) : undefined

                choicePromiseResolve(assistant)

                // state: on content complete progress state from streaming > completed
                this.state = AssistantRunState.COMPLETED

                // streamComplete: event
                this.mitt.emit('runComplete')
            })
        })
    }

}
