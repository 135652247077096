import PlanningStatus from "@/views/Chat/RichResponses/PlanningStatus.vue";
import JobsiteSearch from "@/views/Chat/RichResponses/JobsiteSearch.vue";
import {AInesRichResponseType, AutopilotJobsiteSource} from "@/graphql/generated/graphql";
import RegisterBookingSummary from "@/views/Chat/RichResponses/RegisterBookingSummary.vue";
import DayCalendar from "@/views/Chat/RichResponses/DayCalendar.vue";
import WeekCalendar from "@/views/Chat/RichResponses/WeekCalendar.vue";
import MonthCalendar from "@/views/Chat/RichResponses/MonthCalendar.vue";
import JobsiteList from "@/views/Chat/RichResponses/JobsiteList.vue";
import AutopilotJobsitePreferences from "@/views/Chat/RichResponses/AutopilotJobsitePreferences.vue";
import TimesheetJobsites from "@/views/Timesheet/pages/TimesheetJobsites.vue";
import TimesheetList from "@/views/Chat/RichResponses/TimesheetList.vue";

export type ResponseMessageRichResponse = {type: string, reference: string}

export type RichContent = {
    type: string;
    initData: object | string;
}
export const convertRichResponseToRichContent = (richResponse: { type: string, reference: string }): RichContent => {
    const type = richResponse.type as string

    let initData;
    try {
        initData = JSON.parse(richResponse.reference) as object;
    } catch (e) {
        initData = richResponse.reference
    }

    return {type, initData} as RichContent
}
export const getRichComponentFor = (richContent: RichContent) => {
    switch(richContent.type) {
        case AInesRichResponseType.JobsiteList:
            return JobsiteList
        // case "demand":
        //     return DemandList
        // case "booking":
        //     return BookingList
        // case "availability":
        //     return AvailabilityList
        // case "absence":
        //     return AbsenceList
        // case "planningStatus":
        //     return PlanningStatus
        case AInesRichResponseType.DayCalendar:
            return DayCalendar;
        case AInesRichResponseType.WeekCalendar:
            return WeekCalendar;
        case AInesRichResponseType.MonthCalendar:
            return MonthCalendar
        case AInesRichResponseType.RegisterBookingSummary:
            return RegisterBookingSummary
        case AInesRichResponseType.AutopilotPlanningStatusSummary:
            return PlanningStatus
        case AInesRichResponseType.JobsiteSearch:
            return JobsiteSearch
        case AInesRichResponseType.AutopilotJobsitePreferences:
            return AutopilotJobsitePreferences
        case AInesRichResponseType.TimesheetJobsiteSummary:
            return TimesheetList
        case AInesRichResponseType.AzkMeter:
        case AInesRichResponseType.AutopilotStartWizard:
            debugger
            return undefined;
        default:
            console.log("undefined rich component for " + richContent.type + " - " + JSON.stringify(richContent));
            return undefined;
    }

}
