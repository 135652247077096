<template>
    <ion-page class="page-auth">
        <ion-content padding="true">
            <ion-loading
                :is-open="loginPending"
                message="Anmeldung..."
            />

            <div
                class="centered-content"
            >
                <img class="logo" src="/logo.png" alt="IBFG Logo">

                <h2 class="">
                    Willkommen bei der IBFG.
                </h2>

                <p>
                    Um deine Registrierung abzuschließen benötigst du einmalig einen Einladungslink.
                </p>
                <p>
                    Falls du noch keinen Link hast, <a :href="'mailto:'+contact.mail">registriere</a> dich per E-Mail, <a @click="contactModalIsOpen = true">kontaktiere</a> uns oder schau auf unserer <a :href="website" target="_blank">Website</a> vorbei &mdash; wir helfen dir gerne weiter.
                </p>
            </div>

            <Modal
                title="Kontakt"
                v-model="contactModalIsOpen"
            >
                <Contact
                    :contact="contact"
                    :website="website"
                    display-mode="standalone"
                ></Contact>
            </Modal>

            <div class="boxed">
                <p class="text-center">
                    Du hast bereits einen Einladungslink? Dann gib ihn hier ein um dich zu registrieren.
                    <br><br>
                </p>
                <ion-button
                    color="secondary"
                    expand="block"
                    v-on:click="magicLinkInputModalIsOpen = true"
                >
                    Link eingeben
                </ion-button>
            </div>
        </ion-content>

        <Modal
            v-model="magicLinkInputModalIsOpen"
            title="Einladungslink eingeben"
        >
            <p>
                Bitte kopiere den vollständigen Einladungslink in das Textfeld.
            </p>
            <br>
            <ion-textarea
                v-on:input="(v) =>{magicLinkInput = v.target.value}"
                :value="magicLinkInput"
                placeholder="Hier einfügen"
                label="Einladungslink"
                label-placement="stacked"
                :error-text="magicLinkInputErrorState"
            />

            <ion-button
                v-on:click="pasteMagicLinkFromClipboard"
                expand="block"
                fill="clear"
                color="secondary"
            >
                Link aus Zwischenablage einfügen
            </ion-button>
            <ion-button
                @click="handleMagicLinkInput"
                color="secondary"
                expand="block"
                :disabled="!magicLinkInput"
            >
                Registrierung abschließen
            </ion-button>
        </Modal>
    </ion-page>
</template>

<script setup lang="ts">

import {alertController, IonButton, IonContent, IonLoading, IonPage, IonTextarea} from '@ionic/vue';
import Modal from '@/components/Modal.vue'
import {loginWithUniversalLink} from "@/helper/auth"
import {ref} from "vue"

import {Clipboard} from '@capacitor/clipboard'
import Contact from '../CalendarDetail/components/Contact.vue';
import type {Contact as ContactType} from '@/graphql/generated/graphql';

const contact : ContactType = {
    name: "Alexander Muschalle",
    mobile: '+49 1511 5676102',
    mail: 'alexander@ibfg.social'
}
const website = 'https://ibfg.social'

const loginPending = ref(false)
const contactModalIsOpen = ref(false)
const magicLinkInputModalIsOpen = ref(false)
const magicLinkInput = ref("")
const magicLinkInputErrorState = ref()

const aquireTextFromClipboard = async () => {
    const { type, value } = await Clipboard.read();
    if (type === 'text/plain') {
        return value
    }
};

const onError = async (s: string) => {
    // magicLinkInputErrorState.value = s

    const alert = await alertController.create({
        header: 'Fehler',
        message: s,
        buttons: ['Ok'],
    });

    await alert.present();
}

const pasteMagicLinkFromClipboard = async () => {
    const text = await aquireTextFromClipboard()
    if (!text) {
        onError('Kein Einladungslink in der Zwischenablage')
        return
    }

    magicLinkInput.value = text
    await handleMagicLinkInput()
}

const handleMagicLinkInput = async () => {
    const universalLink = magicLinkInput.value

    if (!universalLink) {
        onError("Eingabefeld leer")
        return
    }

    loginPending.value = true

    try {
        await loginWithUniversalLink(magicLinkInput.value, true)
    } catch (e : any) {
        loginPending.value = false

        onError(e?.message || 'Unbekannter Fehler')

        return
    }

    magicLinkInput.value = ''
    magicLinkInputModalIsOpen.value = false

    loginPending.value = false
}
</script>

<style lang="scss" scoped>

// contact modal auto height
ion-modal {
    --height: auto;
}

.page-auth {
    ion-content {
        &::part(scroll) {
            padding-top: var(--ion-safe-area-top);
            padding-bottom: var(--ion-safe-area-bottom);

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .centered-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .logo {
            width: 25vw;
            margin-bottom: 3rem;
        }
    }

    .boxed {
        margin-bottom: 2rem;
    }
}
</style>
