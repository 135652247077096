<template>
    <ion-page class="availability-templates-view">
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-button
                    color="secondary"
                    fill="clear"
                    v-on:click="router.go(-1)"
                    size="small"
                >
                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />
                </ion-button>
                <ion-title>Verfügbarkeitenvorlage</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="no-padding-top">

            <!-- Shift Default Times -->
            <Skeleton v-if="loader.pending.value" :items="['margin', 'title', 'block--height-10rem', 'margin']"></Skeleton>
            <AvailabilityShiftDefaultsEditor
                v-else
                :availability-shift-defaults="availabilityShiftDefaults"
                v-on:update:available-shift-default="onUpdateAvailabilityShiftDefaults"
            />

            <!-- Shift Default Times -->
            <div
                class="effective-summary"
                v-for="weekday in date.weekdays()"
                :key="weekday.day"
            >
                <Skeleton v-if="loader.pending.value" :items="['title', 'block--height-10rem']"></Skeleton>
                <template v-else>
                    <AvailabilityTemplateEditor
                        :title="weekday.label"
                        :availability-template="getAvailabilityTemplateForWeekday(weekday.day)"
                    />
                    <AvailabilityEffectiveInfo
                        :shifts="getTheoreticalAvailabilityShiftsForTemplate(getAvailabilityTemplateForWeekday(weekday.day))"
                        :constraints="getTheoreticalAvailabilityConstraintsForTemplate(getAvailabilityTemplateForWeekday(weekday.day))"
                        :label="weekday.label"
                        :jobsiteCount="undefined"
                    />
                </template>
            </div>

        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">

import {IonButton, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, onIonViewWillEnter} from '@ionic/vue';
import {computed, Ref, ref} from "vue";
import {useRouter} from 'vue-router'
import {fetchAvailabilityTemplates, generateAvailabilityTemplate} from "@/helper/availabilityTemplate";
import {fetchAvailabilityShiftDefaults} from "@/helper/availabilityShiftDefault";
import {sortShifts} from '@/helper/shiftTypes'
import date from '@/helper/datetime/date'
import datetime from '@/helper/datetime/datetime'
import {generatePropertiesFromDefault} from '@/helper/availability'
import AvailabilityTemplateEditor from '@/components/AvailabilityTemplateEditor.vue'
import AvailabilityEffectiveInfo from '@/components/AvailabilityEffectiveInfo.vue'
import AvailabilityShiftDefaultsEditor from "./components/AvailabilityShiftDefaultsEditor.vue"
import {DataLoader} from "@/helper/dataLoader";
import Skeleton from "@/components/Skeleton.vue";
import {
    AvailabilityShiftDefault,
    AvailabilityTemplate,
    AvailabilityTemplateShift,
    AvailabilityTemplateTimeConstraint
} from "@/graphql/generated/graphql";
import { saveAvailabilityShiftDefault } from '@/helper/availabilityShiftDefault'
const router = useRouter()

const availabilityShiftDefaults : Ref<AvailabilityShiftDefault[]> = ref([])

const availabilityTemplates : Ref<AvailabilityTemplate[]> = ref([])
const getAvailabilityTemplateForWeekday = (weekday: number) : AvailabilityTemplate =>
  {
    if(!availabilityTemplates.value) {
        throw new Error('uninitialized')
    }
    let existingTemplate = availabilityTemplates.value.find(template => template.dayOfWeekISO === weekday)
    if (!existingTemplate){
      existingTemplate = generateAvailabilityTemplate(weekday)
      availabilityTemplates.value.push(existingTemplate)
    }
    return existingTemplate
  }


const convertedAvailabilityShifts = computed(() => {
    const dateString = date.getToday()

    return (availabilityShiftDefaults.value || [])
        .map(shiftDefault => generatePropertiesFromDefault(dateString, shiftDefault))
})

const getTheoreticalAvailabilityShiftsForTemplate = (availabilityTemplate:AvailabilityTemplate) => {
    return convertedAvailabilityShifts.value.filter(availabilityShift => {
        return availabilityTemplate?.shifts.some((shift:AvailabilityTemplateShift) => availabilityShift.type === shift.type)
    })
}

const getTheoreticalAvailabilityConstraintsForTemplate = (availabilityTemplate: AvailabilityTemplate) => {
  return (availabilityTemplate?.constraints || []).map((constraint : AvailabilityTemplateTimeConstraint): { begin: string; until: string; } => {
      return {
          begin: datetime.convertDateAndTime2DateTime(
            date.getToday(),
            constraint.begin
          ),
          until: datetime.convertDateAndTime2DateTime(
            date.getToday(),
            constraint.until
          )
      }
  })
}

const getAvailabilityTemplates = () => {
    return fetchAvailabilityTemplates()
        .then(res => availabilityTemplates.value = res)
}

const getAvailabilityShiftDefaults = () => {
    return fetchAvailabilityShiftDefaults()
        .then(res => availabilityShiftDefaults.value = res.sort(sortShifts))
}

const onUpdateAvailabilityShiftDefaults = (newShiftDefault: AvailabilityShiftDefault) => {
    const existingAvailabilityShiftDefault = availabilityShiftDefaults.value.find(s => s.type === newShiftDefault.type)
    if(!existingAvailabilityShiftDefault){
        availabilityShiftDefaults.value.push(newShiftDefault)
        saveAvailabilityShiftDefault(newShiftDefault)
    }else{
        existingAvailabilityShiftDefault.begin = newShiftDefault.begin
        existingAvailabilityShiftDefault.until = newShiftDefault.until
        saveAvailabilityShiftDefault(existingAvailabilityShiftDefault)
    }

}

const loader = new DataLoader([
    getAvailabilityTemplates,
    getAvailabilityShiftDefaults
])

onIonViewWillEnter(loader.load)
loader.load()

</script>

<style lang="scss">
.availability-templates-view {
    .availability-shifts {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        > div {
            >div {
                border-radius: 1rem;
                background-color: white;

                padding-left: 1.4rem;
                padding-right: 1.4rem;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;


                > .inline-svg {
                    width: 2.5rem;
                    margin-top: 0.75rem;
                    margin-bottom: 0.25rem;
                    color: var(--ion-color-primary);
                    // should be a mixin
                    filter: drop-shadow( 0 0 0.4rem rgba(var(--ion-color-primary-rgb), 0.5));
                }

                > .type {
                    font-weight: var(--custom-font-weight-extrabold);
                    color: var(--ion-color-dark);
                }

                > .times {
                    font-size: var(--custom-font-size-small);
                    color: var(--ion-color-medium);
                    margin-top: 0.5em;
                    margin-bottom: 1rem;
                    text-align: center;
                }
            }
        }
    }

    .effective-summary {
        margin-bottom: 3rem;
    }
}
</style>
