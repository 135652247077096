<script setup lang="ts">
import TimesheetBookings from "@/views/Timesheet/pages/TimesheetBookings.vue";
import {
    IonButton, IonCard,
    IonCardContent,
    IonCardHeader, IonCardTitle, IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList, IonPage,
    IonTitle,
    IonToolbar,
    IonNav, useIonRouter,
    IonBackButton,
    IonButtons, onIonViewWillLeave,
    IonInput, onIonViewWillEnter, NavComponentWithProps
} from "@ionic/vue";
import {Component, computed, markRaw, Ref, ref} from "vue";
import {onBeforeRouteLeave } from "vue-router";
import TimesheetSignateur from "@/views/Timesheet/pages/TimesheetSignateur.vue";
import TimesheetSignature from "@/views/Timesheet/pages/TimesheetSignature.vue";
import TimesheetJobsites from "@/views/Timesheet/pages/TimesheetJobsites.vue";
import TimesheetDone from "@/views/Timesheet/pages/TimesheetDone.vue";
import {useTimesheetStore} from "@/store/timesheet";

/**
 * properties
 */

const ionRouter = useIonRouter()

const timesheetStore = useTimesheetStore()

const props = defineProps({
    jobsiteId: {
        default: '',
        type: String
    }
})


const selectedJobsiteId : Ref<string> = ref(props.jobsiteId)

const timesheetToDoJobsites = computed(()=> timesheetStore.getToDoJobsites)

const pages : Ref<Array<any>> = computed(() => {
    const pages = [
        markRaw(TimesheetBookings),
        markRaw(TimesheetSignateur),
        markRaw(TimesheetSignature),
        markRaw(TimesheetDone)
    ];

    // if no jobsite id is defined add jobsite selection as first step
    if (!selectedJobsiteId.value) {
        pages.unshift(markRaw(TimesheetJobsites))
    }

    return pages
})


const ionNavigator = () :HTMLIonNavElement => {
    const nav = document.querySelector('ion-nav');
    if (!nav) {
        throw new Error("missing nav")
    }
    return nav
}

const currentPageIndex = ref(0)

const unlockRouteLeave = ref(false)

const timesheetNavigator = {
    getCurrentPageIndex: () => currentPageIndex.value,
    getPageLength: () => pages.value.length,
    cancel: () => {
        unlockRouteLeave.value = true
        ionRouter.back()
        // ionRouter.navigate('/', "none", 'replace')
    },
    next: () => {
        const ionNav = ionNavigator()
        currentPageIndex.value++
        ionNav.push(pages.value[currentPageIndex.value], {timesheetNavigator})
    },
    prev: () => {
        const ionNav = ionNavigator()
        currentPageIndex.value--
        ionNav.push(pages.value[currentPageIndex.value], {timesheetNavigator})
    },
    done: () => {
        unlockRouteLeave.value = true
        // ionRouter.back()
        ionRouter.navigate('/', "none", 'replace')
    }
}

/**
 * methods
 */

const init = async () => {
    timesheetStore.ensureInit()

    if (timesheetStore.isInSignatureProcess()) {
        timesheetStore.cancelSignatureProcess()
    }

    // if no jobsite id was given check how many options we actually have
    if (!selectedJobsiteId.value) {
        if (timesheetToDoJobsites.value.length === 1) {
            selectedJobsiteId.value = timesheetToDoJobsites.value[0].jobsite.id.toString()
        }
    }

    if (selectedJobsiteId.value) {
        timesheetStore.startSignatureProcess(parseInt(selectedJobsiteId.value))
    }

    currentPageIndex.value = 0

    unlockRouteLeave.value = false

    const ionNav = ionNavigator()

    if (await ionNav.canGoBack()) {
        await ionNav.popToRoot({animated: false})
    }
}

/**
 * events
 */

onBeforeRouteLeave(() => {
    if (!unlockRouteLeave.value) return false

    timesheetStore.cancelSignatureProcess()
})

const navChanged = async () => {
    const view = await ionNavigator()?.getActive()
    currentPageIndex.value = pages.value.findIndex(component => view?.component === component)
}

onIonViewWillEnter(init)

</script>

<template>
    <ion-page>
        <ion-nav @ionNavDidChange="navChanged" :root="pages[0]" :rootParams="{timesheetNavigator}"></ion-nav>

    </ion-page>
</template>
