<script setup lang="ts">
import { IonRange } from '@ionic/vue'
import { PropType, nextTick, onMounted, onUpdated, ref } from 'vue'

const props = defineProps({
    dualKnobs: {
        type: Boolean,
        required: false,
        default: false
    },
    min: {
        type: Number,
        required: false,
        default: 0
    },
    max: {
        type: Number,
        required: false,
        default: 100
    },
    value: {
        type: [Number, Object] as PropType<number | { lower: number; upper: number }>,
        required: false,
        default: 0
    },
    pin: {
        type: Boolean,
        required: false,
        default: false
    },
    ticks: {
        type: Boolean,
        required: false,
        default: false
    },
    snaps: {
        type: Boolean,
        required: false,
        default: false
    },
    step: {
        type: Number,
        required: false,
        default: 1
    },
    pinFormatter: {
        type: Function as PropType<(value: number) => string | number>,
        required: false,
        default: (value: number) => value
    }
})

const component = ref()
const emit = defineEmits(['ion-change', 'ion-input'])

const onInput = (event: CustomEvent) => {
    emit('ion-input', event)
    updatePinPositions()
}

const getOffset = (element: HTMLElement | null) => {
    let offsetLeft = 0
    let offsetTop  = 0;

    while (element) {
        offsetLeft += element.offsetLeft
        offsetTop  += element.offsetTop
        element = element?.offsetParent as HTMLElement
    }

    return {offsetLeft, offsetTop}  
}

// Thx ionic for being mobile friendly.
const updatePinPositions = () => {
    const componentEl = component.value.$el
    const pinEls = componentEl.shadowRoot.querySelectorAll('[part="pin"]')
    const componentElRect = componentEl.getBoundingClientRect()
    const componentOffsetLeft = getOffset(componentEl).offsetLeft
    const componentOffsetRight = componentOffsetLeft + componentElRect.width

    pinEls.forEach((pinEl: HTMLElement) => {
        const pinElRect = pinEl.getBoundingClientRect()
        const pinOffsetLeft = getOffset(pinEl).offsetLeft
        const pinOffsetRight = pinOffsetLeft + pinElRect.width

        const overflowLeft = Math.max(0, componentOffsetLeft - pinOffsetLeft)
        const overflowRight = Math.min(0, componentOffsetRight - pinOffsetRight)

        pinEl.style.setProperty('--ion-range-knob-pin-translation', `${overflowLeft || overflowRight}px`)
    })
}

onUpdated(() => {
    // Wheeeeeeeee.
    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    updatePinPositions()
                })
            })
        })
    })
})
</script>

<template>
<ion-range
    ref="component"
    :dual-knobs="props.dualKnobs"
    :min="props.min"
    :max="props.max"
    :value="props.value"
    :pin="props.pin"
    :ticks="props.ticks"
    :snaps="props.snaps"
    :step="props.step"
    :pin-formatter="props.pinFormatter"
    v-on:ion-change="($event) => emit('ion-change', $event)"
    v-on:ion-input="onInput"
/>
</template>

<style lang="scss">
ion-range {
    &::part(pin) {
        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--ion-color-dark);
        color: var(--ion-color-light);
        font-size: var(--custom-font-size-small);
        height: 2.5em;
        width: 6em;
        border-radius: 0.5rem;
        transform: scale(1) translateX(var(--ion-range-knob-pin-translation));

        top: -1.5rem;
        transition: transform 120ms ease, background 120ms ease;
    }

    &::part(pin)::before {
        content: none;
    }

    &::part(knob) {
        background: var(--ion-color-primary);
        box-shadow: 0 0 0.5rem 0 rgba(var(--ion-color-primary-rgb), 0.5);
        transform: scale(0.7);

    }

    &::part(bar) {
        background: var(--ion-color-grey-tint-1);
    }

    &::part(bar-active) {
        background: var(--ion-color-primary);
        box-shadow: 0 0 0.5rem 0 rgba(var(--ion-color-primary-rgb), 0.5);
    }
}
</style>