import { SpeechRecognition } from "@capacitor-community/speech-recognition";
import { VoiceRecorder } from "capacitor-voice-recorder";

import {transcribeAudioBase64} from "@/helper/chat/assistantAPI";
import {useChatStore} from "@/helper/chat/chatStore";
import {sleep} from "openai/core";
import {env} from "@/helper/env";

export type TranscribeStatus = 'ready'|'preparing'|'listening'|'transcribing'

export const SpeechToTextRecorder = async () : Promise<any | undefined> => {
    // transcription promise
    let res : (result: string|undefined) => void
    let rej : () => void
    const promise : Promise<string|undefined> = new Promise((resolve, reject) => {
        res = resolve
        rej = reject
    });

    // console.log(await SpeechRecognition.available())
    // console.log(await SpeechRecognition.checkPermissions())

    // try on device speech recognition
    try {
        const availableResult = await SpeechRecognition.available();
        if(availableResult.available){
            let permissionStatus = await SpeechRecognition.checkPermissions();
            if (permissionStatus.speechRecognition !== "granted") {
                permissionStatus = await SpeechRecognition.requestPermissions();
            }
            if (permissionStatus.speechRecognition !== "denied") {
                return {
                    type: 'SpeechRecognition',
                    start: async () => {
                        SpeechRecognition.start({
                            language: "de-DE",
                            maxResults: 1,
                            // prompt: "Frag' AInes",
                            partialResults: false,
                            popup: true,
                        }).then(result => {
                            const text:string|undefined = result.matches ? result.matches[0] : undefined;

                            res(text)
                        })

                        return
                    },
                    stop: async () => await SpeechRecognition.stop(),
                    transcription: promise
                }
            }
        }
    } catch (e) {
        console.log(e)
    }

    // try voice recorder & online transcribe
    try {
        const canRecord = await VoiceRecorder.canDeviceVoiceRecord();
        if (canRecord.value) {
            let permissionToRecord = await VoiceRecorder.hasAudioRecordingPermission();
            if (!permissionToRecord.value) {
                permissionToRecord = await VoiceRecorder.requestAudioRecordingPermission();
            }
            if (permissionToRecord.value) {
                return {
                    type: 'VoiceRecorder',
                    start: async () => {
                        try {
                            await VoiceRecorder.startRecording();
                        } catch (e) {
                            console.error(e)
                            return
                        }
                    },
                    stop: async () => {
                        try {
                            const recording = await VoiceRecorder.stopRecording();
                            const text = await transcribeAudioBase64(recording.value.recordDataBase64, recording.value.mimeType)
                            res(text)
                        } catch (e) {
                            console.error(e)
                            rej()
                        }
                    },
                    transcription: promise
                }
            }
        }
    } catch (e) {
        console.log(e)
    }

    // try on device speech recognition
    if (env.isDevelopment()) {
        console.warn('SpeechRecognition using faker!!!')
        return {
            type: 'Faker',
            start: async () => {
                return
            },
            stop: async () => {
                sleep(1500).then(() => {
                    res('Findest du nicht auch, dass Spracherkennung Hexenwerk ist?')
                })
            },
            transcription: promise
        }
    }

    return false
}
