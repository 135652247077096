<script setup lang="ts">
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonRange } from '@ionic/vue'
import {computed, Ref, ref} from "vue"
import { errorCatcher } from "@/helper/error"
import { fetchAvailabilityPlanningDates, setAvailabilityPlanningDates } from "@/helper/availabilityPlanningDates"
import { fetchDaysForDateRange, yieldEffectiveAbsences, yieldEffectiveAvailabilities, yieldEffectiveBookings } from "@/helper/day";
import ConfirmPlanningDateChangeModal from "@/views/components/ConfirmPlanningDateChangeModal.vue"
import date from "@/helper/datetime/date";
import {AvailabilityPlanningDates} from "@/graphql/generated/graphql";
import Skeleton from "@/components/Skeleton.vue";
import {DataLoader} from "@/helper/dataLoader";
import { pluralize } from '@/helper/amountFormatter';
import { useRoute } from "vue-router";
import Range from '@/components/Range.vue'

const route = useRoute();

const availabilityPlanningDates : Ref<AvailabilityPlanningDates | undefined> = ref()
const planningThreshold = ref()
const daysBeforeAutoPlan = ref()
const isConfirmPlanningDateChangeModalActive = ref(false)


const isRenderedInAutopilotRoute = computed<boolean>(() => {
    return route.name == "Planning"
})

const planningDates = computed(() => {
    const plannedDate = planningThreshold.value
        ? date.endOfMonth(planningThreshold.value)
        : undefined
    const plannedDateDefaulted = availabilityPlanningDates.value
        ? availabilityPlanningDates.value.defaulted
        : undefined
    const nextPlanStartDate = plannedDate
        ? date.addDays(plannedDate, 1)
        : undefined
    const nextPlanEndDate = nextPlanStartDate
        ? date.endOfMonth(nextPlanStartDate)
        : undefined
    const plannedDateFormatted = plannedDate
        ? date.formatKapitelDate(plannedDate, 'MMMM')
        : ''
    const nextPlanEndDateFormatted = nextPlanEndDate
        ? date.formatKapitelDate(nextPlanEndDate, 'MMMM')
        : ''

    return {
        plannedDate,
        plannedDateDefaulted,
        nextPlanStartDate,
        nextPlanEndDate,
        plannedDateFormatted,
        nextPlanEndDateFormatted
    }
})
const planningDaysSummary = ref({})
const planningDaysSummaryText = computed(() => {
    let value = ``

    if (!planningDaysSummary.value.availabilities) {
        return value
    }

    const numDeviating = planningDaysSummary.value.availabilities.filter(av => av.differsFromTemplate).length || 0
    value += `Für ${planningDates.value.nextPlanEndDateFormatted} sind ${pluralize(planningDaysSummary.value.availabilities.length, "Verfügbarkeit", "Verfügbarkeiten")} ${(planningDaysSummary.value.absences?.length > 0 ? ` und ${pluralize(planningDaysSummary.value.absences.length, "Abwesenheit",  "Abwesenheiten")} ` : ``)} geplant.`

    if (numDeviating) {
      value += ` ${pluralize(numDeviating, "Tag", "Tage")} weichen von der Verfügbarkeitsvorlage ab.`
    }

    if (planningDaysSummary.value.bookings?.length) {
      value += ` ${pluralize(planningDaysSummary.value.bookings.length, "Dienst", "Dienste")} sind bereits geplant.`
    }

    return value
})

const onChangeDaysBeforeAutoPlan = () => {
    setAvailabilityPlanningDates({
        planningThreshold: planningThreshold.value,
        daysBeforeAutoPlan: daysBeforeAutoPlan.value
    }).then(loader.load)
}

const onChangePlanningThreshold = () => {
    const newDate = date.startOfMonth(planningDates.value.nextPlanEndDate)

    setAvailabilityPlanningDates({
        planningThreshold: newDate,
        daysBeforeAutoPlan: daysBeforeAutoPlan.value
    }).then(loader.reload)
}

const fetchAndParseAvailabilityPlanningDates = async () => {
    availabilityPlanningDates.value = await fetchAvailabilityPlanningDates()

    planningThreshold.value = availabilityPlanningDates.value.planningThreshold
    daysBeforeAutoPlan.value = availabilityPlanningDates.value.daysBeforeAutoPlan
}

const fetchDays = async () => {
    const days = await fetchDaysForDateRange(planningDates.value.nextPlanStartDate, planningDates.value.nextPlanEndDate, "availability {differsFromTemplate}")
    planningDaysSummary.value = {
        begin: planningDates.value.nextPlanStartDate,
        until: planningDates.value.nextPlanEndDate,
        bookings: yieldEffectiveBookings(days),
        absences: yieldEffectiveAbsences(days),
        availabilities: yieldEffectiveAvailabilities(days).filter(a => a.shifts.length > 0)
    }
}

const loader = new DataLoader(async () => {
    await fetchAndParseAvailabilityPlanningDates()
        .then(fetchDays)
        .catch(errorCatcher)
})

const refresh = () => {
    loader.load()
}
defineExpose({ refresh })
</script>

<template>
<div class="availability-planner">
    <Skeleton v-if="loader.pending.value && isRenderedInAutopilotRoute" :items="['margin', 'title', 'margin', 'block--height-14rem']"></Skeleton>
    <ion-card v-if="!loader.pending.value && isRenderedInAutopilotRoute">
        <ion-card-header>
            <ion-card-title>
                Verfügbarkeitsplanung
            </ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <span v-if="!planningDates.plannedDateDefaulted">
                Die Planung deiner Verfügbarkeiten ist bis Ende {{ planningDates.plannedDateFormatted }} abgeschlossen.
                <br><br>
            </span>
            <span>
                {{ planningDaysSummaryText }}
                <br>
            </span>
            <span>
                Du kannst jetzt die Verfügbarkeitsplanung für {{ planningDates.nextPlanEndDateFormatted }} abschließen und die Dienstplanung starten.
                <br><br>
            </span>
            <ion-button
                @click="isConfirmPlanningDateChangeModalActive = true"
                color="secondary"
                expand="block"
            >
                Dienstplanung für {{ planningDates.nextPlanEndDateFormatted }} starten
            </ion-button>
        </ion-card-content>
    </ion-card>

    <Skeleton v-if="loader.pending.value" :items="['margin', 'title', 'margin', 'block--height-12rem']"></Skeleton>
    <ion-card v-if='!loader.pending.value && !isRenderedInAutopilotRoute' class="custom-ion-card-light">
        <ion-card-header>
            <ion-card-title>Automatische Verfügbarkeitsplanung</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <span>
                Die Verfügbarkeitsplanung für die Folgemonate kann auch automatisiert abgeschlossen werden. Damit startet auch die Dienstplanung, ohne dass du etwas tun musst.
                <br>
                Wieviele Tage vor Monatsende dürfen wir die Dienstplanung für dich starten?
                <br><br><br>
            </span>

            <Range
                min="1"
                max="60"
                v-model="daysBeforeAutoPlan"
                :pin="true"
                :pin-formatter="(v) => v + ' Tage'"
                :ticks="false"
                :snaps="true"
                :step="1"
                v-on:ion-change="onChangeDaysBeforeAutoPlan"
            />
        </ion-card-content>
    </ion-card>

    <ConfirmPlanningDateChangeModal
        v-model="isConfirmPlanningDateChangeModalActive"
        :begin-date="planningDates.nextPlanStartDate"
        :end-date="planningDates.nextPlanEndDate"
        :summary="planningDaysSummary"
        v-on:save="onChangePlanningThreshold"
    />
</div>
</template>
