<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-button
                    color="secondary"
                    fill="clear"
                    @click="router.go(-1)"
                    size="small"
                >
                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />
                </ion-button>
                <ion-title>{{ date.formatKapitelDate(kapitelDate, 'DD') }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="no-padding-top">
            <CalendarDetail
                :dateId="dateId"
                :expectedContent="expectedContent"
                ref="innerComponentRef"
            />
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    onIonViewWillEnter
} from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed } from 'vue';
CalendarDetail
import date from '@/helper/datetime/date';
import { generateCalendarDateFromKapitelDateString } from '@/helper/calendar/calendarDate';
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";

const props = defineProps({
    dateId: String,
    expectedContent: {
        type: String,
        required: false,
        default: undefined
    },
});

const router = useRouter();
const route = useRoute();
const dateId = props.dateId || route.params.dateId;
const calendarDate = generateCalendarDateFromKapitelDateString(dateId);
const kapitelDate = computed(() => calendarDate.id);

const innerComponentRef = ref(null);

onIonViewWillEnter(() => {
    if (innerComponentRef.value && innerComponentRef.value.reload) {
        innerComponentRef.value.reload();
    }
});
</script>
